import ReactDOM from 'react-dom/client';
import './index.css';
import { I18nextProvider } from "react-i18next";
import i18n from "./resources/languages/i18n";
import App from './App';
import reportWebVitals from './reportWebVitals';
import React from 'react';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <I18nextProvider i18n={i18n}>
    <App />
  </I18nextProvider>
);
