import { useState, useEffect } from 'react';
import { GetEmployeeDetail, GetEmployees } from '../../services/employee/EmployeeService';
import { DTOEmployeeInfo } from '../../interfaces/employee/DTOEmployeeInfo';
import { Employee } from '../../interfaces/employee/Employee';

export const UseEmployees = () => {
    const [data, setData] = useState<Employee[]>([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const result = await GetEmployees();
                if (result) {
                    const sortedEmployees = result.sort((a, b) => a.lastName1.localeCompare(b.lastName1));
                    setData(sortedEmployees);
                }
            } catch (err) {
                console.log(err as Error);
            }
        };

        fetchData();
    }, []);

    return data;
};

export const UseEmployeeInfo = (employeeId: number): [DTOEmployeeInfo | undefined | null, () => Promise<void>] => {
    const [data, setData] = useState<DTOEmployeeInfo | null>();

    const loadEmployeeInfo = async () => {
        try {
            const result = await GetEmployeeDetail(employeeId);
            setData(result);
        } catch (err) {
            console.error(err);
        }
    };

    useEffect(() => {
        if (employeeId) {
            loadEmployeeInfo();
        }
    }, [employeeId]);

    return [data, loadEmployeeInfo];
};