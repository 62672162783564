import React, { useEffect, useState } from 'react';
import Pagination from '../pagination/Pagination';
import { useTranslation } from 'react-i18next';
import Dropdown, { IDropdownBlock } from '../dropdown/Dropdown';
import { ArrowDownTrayIcon, Bars2Icon, Bars3Icon, Bars4Icon, ChevronUpIcon, ChevronDownIcon } from '@heroicons/react/20/solid';
import { ExportTypes } from '../../../interfaces/export/Export';
import { useSpinner } from '../../../contexts/SpinnerContext';
import { Select, Skeleton } from 'antd';
import { SortCriteria } from '../sort/SortDynamic';
import { Tooltip } from 'react-tooltip';
import { EyeIcon, TrashIcon } from '@heroicons/react/24/outline';
import { usePageState } from '../../../contexts/PageStateContext';

type Entity = {
    [key: string]: any;
};

interface SortConfigItem {
    columnName: string;
    key: string;
}

interface ISelectOption {
    value: string;
    label: string;
}

type FilterTypes = "text" | "select" | "select-searcheable" | "multi-select";

export type FilterConfigArray = Array<FilterConfig>;

interface FilterConfig {
    columnName: string;
    type: FilterTypes;
    options?: Array<ISelectOption>;
}

interface TableDynamicProps {
    tableName: string;
    fetchData?: (page: number, itemsPerPage: number) => Promise<Entity[] | undefined>;
    getTotalCount?: () => Promise<number | undefined>;
    getDataExport?: (type: number) => Promise<any>;
    onRowButtonClick?: (primaryKeyValue: any) => void;
    onRowButtonDeleteClick?: (primaryKeyValue: any) => void;
    itemsPerPageDefault?: number;
    translationNode: string;
    usePagination?: boolean;
    filterConfig?: FilterConfigArray;
    defaultFilters?: Record<string, any>;
    onFilterChange?: (filters: Record<string, any> | undefined) => void;
    sortConfig?: Array<SortConfigItem>;
    defaultSort?: SortCriteria[];
    onSortChange?: (sort: Record<string, any> | undefined) => void;
}

const badgeStyles: { [key: string]: { background: string; text: string; border: string; } } = {
    green: {
        background: "bg-green-100",
        text: "text-green-700",
        border: "ring-1 ring-inset ring-green-600/20"
    },
    blue: {
        background: "bg-blue-100",
        text: "text-blue-700",
        border: "ring-1 ring-inset ring-blue-600/20"
    },
    yellow: {
        background: "bg-yellow-100",
        text: "text-yellow-700",
        border: "ring-1 ring-inset ring-yellow-600/20"
    },
    red: {
        background: "bg-red-100",
        text: "text-red-700",
        border: "ring-1 ring-inset ring-red-600/20"
    }
};

const dotStyles: { [key: string]: { background: string; } } = {
    green: {
        background: "text-green-400 bg-green-400/10"
    },
    blue: {
        background: "text-blue-400 bg-blue-400/10"
    },
    yellow: {
        background: "text-yellow-400 bg-yellow-400/10"
    },
    rose: {
        background: "text-rose-400 bg-rose-400/10"
    }
};

const TableDynamic: React.FC<TableDynamicProps> = (
    {
        tableName,
        fetchData,
        getTotalCount,
        getDataExport,
        onRowButtonClick,
        onRowButtonDeleteClick,
        itemsPerPageDefault,
        translationNode,
        usePagination,
        filterConfig,
        defaultFilters,
        onFilterChange,
        defaultSort,
        sortConfig,
        onSortChange,
    }) => {
    const { t } = useTranslation();
    const { setIsLoading } = useSpinner();
    const { state, dispatch } = usePageState();
    const [columns, setColumns] = useState<string[]>([]);
    const [data, setData] = useState<Entity[] | null | undefined>(undefined);
    const [totalCount, setTotalCount] = useState<number>(0);
    const [currentPage, setCurrentPage] = useState(state[tableName]?.currentPage || 1);
    const [itemsPerPage, setItemsPerPage] = useState<number>(!usePagination && itemsPerPageDefault ? itemsPerPageDefault : 0);
    const [useDataPagination] = useState<boolean>(usePagination ?? true);

    const [originalValue, setOriginalValue] = useState<string | null>(null);
    const [filters, setFilters] = useState<Record<string, any>>(defaultFilters || {});
    const [sort, setSort] = useState<SortCriteria[]>(defaultSort || []);

    const dropdownOptions: IDropdownBlock[] = [
        {
            items: [
                { label: t("common.exportExcel"), icon: ArrowDownTrayIcon, action: () => exportToExcel() },
            ]
        }
    ];

    const dropdownItemsPerPage: IDropdownBlock[] = [
        {
            items: [
                { label: "10", icon: Bars2Icon, action: () => setItemsPerPage(10) },
                { label: "25", icon: Bars3Icon, action: () => setItemsPerPage(25) },
                { label: "50", icon: Bars4Icon, action: () => setItemsPerPage(50) },
                { label: "100", icon: Bars4Icon, action: () => setItemsPerPage(100) },
            ]
        }
    ];

    const renderBadge = (content: string, color: string): JSX.Element => (
        <span className={`inline-flex items-center rounded-md ${badgeStyles[color]?.background} px-2.5 py-1.5 text-xs font-medium ${badgeStyles[color]?.text} ${badgeStyles[color]?.border}`}>
            {content}
        </span>
    );

    const renderDot = (content: string, color: string): JSX.Element => (
        <div className="flex items-center justify-end gap-x-2 sm:justify-start">
            <div className={`${dotStyles[color]?.background} flex-none rounded-full p-1`}>
                <div className="h-1.5 w-1.5 rounded-full bg-current" />
            </div>
            <div className="hidden sm:block">{content}</div>
        </div>
    );

    const translateIfNeeded = (str: string): string => {
        const translationRegex = /^\{t\("(.+)"\)\}$/;
        const match = str.match(translationRegex);
        if (match && match[1]) {
            return t(match[1]);
        }
        return str;
    };

    const parseContent = (str: string): string | JSX.Element => {
        try {
            const json = JSON.parse(str);

            if (json && json.value) {
                let content: string;

                if (json.translate) {
                    content = t(json.value);
                } else {
                    content = json.value;
                }

                if (json.component && json.component.type === 'badge' && json.component.color) {
                    return renderBadge(content, json.component.color);
                }
                else if (json.component && json.component.type === 'dot' && json.component.color) {
                    return renderDot(content, json.component.color);
                }
                return content;
            }
        } catch (e) {
            return translateIfNeeded(str);
        }

        return str;
    };

    useEffect(() => {
        const getData = async () => {
            if (fetchData !== undefined) {
                let spinnerTimeout = setTimeout(() => {
                    setData(undefined);
                }, 500);

                try {
                    const tableData = await fetchData(currentPage, itemsPerPage);
                    clearTimeout(spinnerTimeout);
                    setData(tableData || null);

                    if (getTotalCount !== undefined) {
                        const count = await getTotalCount();
                        setTotalCount(count || 0);

                        if (count && count > 0 && Math.ceil(count / itemsPerPage) < currentPage) {
                            setCurrentPage(1);
                        }
                    }

                } catch (error) {
                    console.error("Error fetching data:", error);
                }
            }
        };
        getData();
    }, [fetchData, currentPage, itemsPerPage]);

    useEffect(() => {
        if (data && data.length > 0) {
            setColumns(Object.keys(data[0]));
        }
    }, [data]);

    const handlePageChange = (newPage: number) => {
        setCurrentPage(newPage);
        dispatch({ type: 'SET_PAGE_STATE', payload: { pageKey: tableName, state: { ...state[tableName], currentPage: newPage } } });
    };

    const exportToExcel = () => {
        const getDataExcel = async () => {
            setIsLoading(true)
            try {
                await getDataExport!(ExportTypes.Excel);
            } catch (error) {
                console.error("Error exporting data to excel:", error);
            } finally {
                setIsLoading(false);
            }
        };
        if (getDataExport) getDataExcel();
    };

    const handleInputFocus = (e: React.FocusEvent<HTMLInputElement>) => {
        setOriginalValue(e.target.value);
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>, columnName: string) => {
        if (e.key === 'Enter') {
            handleFilterChange(columnName, e.currentTarget.value);
        }
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>, columnName: string) => {
        const value = e.target.value;
        if (value === '') {
            handleFilterChange(columnName, null);
        }
    };

    const handleInputBlur = (e: React.FocusEvent<HTMLInputElement>, columnName: string) => {
        const currentValue = e.target.value;
        if (currentValue !== originalValue) {
            handleFilterChange(columnName, currentValue === '' ? null : currentValue);
        }
    };

    const handleFilterChange = (columnName: string, value: any) => {
        const filterValue = value === '' ? null : value;
        const newFilters = { ...filters, [columnName]: filterValue };
        setFilters(newFilters);

        onFilterChange && onFilterChange(newFilters);
    };

    const handleSelectChange = (value: string, key: string) => {
        const newValue = value ? value : undefined;
        handleFilterChange(key, newValue);
    };

    const handleMultiSelectChange = (value: string[], key: string) => {
        const newValue = value ? value : undefined;
        handleFilterChange(key, newValue);
    };

    const handleSortChange = (sortKey: string) => {
        const existingSort = sort.find(crit => crit.field === sortKey);
        const newSortCriteria: SortCriteria = {
            field: sortKey,
            order: existingSort?.order === 'asc' ? 'desc' : 'asc'
        };

        setSort([newSortCriteria]);
        onSortChange && onSortChange([newSortCriteria]);
    };

    return (
        <div className="-mx-4 mt-4 sm:-mx-0">
            <div className={`flex justify-end ${useDataPagination || getDataExport !== undefined ? "mb-6" : ""} space-x-4`}>
                {useDataPagination && <Dropdown title={`${t("common.itemsPerPage")}: ${itemsPerPage}`} blocks={dropdownItemsPerPage} />}
                {getDataExport && <Dropdown title={t("common.options")} blocks={dropdownOptions} />}
            </div>
            <div className="overflow-x-auto">
                {data === undefined ? (
                    <Skeleton className="mt-8" active paragraph={{ rows: 14 }} />
                ) : (
                    <table className="min-w-full divide-y divide-gray-300">
                        <thead>
                            <tr>
                                {(onRowButtonClick !== undefined || onRowButtonDeleteClick !== undefined) &&
                                    <th className="min-w-min w-[50px]">&nbsp;</th>
                                }
                                {columns.map((key, index) => {
                                    if (index === 0) return null;
                                    const sortConfigItem = sortConfig?.find(config => config.columnName === key);
                                    const isSortable = Boolean(sortConfigItem);
                                    const currentSortOrder = sort.find(crit => crit.field === sortConfigItem?.key)?.order;

                                    return (
                                        <th key={index} className="flex-grow px-6 py-3 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                            {isSortable ? (
                                                <button
                                                    type="button"
                                                    className="group inline-flex items-center font-medium text-xs uppercase tracking-wider focus:outline-none"
                                                    onClick={() => sortConfigItem && handleSortChange(sortConfigItem.key)}
                                                >
                                                    {currentSortOrder === 'asc' ? (
                                                        <div className="inline-flex items-center">
                                                            {t(`${translationNode}.${key}`)}
                                                            <span className="ml-2 flex-none rounded text-gray-400 group-hover:visible">
                                                                <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
                                                            </span>
                                                        </div>
                                                    ) : currentSortOrder === 'desc' ? (
                                                        <div className="inline-flex items-center">
                                                            {t(`${translationNode}.${key}`)}
                                                            <span className="ml-2 flex-none rounded text-gray-400 group-hover:visible">
                                                                <ChevronUpIcon className="h-5 w-5" aria-hidden="true" />
                                                            </span>
                                                        </div>
                                                    ) : (
                                                        <div className="inline-flex items-center">
                                                            {t(`${translationNode}.${key}`)}
                                                            <span className="invisible ml-2 flex-none rounded text-gray-400 group-hover:visible group-focus:visible">
                                                                <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
                                                            </span>
                                                        </div>
                                                    )}
                                                </button>
                                            ) : (
                                                t(`${translationNode}.${key}`)
                                            )}
                                        </th>
                                    );
                                })}
                            </tr>
                            {filterConfig &&
                                <tr>
                                    {(onRowButtonClick !== undefined || onRowButtonDeleteClick !== undefined) &&
                                        <th className="min-w-min w-[50px]">&nbsp;</th>
                                    }
                                    {filterConfig.map((filter) => (
                                        <th key={filter.columnName} className="text-left px-2 pb-3">
                                            {filter.type === "text" && (
                                                <input
                                                    type="text"
                                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 text-xs"
                                                    onFocus={handleInputFocus}
                                                    onKeyDown={(e) => handleKeyDown(e, filter.columnName)}
                                                    onChange={(e) => handleInputChange(e, filter.columnName)}
                                                    onBlur={(e) => handleInputBlur(e, filter.columnName)}
                                                    defaultValue={filters[filter.columnName] || ""}
                                                />
                                            )}
                                            {filter.type === "select" && filter.options && (
                                                <Select
                                                    key={filter.columnName}
                                                    options={filter.options}
                                                    className="rounded-md py-0.5"
                                                    placeholder=""
                                                    onChange={(selectedValue) => handleSelectChange(selectedValue, filter.columnName)}
                                                    defaultValue={filter.options.find(option => option.value === filters[filter.columnName])?.value || ""}
                                                />
                                            )}
                                            {filter.type === "select-searcheable" && filter.options && (
                                                <Select
                                                    key={filter.columnName}
                                                    options={filter.options}
                                                    className="rounded-md py-0.5"
                                                    placeholder=""
                                                    showSearch
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) => (option?.label ?? '').includes(input)}
                                                    onChange={(selectedValue) => handleSelectChange(selectedValue, filter.columnName)}
                                                    defaultValue={filter.options.find(option => option.value === filters[filter.columnName])?.value || ""}
                                                />
                                            )}
                                            {filter.type === "multi-select" && filter.options && (
                                                <Select
                                                    key={filter.columnName}
                                                    mode="multiple"
                                                    className="rounded-md py-0.5"
                                                    allowClear
                                                    showSearch={false}
                                                    style={{ width: '100%' }}
                                                    placeholder=""
                                                    defaultValue={filters[filter.columnName] || []}
                                                    onChange={(selectedValues) => handleMultiSelectChange(selectedValues, filter.columnName)}
                                                    options={filter.options}
                                                />
                                            )}
                                        </th>
                                    ))}
                                </tr>
                            }
                        </thead>
                        <tbody className="divide-y divide-gray-200 bg-white">
                            {data && data.length > 0 ? data.map((row, rowIndex) => (
                                <tr key={rowIndex}>
                                    {(onRowButtonClick !== undefined || onRowButtonDeleteClick !== undefined) &&
                                        <td className="min-w-min w-[50px]">
                                            <div className="flex space-x-2 ml-1">
                                                {onRowButtonClick !== undefined &&
                                                    <button
                                                        data-tooltip-id="viewDetails"
                                                        data-tooltip-content={t(`${translationNode}.viewDetails`)}
                                                        data-tooltip-place="top-start"
                                                        className="rounded-md bg-indigo-50 px-2.5 py-1.5 text-sm font-semibold text-indigo-600 shadow-sm hover:bg-indigo-100"
                                                        onClick={() => onRowButtonClick(row[Object.keys(row)[0]])}>
                                                        <Tooltip id="viewDetails" />
                                                        <EyeIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
                                                    </button>
                                                }
                                                {onRowButtonDeleteClick !== undefined &&
                                                    <button
                                                        data-tooltip-id="delete"
                                                        data-tooltip-content={t(`${translationNode}.delete`)}
                                                        data-tooltip-place="top-start"
                                                        className="rounded-md bg-red-50 px-2.5 py-1.5 text-sm font-semibold text-red-600 shadow-sm hover:bg-red-100"
                                                        onClick={() => onRowButtonDeleteClick(row[Object.keys(row)[0]])}>
                                                        <Tooltip id="delete" />
                                                        <TrashIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
                                                    </button>
                                                }
                                            </div>
                                        </td>
                                    }
                                    {Object.entries(row).map(([key, value], colIndex) => {
                                        if (colIndex === 0) return null;
                                        return (
                                            <td key={`${rowIndex}-${colIndex}`} className="px-6 py-4 whitespace-normal text-sm leading-5 text-gray-900 break-words">
                                                {typeof value === 'string' ? parseContent(value) : value}
                                            </td>
                                        );
                                    })}
                                </tr>
                            )) : (
                                <tr>
                                    <td colSpan={columns.length > (filterConfig?.length ?? 0) ? columns.length + 1 : filterConfig?.length} className="px-6 py-4 text-center">{t("common.noData")}</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                )}
            </div>
            {useDataPagination &&
                <Pagination
                    totalPages={Math.ceil(totalCount / itemsPerPage)}
                    currentPage={currentPage}
                    itemsPerPage={itemsPerPage}
                    totalItems={totalCount}
                    onPageChange={handlePageChange}
                />
            }
        </div>
    );
};

export default TableDynamic;