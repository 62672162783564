import { Employee } from "../employee/Employee";
import { WorkCenter } from "../work-center/WorkCenter";

export interface WorkCenterTimeControlResponsible {
    id: number;
    workCenterId: number;
    workCenter?: WorkCenter;
    employeeId?: number;
    employee?: Employee;
}

export function DefaultWorkCenterTimeControlResponsible(): WorkCenterTimeControlResponsible {
    return {
        id: 0,
        workCenterId: 0,
        employeeId: 0
    };
}