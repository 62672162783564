import React from 'react';
import { useTranslation } from 'react-i18next';
import { DefaultTFuncReturn } from 'i18next';
import { Fragment, useState, useEffect } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { useSpinner } from '../../../contexts/SpinnerContext';

interface ModalModalAdvancedProps {
    title: string | DefaultTFuncReturn;
    subtitle?: string | DefaultTFuncReturn;
    isOpen: boolean;
    onOk: () => void;
    okText?: string | DefaultTFuncReturn;
    closeVisible?: boolean;
    onClose: () => void;
    children: React.ReactNode;
}

const ModalAdvanced: React.FC<ModalModalAdvancedProps> = ({ title, subtitle, isOpen, okText, onOk, closeVisible = true, onClose, children }) => {
    const { t } = useTranslation();
    const [open, setOpen] = useState(isOpen);
    const { isLoading } = useSpinner();

    useEffect(() => {
        setOpen(isOpen);
    }, [isOpen]);

    useEffect(() => {
        setOpen(isOpen);
    }, [open]);

    const handleClose = () => {
        if (!isLoading) {
            setOpen(false);
            onClose();
        }
    };

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-50" onClose={handleClose} style={{ pointerEvents: isLoading ? 'none' : 'auto' }}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 hidden bg-gray-500 bg-opacity-75 transition-opacity md:block" />
                </Transition.Child>

                <div className="fixed inset-0 z-50 overflow-y-auto">
                    <div className="flex min-h-full items-stretch justify-center text-center md:items-center md:px-2 lg:px-4">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
                            enterTo="opacity-100 translate-y-0 md:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 md:scale-100"
                            leaveTo="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
                        >
                            <Dialog.Panel className="flex w-full transform text-left text-base transition md:my-8 md:max-w-2xl md:px-4 lg:max-w-4xl">
                                <div className="relative flex flex-col w-full bg-white shadow-2xl rounded-lg pt-1 pb-4 px-1.5">
                                    <div className="flex justify-between items-center px-4 pt-4">
                                        <h2 className="text-2xl font-medium">{title}</h2>
                                        <button
                                            type="button"
                                            className="text-gray-400 hover:text-gray-500"
                                            onClick={onClose}
                                        >
                                            <span className="sr-only">Close</span>
                                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                                        </button>
                                    </div>
                                    {subtitle &&
                                        <div className="flex justify-between items-center px-4 mt-3.5 mb-4">
                                            <p className="text-sm italic text-gray-700 leading-6 text-justify" style={{ whiteSpace: 'pre-wrap' }}>
                                                {subtitle}
                                            </p>
                                        </div>
                                    }

                                    <div className="mt-2 px-4 py-2">
                                        {children}
                                    </div>

                                    <div className="mt-6 px-4 py-2 flex flex-col sm:flex-row items-center justify-center gap-x-6 gap-y-4 sm:gap-y-0">
                                        {!closeVisible &&
                                            <div className="inline-flex w-full sm:w-1/3 justify-center opacity-0 pointer-events-none">
                                                &nbsp;
                                            </div>
                                        }
                                        {closeVisible &&
                                            <button
                                                type="button"
                                                className="inline-flex w-full sm:w-1/2 justify-center rounded-md bg-gray-200 px-3 py-2 text-sm font-semibold text-gray-600 shadow-sm hover:bg-gray-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-300"
                                                onClick={onClose}
                                            >
                                                {t("common.cancel")}
                                            </button>
                                        }
                                        <button
                                            type="button"
                                            className="inline-flex w-full sm:w-1/2 justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                            onClick={onOk}
                                        >
                                            {okText ?? t("common.accept")}
                                        </button>
                                        {!closeVisible &&
                                            <div className="inline-flex w-full sm:w-1/3 justify-center opacity-0 pointer-events-none">&nbsp;</div>
                                        }
                                    </div>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    );
}

export default ModalAdvanced;