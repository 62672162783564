import { AxiosError } from "axios";
import { AnnualCalendar } from "../../interfaces/common/calendar/AnnualCalendar";
import { Job } from "../../interfaces/job/Job";
import { WorkCenter } from "../../interfaces/work-center/WorkCenter";
import { Company } from "../../interfaces/company/Company";
import axiosRRHHEmployeeAPI from "../axios/AxiosRRHHEmployeeAPI";
import { Department } from "../../interfaces/company/Department";
import { WorkCenterDepartment } from "../../interfaces/company/WorkCenterDepartment";
import { WorkCenterTimeControlResponsible } from "../../interfaces/company/WorkCenterTimeControlResponsible";

export async function GetCompanies(
    page?: number,
    itemsPerPage?: number,
    companyFilter?: Record<string, any>
): Promise<Company[]> {
    try {
        let response: any;
        if (page && itemsPerPage) {
            response = await axiosRRHHEmployeeAPI.post(`/api/company/${page}/${itemsPerPage}`, companyFilter ?? {});
        } else {
            response = await axiosRRHHEmployeeAPI.post(`/api/company`, companyFilter ?? {});
        }

        if (response.status === 200) {
            return response.data;
        }
    } catch (error) {
        console.error('Error en la solicitud:', error);
    }
    return [];
}

export async function GetCompaniesCount(
    companyFilter?: Record<string, any>
): Promise<number> {
    try {
        const response = await axiosRRHHEmployeeAPI.post("/api/company/count", companyFilter ?? {});

        if (response.status === 200) {
            return response.data;
        }
    } catch (error) {
        console.error('Error en la solicitud:', error);
    }
    return 0;
}

export async function GetCompany(
    id: number
): Promise<Company | null> {
    try {
        const response = await axiosRRHHEmployeeAPI.get(`/api/company/${id}`);

        if (response.status === 200) {
            return response.data;
        }
    } catch (error) {
        console.error('Error en la solicitud:', error);
    }
    return null;
}

export async function PatchCompany(
    id: number,
    updates: { fieldName: string, fieldValue: any }[]
): Promise<Company | null> {
    try {
        const patchCompany = updates.map(update => {
            return { op: "replace", path: `/${update.fieldName}`, value: update.fieldValue };
        });

        const response = await axiosRRHHEmployeeAPI.patch(`/api/company/${id}`, patchCompany);

        if (response.status === 200) {
            return response.data;
        }
    } catch (error) {
        console.error('Error en la solicitud:', error);
    }
    return null;
}

export async function GetWorkCenters(
    page?: number,
    itemsPerPage?: number,
    workCenterFilter?: Record<string, any>
): Promise<WorkCenter[]> {
    try {
        if (page && itemsPerPage) {
            const response = await axiosRRHHEmployeeAPI.get(`/api/company/Workcenters/${page}/${itemsPerPage}`, workCenterFilter ?? {});
            if (response.status === 200) {
                return response.data;
            }
        } else {
            const response = await axiosRRHHEmployeeAPI.get("/api/company/Workcenters", workCenterFilter ?? {});
            if (response.status === 200) {
                return response.data;
            }
        }
    } catch (error) {
        console.error('Error en la solicitud:', error);
    }
    return [];
}

export async function GetWorkCentersCount(
    workCenterFilter?: Record<string, any>
): Promise<number> {
    try {
        const response = await axiosRRHHEmployeeAPI.post("/api/company/Workcenters/WorkcentersCount", workCenterFilter ?? {});

        if (response.status === 200) {
            return response.data;
        }
    } catch (error) {
        console.error('Error en la solicitud:', error);
    }
    return 0;
}

export async function GetWorkCenter(
    id: number
): Promise<WorkCenter | null> {
    try {
        const response = await axiosRRHHEmployeeAPI.get(`/api/company/WorkCenters/${id}`);

        if (response.status === 200) {
            return response.data;
        }
    } catch (error) {
        console.error('Error en la solicitud:', error);
    }
    return null;
}

export async function GetWorkCenterCalendar(
    id: number,
    year: number
): Promise<AnnualCalendar | null> {
    try {
        const response = await axiosRRHHEmployeeAPI.get(`/api/company/WorkCenters/Calendar/${id}/${year}`);

        if (response.status === 200) {
            return response.data;
        }
    } catch (error) {
        console.error('Error en la solicitud:', error);
    }
    return null;
}

export async function GetWorkCenterCalendarAvailableYears(
    id: number
): Promise<number[]> {
    try {
        const response = await axiosRRHHEmployeeAPI.get(`/api/company/WorkCenters/Calendar/AvailableYears/${id}`);

        if (response.status === 200) {
            return response.data;
        }
    } catch (error) {
        console.error('Error en la solicitud:', error);
    }
    return [];
}

export async function PostWorkCenter(
    workCenter: WorkCenter
): Promise<WorkCenter | string> {
    try {
        const response = await axiosRRHHEmployeeAPI.post("/api/company/WorkCenter", workCenter);

        if (response.status === 200) {
            return response.data;
        }
    } catch (error) {
        console.error('Error en la solicitud:', error);
        const axiosError = error as AxiosError;
        if (axiosError.response && axiosError.response.data && typeof axiosError.response.data === "string") {
            return axiosError.response.data;
        }
    }
    return "";
}

export async function PatchWorkCenter(
    id: number,
    updates: { fieldName: string, fieldValue: any }[]
): Promise<WorkCenter | null> {
    try {
        const patchWorkCenter = updates.map(update => {
            return { op: "replace", path: `/${update.fieldName}`, value: update.fieldValue };
        });

        const response = await axiosRRHHEmployeeAPI.patch(`/api/company/WorkCenters/${id}`, patchWorkCenter);

        if (response.status === 200) {
            return response.data;
        }
    } catch (error) {
        console.error('Error en la solicitud:', error);
    }
    return null;
}

export async function PostWorkCenterCalendar(
    id: number,
    year: number,
    calendar: AnnualCalendar
): Promise<boolean> {
    try {
        const response = await axiosRRHHEmployeeAPI.post(`/api/company/WorkCenters/Calendar/${id}/${year}`, calendar ?? {});

        if (response.status === 200) {
            return true;
        }
    } catch (error) {
        console.error('Error en la solicitud:', error);
    }
    return false;
}

export async function GetJobs(
    page?: number,
    itemsPerPage?: number
): Promise<Job[]> {
    try {
        if (page && itemsPerPage) {
            const response = await axiosRRHHEmployeeAPI.get(`/api/company/Jobs/${page}/${itemsPerPage}`);
            if (response.status === 200) {
                return response.data;
            }
        } else {
            const response = await axiosRRHHEmployeeAPI.get("/api/company/Jobs");
            if (response.status === 200) {
                return response.data;
            }
        }
    } catch (error) {
        console.error('Error en la solicitud:', error);
    }
    return [];
}

export async function GetDepartments(): Promise<Department[]> {
    const response = await axiosRRHHEmployeeAPI.get("/api/department");
    if (response.status === 200) {
        return response.data;
    }
    return [];
}

export async function GetDepartmentsByWorkCenter(
    workCenterId: number
): Promise<WorkCenterDepartment[]> {
    const response = await axiosRRHHEmployeeAPI.get(`/api/WorkCenterDepartment/GetAllByWorkCenterId/${workCenterId}`);
    if (response.status === 200) {
        return response.data;
    }
    return [];
}

export async function PostWorkCenterDepartment(
    workCenterDepartment: WorkCenterDepartment
): Promise<WorkCenterDepartment | undefined> {
    const response = await axiosRRHHEmployeeAPI.post("/api/WorkCenterDepartment", workCenterDepartment);
    if (response.status === 200 || response.status === 201) {
        return response.data;
    }
    return undefined;
}

export async function DeleteWorkCenterDepartment(
    id: number
): Promise<boolean> {
    const response = await axiosRRHHEmployeeAPI.delete(`/api/WorkCenterDepartment/${id}`);
    return response.status === 200;
}

export async function GetTimeControlResponsiblesByWorkCenter(
    workCenterId: number
): Promise<WorkCenterDepartment[]> {
    const response = await axiosRRHHEmployeeAPI.get(`/api/WorkCenterTimeControlResponsible/GetAllByWorkCenterId/${workCenterId}`);
    if (response.status === 200) {
        return response.data;
    }
    return [];
}

export async function PostWorkCenterTimeControlResponsible(
    workCenterTimeControlResponsible: WorkCenterTimeControlResponsible
): Promise<WorkCenterTimeControlResponsible | undefined> {
    const response = await axiosRRHHEmployeeAPI.post("/api/WorkCenterTimeControlResponsible", workCenterTimeControlResponsible);
    if (response.status === 200 || response.status === 201) {
        return response.data;
    }
    return undefined;
}

export async function DeleteWorkCenterTimeControlResponsible(
    id: number
): Promise<boolean> {
    const response = await axiosRRHHEmployeeAPI.delete(`/api/WorkCenterTimeControlResponsible/${id}`);
    return response.status === 200;
}