import { Employee } from "../employee/Employee";
import { WorkCenter } from "../work-center/WorkCenter";
import { Department } from "./Department";

export interface WorkCenterDepartment {
    id: number;
    workCenterId: number;
    workCenter?: WorkCenter;
    departmentId: number;
    department?: Department;
    responsibleEmployeeId?: number;
    responsibleEmployee?: Employee;
}

export function DefaultWorkCenterDepartment(): WorkCenterDepartment {
    return {
        id: 0,
        workCenterId: 0,
        departmentId: 0,
        responsibleEmployeeId: 0
    };
}