import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ArrowDownIcon, PaperClipIcon, PhotoIcon, TrashIcon } from '@heroicons/react/24/outline';
import { Alert, Modal, Tooltip } from 'antd';

interface Attachment {
    id: number;
    fileName: string;
    fileType: string;
}

interface AttachmentsProps {
    attachments: Attachment[];
    allowedExtensions: string[];
    maxSize: number;
    onAdd: (file: File) => void;
    onDelete: (id: number) => void;
    onDownload: (id: number) => void;
}

const Attachments: React.FC<AttachmentsProps> = ({ attachments, allowedExtensions, maxSize, onAdd, onDelete, onDownload }) => {
    const { t } = useTranslation();
    const [fileError, setFileError] = useState<string | null>(null);

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0];
        if (file && validateFile(file)) {
            onAdd(file);
        }
    };

    const validateFile = (file: File): boolean => {
        const allowedExtensions = ['png', 'jpg', 'jpeg', 'pdf', 'docx'];
        const maxFileSize = maxSize * 1024 * 1024;

        const fileExtension = file.name.split('.').pop()?.toLowerCase();

        if (!allowedExtensions.includes(fileExtension || '')) {
            setFileError(t('attachments.invalidExtensionError', { allowedExtensions: allowedExtensions.join(', ').toUpperCase() }));
            return false;
        }

        if (file.size > maxFileSize) {
            setFileError(t('attachments.maxSizeError', { maxSize: maxSize }));
            return false;
        }

        setFileError(null);
        return true;
    };

    return (
        <>
            {fileError && <Alert className="mb-4" message={fileError} type="error" showIcon />}
            <div className="mb-4 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-6">
                <div className="text-center">
                    <PhotoIcon className="mx-auto h-12 w-12 text-gray-300" aria-hidden="true" />
                    <div className="mt-4 flex text-sm leading-6 text-gray-600">
                        <label
                            htmlFor="file-upload"
                            className="relative cursor-pointer rounded-md bg-white font-semibold text-indigo-600 hover:text-indigo-500"
                        >
                            <span>{t("attachments.text1")}</span>
                            <input
                                id="file-upload"
                                name="file-upload"
                                type="file"
                                className="sr-only"
                                onChange={handleFileChange}
                            />
                        </label>
                        <p className="pl-1">{t("attachments.text2")}</p>
                    </div>
                    <p className="text-xs leading-5 text-gray-600">{t("attachments.text3", { allowedExtensions: allowedExtensions.join(', ').toUpperCase(), maxSize: maxSize })}</p>
                </div>
            </div>
            {(attachments && attachments.length > 0) ? (
                <div className="rounded-md">
                    <dl className="grid grid-cols-1">
                        <div className="col-span-2">
                            <dd className="text-sm text-gray-900">
                                <ul role="list" className="divide-y divide-gray-100 rounded-md border border-gray-200">
                                    {attachments.map((attachment, index) => (
                                        <li key={index} className="flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6">
                                            <div className="flex w-0 flex-1 items-center space-x-4">
                                                <Tooltip placement="topLeft" title={t("common.delete")} color="red">
                                                    <button
                                                        className="ml-2 mr-1 rounded-md bg-red-50 px-2.5 py-1.5 text-sm font-semibold text-red-600 shadow-sm hover:bg-red-100"
                                                        onClick={() => {
                                                            Modal.confirm({
                                                                title: t("attachments.deleteAttachmentTitle").toString(),
                                                                content: <div className="py-3" dangerouslySetInnerHTML={{ __html: `${t("attachments.deleteAttachmentSubtitle", { attachmentName: attachment.fileName })}` }} />,
                                                                okText: t("common.accept").toString(),
                                                                okType: 'danger',
                                                                cancelText: t("common.cancel").toString(),
                                                                centered: true,
                                                                width: 550,
                                                                onOk() {
                                                                    onDelete(attachment.id);
                                                                },
                                                            })
                                                        }}
                                                    >
                                                        <TrashIcon className="h-5 w-5" aria-hidden="true" />
                                                    </button>
                                                </Tooltip>
                                                <PaperClipIcon className="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                                                <div className="ml-4 flex min-w-0 flex-1 gap-2">
                                                    <span className="truncate font-medium">{attachment.fileName}</span>
                                                </div>
                                                <button
                                                    className="flex rounded-md bg-indigo-100 px-2.5 py-1.5 text-sm font-semibold text-indigo-600 shadow-sm hover:bg-indigo-200"
                                                    onClick={() => {
                                                        onDownload(attachment.id);
                                                    }}
                                                >
                                                    <ArrowDownIcon className="h-5 w-5" aria-hidden="true" />
                                                    <span className="ml-1.5 truncate font-medium">{t("common.download")}</span>
                                                </button>
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            </dd>
                        </div>
                    </dl>
                </div>
            ) : (
                <label htmlFor="comment" className="mt-5 mb-6 block text-sm italic font-medium leading-6 text-gray-900">
                    {t("attachments.noFilesSelected")}
                </label>
            )}
            <div className="mb-2">&nbsp;</div>
        </>
    )
};

export default Attachments;