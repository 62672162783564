import { useState, useEffect, useContext } from 'react';
import { useTranslation } from "react-i18next";
import SectionHeader from "../../../components/common/header/SimpleHeader";
import { useNavigate, useParams } from 'react-router-dom';
import { GetTimeControlSummaryByWorkCenter } from '../../../services/time-control/TimeControlService';
import { useSpinner } from '../../../contexts/SpinnerContext';
import { usePageState } from '../../../contexts/PageStateContext';
import { NotificationContext } from '../../../contexts/NotificationContext';
import { UseYears } from '../../../hooks/utils/UseUtils';
import { DTOTimeControlWorkCenterSummary } from '../../../interfaces/time-control/DTOTimeControlWorkCenterSummary';
import { UseWorkCenter } from '../../../hooks/company/UseCompany';
import TimeControlSummaryWorkCenterInfo from './TimeControlSummaryWorkCenterInfo';
import TimeControlWorkCenterSummaryTable from './TimeControlWorkCenterSummaryTable';
import SimpleHeaderWithTwoButtons from '../../../components/common/header/SimpleHeaderWithTwoButtons';
import { Skeleton } from 'antd';

export default function TimeControlWorkCenterSummary() {
  const pageName = 'TimeControlWorkCenterSummary';
  const { t } = useTranslation();
  const { showNotification } = useContext(NotificationContext);
  const { setIsLoading } = useSpinner(false);
  const { state, dispatch } = usePageState();
  const [timeControlWorkCenterSummary, setTimeControlWorkCenterSummary] = useState<DTOTimeControlWorkCenterSummary | null | undefined>(undefined);

  const navigate = useNavigate();
  const { workCenterId } = useParams();

  useEffect(() => {
    getTimeControlSummaryByWorkCenter();
  }, []);

  const getTimeControlSummaryByWorkCenter = async () => {
    await GetTimeControlSummaryByWorkCenter(Number(workCenterId)).then((result) => {
      setTimeControlWorkCenterSummary(result);
    })
    setIsLoading(false);
  };

  return (
    <div className="px-2 sm:px-4">
      <SimpleHeaderWithTwoButtons
        title={`${t("timeControl.title")} - ${t("timeControl.workCenterView")}`}
        textSize="text-3xl"
        buttonText1={t("common.goBack")}
        button1Click={() => navigate(`/time-control`)}
      />
      <div>
        <div className="mt-7">
          <TimeControlSummaryWorkCenterInfo workCenterId={Number(workCenterId)} incidencesCount={timeControlWorkCenterSummary?.incidencesCount || 0} />
        </div>
        <div className="mt-8">
          {timeControlWorkCenterSummary ? (
            <TimeControlWorkCenterSummaryTable workCenterId={Number(workCenterId)} timeControlWorkCenterSummary={timeControlWorkCenterSummary} />
          ) : timeControlWorkCenterSummary === null ? (
            <p className="px-6 py-4 text-base text-center italic">{t("timeControl.summaryLoadNoDataMessage")}</p>
          ) : (
            <Skeleton className="mt-5" active paragraph={{ rows: 10 }} />
          )}
        </div>
        <div className="mb-10"></div>
      </div>
    </div >
  )
}
