import { useState, useEffect, useContext } from 'react';
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from 'react-router-dom';
import { GenerateTimeControlCertificateForCustomer, GenerateTimeControlCertificateForEmployee, GetTimeControlCertificateForEmployeeOptions, GetTimeControlSummaryByEmployee } from '../../../services/time-control/TimeControlService';
import { useSpinner } from '../../../contexts/SpinnerContext';
import { Cascader, Select, Skeleton, Tooltip } from 'antd';
import { usePageState } from '../../../contexts/PageStateContext';
import { NotificationContext } from '../../../contexts/NotificationContext';
import { UseYears } from '../../../hooks/utils/UseUtils';
import { DTOTimeControlEmployeeSummary } from '../../../interfaces/time-control/DTOTimeControlEmployeeSummary';
import TimeControlSummaryTable from './TimeControlSummaryTable';
import TimeControlSummaryStats from './TimeControlSummaryStats';
import TimeControlSummaryEmployeeInfo from './TimeControlSummaryEmployeeInfo';
import SimpleHeaderWithTwoButtons from '../../../components/common/header/SimpleHeaderWithTwoButtons';
import Alert, { AlertType } from '../../../components/common/alert/Alert';
import { UseEmployeeInfo } from '../../../hooks/employee/UseEmployee';
import ModalAdvanced from '../../../components/common/overlay/ModalAdvanced';
import { DTOTimeControlCertificateOption } from '../../../interfaces/time-control/DTOTimeControlCertificateOption';

export default function TimeControlEmployeeSummary() {
  const pageName = 'TimeControlEmployeeSummary';
  const { t } = useTranslation();
  const { showNotification } = useContext(NotificationContext);
  const { setIsLoading } = useSpinner(false);
  const { state, dispatch } = usePageState();
  const navigate = useNavigate();
  const { workCenterId, employeeId } = useParams();
  const years = UseYears();
  const [timeControlSummary, setTimeControlSummary] = useState<DTOTimeControlEmployeeSummary | null | undefined>(undefined);
  const [selectedYear, setSelectedYear] = useState<number>(new Date().getFullYear());
  const [employeeInfo, reloadEmployeeInfo] = UseEmployeeInfo(Number(employeeId));

  const [generateTimeControlCertificateForCustomerModal, setGenerateTimeControlCertificateForCustomerModal] = useState<boolean>(false);
  const [generateTimeControlCertificateForCustomerOptions, setGenerateTimeControlCertificateForCustomerOptions] = useState<DTOTimeControlCertificateOption[]>([]);
  const [generateTimeControlCertificateForCustomerIsLoading, setGenerateTimeControlCertificateForCustomerIsLoading] = useState(false);
  const [selectedOptionsToGenerateTimeControlCertificateForCustomer, setSelectedOptionsToGenerateTimeControlCertificateForCustomer] = useState<string[]>([]);

  useEffect(() => {
    getTimeControlSummaryByEmployee(new Date().getFullYear());
  }, []);

  const getTimeControlSummaryByEmployee = async (year: number) => {
    await GetTimeControlSummaryByEmployee(Number(workCenterId), Number(employeeId), year)
      .then((result) => {
        setTimeControlSummary(result);
        setIsLoading(false);
      })
  };

  const onRefresh = async () => {
    await getTimeControlSummaryByEmployee(new Date().getFullYear());
  };

  const handleGenerateTimeControlCertificateForEmployeeCascaderChange = (value: (string | number)[][]) => {
    if (value) {
      const dates = value.map(([year, month]) => `${Number(year)}-${Number(month)}-01`);
      setSelectedOptionsToGenerateTimeControlCertificateForCustomer(dates);
    }
  };

  return (
    <div className="px-2 sm:px-4">
      <SimpleHeaderWithTwoButtons
        title={`${t("timeControl.title")} - ${t("timeControl.employeeView")}`}
        textSize="text-3xl"
        buttonText1={t("common.goBack")}
        button1Click={() => Number(workCenterId) === 0 ? navigate(`/time-control`) : navigate(`/time-control/work-center/${workCenterId}`)}
      />
      {timeControlSummary !== null ? (
        <div>
          {(timeControlSummary && timeControlSummary.information) ? (
            <Alert type={AlertType.Info} text={timeControlSummary.information} />
          ) : (
            <Skeleton className="mt-5" active paragraph={{ rows: 1 }} />
          )}

          <div className="mt-7">
            {employeeInfo ? (
              <TimeControlSummaryEmployeeInfo employee={employeeInfo} />
            ) : (
              <Skeleton active paragraph={{ rows: 2 }} />
            )}
          </div>

          <div className="mt-7">
            <div className="flex justify-between items-center mb-6">
              <div className="flex items-center space-x-4">
                <h3 className="text-2xl font-semibold leading-6 text-gray-900">
                  {t("common.year")}
                </h3>
                <Select
                  options={years}
                  placeholder=""
                  size="large"
                  onChange={(selectedValue: string) => {
                    getTimeControlSummaryByEmployee(Number(selectedValue))
                    setSelectedYear(Number(selectedValue))
                  }}
                  defaultValue={new Date().getFullYear().toString()}
                />
              </div>
              <button
                type="button"
                className="relative inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                onClick={async () => {
                  setGenerateTimeControlCertificateForCustomerModal(true);
                }}
              >
                {t("timeControl.generateTimeControlCertificateForCustomer")}
              </button>
              <ModalAdvanced
                title={t("timeControl.generateTimeControlCertificateForCustomer")}
                subtitle={t("timeControl.generateTimeControlCertificateForCustomerSubtitle")}
                okText={t("common.generate")}
                onOk={async () => {
                  setIsLoading(true);
                  await GenerateTimeControlCertificateForCustomer(Number(employeeId), Number(workCenterId), selectedOptionsToGenerateTimeControlCertificateForCustomer);
                  setIsLoading(false);
                }}
                isOpen={generateTimeControlCertificateForCustomerModal}
                onClose={() => setGenerateTimeControlCertificateForCustomerModal(false)}
              >
                <Cascader
                  style={{ width: '100%' }}
                  onChange={handleGenerateTimeControlCertificateForEmployeeCascaderChange}
                  placeholder={t("timeControl.generateTimeControlCertificateForCustomerPlaceholder")}
                  multiple
                  maxTagCount="responsive"
                  showCheckedStrategy="SHOW_CHILD"
                  allowClear={true}
                  displayRender={(labels) => labels.join(' ')}
                  onDropdownVisibleChange={async open => {
                    if (open && generateTimeControlCertificateForCustomerOptions.length === 0) {
                      setGenerateTimeControlCertificateForCustomerIsLoading(true);
                      await GetTimeControlCertificateForEmployeeOptions(Number(employeeId), Number(workCenterId)).then((generateTimeControlCertificateForEmployeeOptions) => {
                        setGenerateTimeControlCertificateForCustomerOptions(
                          generateTimeControlCertificateForEmployeeOptions.map(option => ({
                            ...option,
                            children: option.children?.map(child => ({
                              ...child,
                              label: t(`common.month${child.label}`)
                            }))
                          }))
                        );
                      });
                      setGenerateTimeControlCertificateForCustomerIsLoading(false);
                    }
                  }}
                  loading={generateTimeControlCertificateForCustomerIsLoading}
                  options={generateTimeControlCertificateForCustomerOptions}
                />
              </ModalAdvanced>
            </div>
          </div>

          {timeControlSummary ? (
            <>
              <div className="mt-5">
                <TimeControlSummaryStats timeControlSummary={timeControlSummary} />
              </div>

              <div className="mt-4">
                <TimeControlSummaryTable workCenterId={Number(workCenterId)} employeeId={Number(employeeId)} year={selectedYear} timeControlSummary={timeControlSummary} onRefresh={onRefresh} />
              </div>
            </>
          ) : (
            <Skeleton className="mt-5" active paragraph={{ rows: 7 }} />
          )}

          <div className="mb-10"></div>
        </div>
      ) : (
        <div>
          <div className="mt-7">
            {employeeInfo ? (
              <TimeControlSummaryEmployeeInfo employee={employeeInfo} />
            ) : employeeInfo === null ? (
              <div className="mt-7">
                <p className="px-6 py-4 text-base text-center italic">{t("timeControl.summaryLoadEmployeeNotFound")}</p>
              </div>
            ) : (
              <Skeleton active paragraph={{ rows: 2 }} />
            )}
          </div>
          <div className="mt-7">
            <div className="flex justify-between items-center mb-6">
              <div className="flex items-center space-x-4">
                <h3 className="text-2xl font-semibold leading-6 text-gray-900">
                  {t("common.year")}
                </h3>
                <Select
                  options={years}
                  placeholder=""
                  size="large"
                  onChange={(selectedValue: string) => {
                    getTimeControlSummaryByEmployee(Number(selectedValue))
                  }}
                  defaultValue={new Date().getFullYear().toString()}
                />
              </div>
            </div>
          </div>
          <div className="mt-7">
            <p className="px-6 py-4 text-base text-center italic">{t("timeControl.summaryLoadNoDataMessage")}</p>
          </div>
        </div>
      )}
    </div >
  )
}
