import { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from "react-i18next";
import { NotificationContext } from '../../../contexts/NotificationContext';
import { useNavigate, useParams } from 'react-router-dom';
import SimpleHeaderWithTwoButtons from '../../../components/common/header/SimpleHeaderWithTwoButtons';
import { useSpinner } from '../../../contexts/SpinnerContext';
import { Calendar, DefaultCalendar } from '../../../interfaces/calendar/Calendar';
import Form from '../../../components/common/form/Form';
import * as Yup from 'yup';
import { FormikProps } from 'formik';
import { FieldTypes, FormField } from '../../../components/common/form/FormField';
import { NotificationTypes } from '../../../components/common/Notification';
import { GetAnnualCalendar, Post, Put } from '../../../services/calendar/CalendarService';
import { UseCalendar } from '../../../hooks/calendar/UseCalendar';
import { AnnualCalendar } from '../../../interfaces/common/calendar/AnnualCalendar';
import AnnualCalendarTemplate from '../../../components/common/calendar/AnnualCalendarTemplate';
import { Tooltip } from 'antd';
import { InformationCircleIcon } from '@heroicons/react/24/solid';
import { FormFieldReadOnly } from '../../../components/common/form/FormFieldReadOnly';
import { OptionType, UseYesNoOptions } from '../../../hooks/utils/UseUtils';

export default function CalendarDetail() {
  const pageName = 'CalendarDetail';
  const { t } = useTranslation();
  const { showNotification } = useContext(NotificationContext);
  const { setIsLoading } = useSpinner();

  const navigate = useNavigate();
  const { workCenterId, calendarId } = useParams();

  const [calendar, setCalendar] = UseCalendar(Number(calendarId));

  const yesNoOptions = UseYesNoOptions();

  useEffect(() => {
    if (Number(calendarId) === 0) {
      fetchAnnualCalendarByYear();
    }
  }, []);

  const fetchAnnualCalendarByYear = async () => {
    await GetAnnualCalendar(Number(calendarId), new Date().getFullYear())
      .then((annualCalendar) => {
        setCalendar(prevCalendar => {
          if (prevCalendar === null) prevCalendar = DefaultCalendar();
          return {
            ...prevCalendar,
            annualCalendar: annualCalendar
          };
        });
      })
  };

  const calendarFormRef = useRef<FormikProps<Calendar>>();
  const calendarValidationSchema = Yup.object({
    year: Yup.string().required('Debe seleccionar un año'),
    name: Yup.string().required('Debe indicar un nombre para la plantilla'),
  });

  const handleValidateAndSubmit = async () => {
    if (calendarFormRef.current) {
      const calendarValues = await calendarFormRef.current.submitForm();
      const calendarErrors = await calendarFormRef.current.validateForm();

      if (Object.keys(calendarErrors).length === 0) {
        updateWorkShift(calendarValues!);
      } else {
        showNotification({ color: NotificationTypes.Danger, messageTitle: t("common.errorDataValidation"), messageText: Object.values(calendarErrors).map(error => `- ${error}`).join('\n') });
      }
    }
  };

  const updateWorkShift = async (calendar: Calendar) => {
    setIsLoading(true);
    if (calendar.id === 0) {
      calendar.workCenterId = Number(workCenterId);
      await Post(calendar).then(async (result) => {
        setIsLoading(false);
        if (result) {
          navigate(`/work-centers/${workCenterId}`);
        }
      })
    } else {
      await Put(calendar).then(async (result) => {
        setIsLoading(false);
        if (result) {
          showNotification({ color: NotificationTypes.Success, messageTitle: t("common.updatedOkTitle"), messageText: t("common.updatedOkSubtitle") });
        }
      })
    }
  };

  const handleYearChange = async (year: number) => {
    await GetAnnualCalendar(Number(calendarId), year)
      .then((annualCalendar) => {
        setCalendar(prevCalendar => {
          if (prevCalendar === null) return null;
          return {
            ...prevCalendar,
            year: year,
            annualCalendar: annualCalendar
          };
        });
      })
  };

  const handleCalendarComponentChange = (annualCalendar: AnnualCalendar | null) => {
    setCalendar(prevCalendar => {
      if (prevCalendar === null) return null;
      return {
        ...prevCalendar,
        annualCalendar: annualCalendar
      };
    });
  };

  return (
    <div className="px-4">
      <SimpleHeaderWithTwoButtons
        title={t("calendar.calendar")}
        textSize="text-3xl"
        buttonText1={t("common.goBack")}
        buttonText2={t("common.save")}
        button1Click={() => navigate(`/work-centers/${workCenterId}`)}
        button2Click={handleValidateAndSubmit}
      />
      <div className="mt-4">
        <Form initialValues={calendar || DefaultCalendar()} validationSchema={calendarValidationSchema} ref={calendarFormRef}>
          <h2 className="text-base font-semibold leading-7 text-gray-900">{t("calendar.templateConfigurationTitle")}</h2>
          <p className="mt-1.5 mb-6 text-sm italic text-gray-700">
            {t("calendar.templateConfigurationSubtitle")}
          </p>
          <div className="space-y-10">
            <div className="pb-12">
              <div className="mt-4 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                {Number(calendarId) === 0 ? (
                  <FormField
                    name="year"
                    label={t("calendar.year")}
                    customType={FieldTypes.Select} options={[{ value: "2024", label: "2024" }, { value: "2025", label: "2025" }]}
                    onChange={async (selectedYear: number) => {
                      await handleYearChange(selectedYear);
                    }} />
                ) : (
                  <FormFieldReadOnly colspan={1} label={t("calendar.year")} value={calendar?.year.toString()} />
                )}
                <FormField
                  colspan={1}
                  name="isDefault"
                  label={t("calendar.isDefault")}
                  customType={FieldTypes.Select}
                  options={yesNoOptions.map((yesNoOption: OptionType) => ({ value: yesNoOption.value, label: yesNoOption.label }))}
                  onChange={(isDefault) => {
                    setCalendar(prevCalendar => {
                      if (prevCalendar === null) return null;
                      return {
                        ...prevCalendar,
                        isDefault
                      };
                    });
                  }}
                />
                <FormField
                  colspan={2}
                  name="name"
                  label={t("calendar.name")}
                  onChange={(name) => {
                    setCalendar(prevCalendar => {
                      if (prevCalendar === null) return null;
                      return {
                        ...prevCalendar,
                        name: name
                      };
                    });
                  }}
                />
              </div>
              <div className="mt-4 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                <FormField
                  colspan={4}
                  name="remarks"
                  label={t("calendar.remarks")}
                  customType={FieldTypes.Textarea}
                  onChange={(remarks) => {
                    setCalendar(prevCalendar => {
                      if (prevCalendar === null) return null;
                      return {
                        ...prevCalendar,
                        remarks
                      };
                    });
                  }}
                />
              </div>
            </div>
          </div>
          <h2 className="text-base font-semibold leading-7 text-gray-900">{t("calendar.calendarComponentTitle")}</h2>
          <div className="flex justify-start items-center mt-1.5">
            <Tooltip placement="topLeft" title={t("calendar.calendarComponentTooltip")} color="geekblue">
              <InformationCircleIcon className="h-7 w-7 text-blue-600" aria-hidden="true" />
            </Tooltip>
            <p className="ml-2.5 text-sm italic text-gray-700">
              {t("calendar.calendarComponentSubtitle")}
            </p>
          </div>
          <AnnualCalendarTemplate
            calendar={calendar?.annualCalendar || null}
            onCalendarChange={handleCalendarComponentChange}
          />
        </Form>
      </div>
    </div >
  )
}
