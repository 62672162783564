export interface User {
    id: number;
    username: string;
    password?: string,
    language: string;
    permissions: { [key: string]: { [key: string]: {} } };
    isActive: boolean;
}

export function DefaultUser(): User {
    return {
        id: 0,
        username: "",
        password: "",
        language: "es",
        permissions: { "EmployeePortal": { "timeControl": {}, "home": {} } },
        isActive: true,
    };
}