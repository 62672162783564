import { Employee } from "../employee/Employee";
import { WorkCenter } from "../work-center/WorkCenter";
import { WorkTime } from "./WorkTime";
import { TimeOff } from "./TimeOff";
import { WorkShiftAttachment } from "./WorkShiftAttachment";

export interface WorkShift {
    id: number;
    employeeId: number;
    startShift: Date | null;
    endShift: Date | null;
    isActive: boolean;
    isValidated: boolean;
    employeeRemarks?: string;
    humanResourcesRemarks?: string;
    systemRemarks?: string;
    employee?: Employee;
    workTimes?: WorkTime[];
    timeOffs?: TimeOff[];
    totalWorkTime?: string;
    attachments?: WorkShiftAttachment[];
    workCenterId: number;
    workCenter?: WorkCenter;
    jobId?: number;
    isEditable: boolean;
}

export function DefaultWorkShift(): WorkShift {
    return {
        id: 0,
        employeeId: 0,
        startShift: null,
        endShift: null,
        isActive: true,
        isValidated: false,
        workTimes: [],
        timeOffs: [],
        workCenterId: 0,
        jobId: 0,
        isEditable: true
    };
}