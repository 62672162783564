import { CheckCircleIcon, XCircleIcon, ExclamationTriangleIcon, InformationCircleIcon } from '@heroicons/react/20/solid';
import { AlertProps } from "../../../interfaces/common/alert/AlertProps";

export enum AlertType {
    Info = "info",
    Success = "success",
    Warning = "warning",
    Error = "error",
}

const alertStyles = {
    success: 'border-green-400 bg-green-50 text-green-800',
    error: 'border-red-400 bg-red-50 text-red-800',
    warning: 'border-yellow-400 bg-yellow-50 text-yellow-800',
    info: 'border-blue-400 bg-blue-50 text-blue-800',
};

const alertIcons = {
    success: CheckCircleIcon,
    error: XCircleIcon,
    warning: ExclamationTriangleIcon,
    info: InformationCircleIcon,
};

export default function Alert({ text, type = AlertType.Info }: AlertProps) {
    const IconComponent = alertIcons[type];

    return (
        <div className={`p-4 my-6 border-l-4 ${alertStyles[type]}`}>
            <div className="flex">
                <div className="flex-shrink-0">
                    <IconComponent className="h-5 w-5" aria-hidden="true" />
                </div>
                <div className="ml-3">
                    <p className="text-sm font-medium text-justify">{text}</p>
                </div>
            </div>
        </div>
    );
}