import { useTranslation } from "react-i18next";
import { NotificationContext } from '../../../contexts/NotificationContext';
import { NotificationTypes } from "../../../components/common/Notification";
import Form from "../../../components/common/form/Form";
import { FieldTypes, FormField } from "../../../components/common/form/FormField";
import { useContext, useEffect, useRef, useState } from "react";
import { DefaultEmployee, Employee } from "../../../interfaces/employee/Employee";
import * as Yup from 'yup';
import { FormikProps } from "formik";
import { WorkCenter } from "../../../interfaces/work-center/WorkCenter";
import { GetWorkCenters } from "../../../services/company/CompanyService";
import { DefaultUser, User } from "../../../interfaces/user/User";
import { PostEmployee } from "../../../services/employee/EmployeeService";
import AlertModal from "../../../components/common/alert/AlertModal";
import { UseCompanies } from "../../../hooks/company/UseCompany";

interface EmployeeAddProps {
    onUpdateParent: () => void;
    isLoading: (state: boolean) => void;
    closeModal: () => void;
}

export default function EmployeeAdd(props: EmployeeAddProps) {
    const { t } = useTranslation();
    const [employee, setEmployee] = useState<Employee>(DefaultEmployee());
    const companies = UseCompanies();
    const { showNotification } = useContext(NotificationContext);

    const employeeFormRef = useRef<FormikProps<Employee>>();

    const employeeValidationSchema = Yup.object({
        workCenterId: Yup.string()
            .required('Debe seleccionar un centro de trabajo')
            .notOneOf(['0'], 'Debe seleccionar un centro de trabajo'),
        documentNumber: Yup.string().required('El documento es obligatorio'),
        firstName: Yup.string().required('El nombre es obligatorio'),
        lastName1: Yup.string().required('El primer apellido es obligatorio'),
        email: Yup.string().email('Introduzca un email válido').required('El email es obligatorio'),
    });

    const handleValidateAndSubmit = async () => {
        if (employeeFormRef.current) {
            const employeeValues = await employeeFormRef.current.submitForm();
            const employeeErrors = await employeeFormRef.current.validateForm();

            if (Object.keys(employeeErrors).length === 0) {
                addEmployee(employeeValues!);
            }
        }
    };

    const addEmployee = async (employee: Employee) => {
        let newUser: User = DefaultUser();
        newUser.username = employee.documentNumber!;
        newUser.password = `${employee.documentNumber}*`;
        employee.user = newUser;
        employee.isAddedManually = true;
        props.isLoading(true);
        const result = await PostEmployee(employee);
        props.isLoading(false);
        if (result) {
            props.onUpdateParent();
            props.closeModal();
        }
    };

    return (
        <>
            <h3 className="mt-0 mb-2 text-xl font-medium leading-6 text-gray-900">
                {t("employee.addEmployee")}
            </h3>
            <p className="mt-4 text-sm italic text-justify text-red-700">
                {t("employee.addEmployeeHelpText1")}
            </p>
            <p className="mt-4 mb-6 text-sm italic text-justify text-red-700">
                {t("employee.addEmployeeHelpText2")}
            </p>
            <Form initialValues={employee} validationSchema={employeeValidationSchema} ref={employeeFormRef}>
                <div className="space-y-7">
                    <div className="border-t border-b border-gray-900/10 pt-2 pb-10">
                        <div className="mt-4 grid gap-x-6 gap-y-8 grid-cols-1">
                            <FormField
                                label={`${t("employee.company")} | ${t("employee.workCenter")}`}
                                name="workCenterId"
                                customType={FieldTypes.SelectWithFinder}
                                options={[{ value: "", label: t("common.select") }]
                                    .concat(
                                        companies
                                            .flatMap(company =>
                                                company.workCenters!.map(workCenter => ({
                                                    value: workCenter.id.toString(),
                                                    label: `${company.code} ${company.name} | ${workCenter.code} ${workCenter.name}`,
                                                    companyCode: +company.code!,
                                                    workCenterCode: +workCenter.code!
                                                }))
                                            )
                                            .sort((a, b) => {
                                                if (a.companyCode !== b.companyCode) {
                                                    return a.companyCode - b.companyCode;
                                                }
                                                return a.workCenterCode - b.workCenterCode;
                                            })
                                            .map(option => ({
                                                value: option.value,
                                                label: option.label
                                            }))
                                    )
                                }
                            />
                        </div>
                        <div className="mt-4 grid gap-x-6 gap-y-8 grid-cols-6">
                            <FormField colspan={2} label={t("employee.documentType")} name="documentType" customType={FieldTypes.Select}
                                options={[
                                    { value: 'DNI', label: t("employee.dni") },
                                    { value: 'NIE', label: t("employee.nie") },
                                    { value: 'PASSPORT', label: t("employee.passport") }
                                ]}
                            />
                            <FormField colspan={2} label={t("employee.documentNumber")} name="documentNumber" />
                        </div>
                        <div className="mt-4 grid gap-x-6 gap-y-8 grid-cols-6">
                            <FormField colspan={2} label={t("employee.firstName")} name="firstName" />
                            <FormField colspan={2} label={t("employee.lastName1")} name="lastName1" />
                            <FormField colspan={2} label={t("employee.lastName2")} name="lastName2" placeholder="Dejar en blanco si no tiene" />
                        </div>
                        <div className="mt-4 grid gap-x-6 gap-y-8 grid-cols-6">
                            <FormField colspan={4} label={t("employee.email")} name="email" />
                        </div>
                    </div>
                    <div className="flex justify-end mb-6 space-x-4">
                        <button
                            type="button"
                            className="mb-1 rounded-md bg-orange-50 px-3.5 py-2.5 text-sm font-semibold text-orange-600 shadow-sm hover:bg-orange-100"
                            onClick={() => props.closeModal()}
                        >
                            {t("common.cancel")}
                        </button>
                        <button
                            type="button"
                            className="mb-1 rounded-md bg-indigo-50 px-3.5 py-2.5 text-sm font-semibold text-indigo-600 shadow-sm hover:bg-indigo-100"
                            onClick={() => handleValidateAndSubmit()}
                        >
                            {t("employee.addEmployee")}
                        </button>
                    </div>
                </div>
            </Form>
        </>
    )
}