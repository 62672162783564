import { useTranslation } from "react-i18next";
import { NotificationContext } from '../../../contexts/NotificationContext';
import Form from "../../../components/common/form/Form";
import { FieldTypes, FormField } from "../../../components/common/form/FormField";
import { useContext, useRef, useState } from "react";
import * as Yup from 'yup';
import { FormikProps } from "formik";
import AlertModal from "../../../components/common/alert/AlertModal";
import { WorkCenter } from "../../../interfaces/work-center/WorkCenter";
import { PostWorkCenterTimeControlResponsible } from "../../../services/company/CompanyService";
import { UseEmployees } from "../../../hooks/employee/UseEmployee";
import { Employee } from "../../../interfaces/employee/Employee";
import { DefaultWorkCenterTimeControlResponsible, WorkCenterTimeControlResponsible } from "../../../interfaces/company/WorkCenterTimeControlResponsible";

interface WorkCenterTimeControlResponsibleAddProps {
    workCenter: WorkCenter;
    onUpdateParent: () => void;
    isLoading: (state: boolean) => void;
    closeModal: () => void;
}

export default function WorkCenterTimeControlResponsibleAdd(props: WorkCenterTimeControlResponsibleAddProps) {
    const { t } = useTranslation();
    const [workCenterTimeControlResponsible, setWorkCenterTimeControlResponsible] = useState<WorkCenterTimeControlResponsible>(DefaultWorkCenterTimeControlResponsible());
    const { showNotification } = useContext(NotificationContext);
    const [updateErrors, setUpdateErrors] = useState<string>("");
    const [validationErrors, setValidationErrors] = useState<string>("");
    const employees = UseEmployees();

    const workCenterTimeControlResponsibleFormRef = useRef<FormikProps<WorkCenterTimeControlResponsible>>();

    const workCenterTimeControlResponsibleValidationSchema = Yup.object({
        employeeId: Yup.string().required('Debe seleccionar un responsable').notOneOf(['0'], 'Debe seleccionar un responsable'),
    });

    const handleValidateAndSubmit = async () => {
        if (workCenterTimeControlResponsibleFormRef.current) {
            const workCenterTimeControlResponsibleValues = await workCenterTimeControlResponsibleFormRef.current.submitForm();
            const workCenterTimeControlResponsibleErrors = await workCenterTimeControlResponsibleFormRef.current.validateForm();

            if (Object.keys(workCenterTimeControlResponsibleErrors).length === 0) {
                addWorkCenterTimeControlResponsible(workCenterTimeControlResponsibleValues!);
            } else {
                setValidationErrors(Object.values(workCenterTimeControlResponsibleErrors).map(error => `- ${error}`).join('\n'));
            }
        }
    };

    const addWorkCenterTimeControlResponsible = async (workCenterTimeControlResponsible: WorkCenterTimeControlResponsible) => {
        workCenterTimeControlResponsible.workCenterId = props.workCenter.id;
        props.isLoading(true);
        if (await PostWorkCenterTimeControlResponsible(workCenterTimeControlResponsible) !== undefined) {
            props.onUpdateParent();
            props.closeModal();
        }
        props.isLoading(false);
    };

    return (
        <>
            <h3 className="mt-0 mb-2 text-xl font-medium leading-6 text-gray-900">
                {t("workCenter.addWorkCenterTimeControlResponsible")}
            </h3>
            <AlertModal title={t("common.updatedErrorTitle")} text={t(updateErrors)} type="error" isOpen={updateErrors !== ""} onClose={() => setUpdateErrors("")} />
            <AlertModal title={t("common.errorDataValidation")} text={t(validationErrors)} type="warning" isOpen={validationErrors !== ""} onClose={() => setValidationErrors("")} />
            <Form initialValues={workCenterTimeControlResponsible} validationSchema={workCenterTimeControlResponsibleValidationSchema} ref={workCenterTimeControlResponsibleFormRef}>
                <div className="space-y-7">
                    <div className="border-t border-b border-gray-900/10 pt-2 pb-10">
                        <div className="mt-4 grid gap-x-6 gap-y-8 grid-cols-4">
                            <FormField colspan={4}
                                label={t("workCenter.responsibleEmployee")} name="employeeId" customType={FieldTypes.SelectWithFinder}
                                options={[{ value: "", label: t("common.select") }].concat(employees.map((employee: Employee) => ({ value: employee.id.toString(), label: `${employee.lastName1} ${employee.lastName2}, ${employee.firstName}` })))}
                            />
                        </div>
                    </div>
                    <div className="flex justify-end mb-6 space-x-4">
                        <button
                            type="button"
                            className="mb-1 rounded-md bg-orange-50 px-3.5 py-2.5 text-sm font-semibold text-orange-600 shadow-sm hover:bg-orange-100"
                            onClick={() => props.closeModal()}
                        >
                            {t("common.cancel")}
                        </button>
                        <button
                            type="button"
                            className="mb-1 rounded-md bg-indigo-50 px-3.5 py-2.5 text-sm font-semibold text-indigo-600 shadow-sm hover:bg-indigo-100"
                            onClick={() => handleValidateAndSubmit()}
                        >
                            {t("common.save")}
                        </button>
                    </div>
                </div>
            </Form>
        </>
    )
}