import { useState, useContext, useRef, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { NotificationContext } from '../../../contexts/NotificationContext';
import { BuildingOffice2Icon } from '@heroicons/react/20/solid'
import Tabs from '../../../components/common/navigation/Tabs';
import { useNavigate, useParams } from 'react-router-dom';
import SimpleHeaderWithTwoButtons from '../../../components/common/header/SimpleHeaderWithTwoButtons';
import { DefaultWorkCenter, WorkCenter } from '../../../interfaces/work-center/WorkCenter';
import { PatchCompany, PatchWorkCenter, PostWorkCenter, PostWorkCenterCalendar } from '../../../services/company/CompanyService';
import { FormField } from '../../../components/common/form/FormField';
import { NotificationTypes } from '../../../components/common/Notification';
import * as Yup from 'yup';
import Form from '../../../components/common/form/Form';
import { FormikProps } from 'formik';
import { useSpinner } from '../../../contexts/SpinnerContext';
import AlertModal from '../../../components/common/alert/AlertModal';
import { UseCompany } from '../../../hooks/company/UseCompany';
import { Company } from '../../../interfaces/company/Company';
import UploadFiles from '../../../components/common/upload/UploadFiles';
import { Resource } from '../../../interfaces/resource/Resource';
import { AddResource } from '../../../services/resource/ResourceService';

export default function WorkCenterDetail() {
  const { t } = useTranslation();
  const { showNotification } = useContext(NotificationContext);
  const { setIsLoading } = useSpinner();
  const [activeTab, setActiveTab] = useState<string>("generalData");
  const [validationErrors, setValidationErrors] = useState<string>("");
  const [stamp, setStamp] = useState<string | null>(null);
  const [companyUpdatedOk, setCompanyUpdatedOk] = useState<string>("");
  const [companyUpdatedError, setCompanyUpdatedError] = useState<string>("");

  const workCenterFormRef = useRef<FormikProps<WorkCenter>>();

  const navigate = useNavigate();
  const { id } = useParams();

  const company = UseCompany(Number(id));

  const tabs = [
    { key: "generalData", name: t("workCenter.generalData"), icon: BuildingOffice2Icon, current: true },
  ];

  const changeTab = (tabName: string) => {
    setActiveTab(tabName);
  };

  const workCenterValidationSchema = Yup.object({});

  const handleValidateAndSubmit = async () => {
    updateCompany(company!);
  };

  const updateCompany = async (company: Company) => {
    if (company.stamp?.content) {
      setIsLoading(true);
      let stampId: number | null = 0;
      stampId = await AddResource(company.stamp?.content);
      if (stampId && stampId > 0) {
        const result = await PatchCompany(company.id, [
          { fieldName: "stampId", fieldValue: stampId },
        ]);
        if (result) {
          setIsLoading(false);
          setCompanyUpdatedOk(t("common.updatedOkTitle").toString());
        } else {
          setIsLoading(false);
          setCompanyUpdatedError(t("common.updatedErrorTitle").toString());
        }
      }
    }
    else {
      setCompanyUpdatedOk(t("common.updatedOkTitle").toString());
    }
  };

  const updateCompanyStamp = (selectedFile: File | null) => {
    if (selectedFile) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader.result;
        setStamp(base64String!.toString());
      };
      reader.readAsDataURL(selectedFile);

      const newCompany: Company = company!;
      newCompany.stamp = {
        id: 0,
        content: selectedFile
      };
    }
  };

  return (
    <div className="px-4">
      <AlertModal title={t("common.updatedOkTitle")} text={companyUpdatedOk} type="success" isOpen={companyUpdatedOk !== ""} onClose={() => setCompanyUpdatedOk("")} />
      <AlertModal title={t("common.updatedErrorTitle")} text={companyUpdatedError} type="error" isOpen={companyUpdatedError !== ""} onClose={() => setCompanyUpdatedError("")} />
      <SimpleHeaderWithTwoButtons
        title={company && company.id > 0
          ? `${t("company.name")}: ${company.code} - ${company.name}`
          : t("common.loading")}
        textSize="text-3xl"
        buttonText1={t("common.goBack")}
        buttonText2={t("common.save")}
        button1Click={() => navigate("/companies")}
        button2Click={handleValidateAndSubmit}
      />
      <div className="mt-4">
        <Tabs setTab={changeTab} activeTab={activeTab} tabs={tabs} />
        <div className="mt-8">
          <AlertModal title={t("common.errorDataValidation")} text={t(validationErrors)} type="warning" isOpen={validationErrors !== ""} onClose={() => setValidationErrors("")} />
          <div className="space-y-10">
            <div className="pb-12">
              <h2 className="text-base font-semibold leading-7 text-gray-900">{t("company.stamp")}</h2>
              {stamp ? (
                <div className="w-1/4 inline-block overflow-hidden rounded-md bg-gray-200">
                  <img
                    src={stamp}
                    className="h-full w-full object-cover object-center"
                  />
                </div>
              ) : company?.stamp?.content ? (
                <div className="w-1/4 inline-block overflow-hidden rounded-md bg-gray-200">
                  <img
                    src={`data:image/jpeg;base64,${company.stamp.content}`}
                    className="h-full w-full object-cover object-center"
                  />
                </div>
              ) : null}
              <div className="mt-4">
                <div className="w-1/2">
                  <UploadFiles
                    onUpdateParent={updateCompanyStamp}
                    text1={t("company.stampAttachDocumentModalText1")}
                    text2={t("company.stampAttachDocumentModalText2")}
                    text3={t("company.stampAttachDocumentModalText3")}
                    allowedExtensions={['png', 'jpg', 'jpeg', 'pdf', 'docx']}
                    extensionErrorMessage={t("company.stampAttachDocumentModalInvalidExtensionError")}
                    maxSize={10}
                    maxSizeErrorMessage={t("company.stampAttachDocumentModalMaxSizeError")}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
