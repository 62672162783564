import { useState, useCallback } from 'react';
import { useTranslation } from "react-i18next";
import { UsersIcon } from '@heroicons/react/20/solid'
import SectionHeader from "../../../components/common/header/SimpleHeader";
import Tabs from '../../../components/common/navigation/Tabs';
import TableDynamic, { FilterConfigArray } from '../../../components/common/list/TableDynamic';
import { GetEmployeeVariations, GetEmployeeVariationsCount, GetEmployeeVariationsExportExcel } from '../../../services/employee/EmployeeService';
import { useNavigate } from 'react-router-dom';
import { ExportTypes } from '../../../interfaces/export/Export';
import { SortCriteria } from '../../../components/common/sort/SortDynamic';
import { usePageState } from '../../../contexts/PageStateContext';

export default function EmployeeList() {
  const pageName = 'EmployeeList';
  const { t } = useTranslation();
  const { state, dispatch } = usePageState();
  const [activeTab, setActiveTab] = useState<string>("employees");
  const [employeeFilter, setEmployeeFilter] = useState<Record<string, any> | undefined>(state[pageName]?.filters || {});
  const [employeeSort, setEmployeeSort] = useState<Record<string, any> | undefined>(state[pageName]?.sort || undefined);

  const navigate = useNavigate();

  const tabs = [
    { key: "employees", name: t("employee.list"), icon: UsersIcon, current: true },
  ];

  const changeTab = (tabName: string) => {
    setActiveTab(tabName);
  };

  const fetchEmployeesData = useCallback(async (page: number, itemsPerPage: number) => {
    if (!employeeFilter) {
      return;
    }
    if (employeeSort) {
      employeeFilter.SortCriteria = employeeSort.map((sort: SortCriteria) => ({
        Field: sort.field,
        Order: sort.order,
      }));
    }
    return GetEmployeeVariations(page, itemsPerPage, employeeFilter);
  }, [employeeFilter, employeeSort]);

  const handleFilterUpdate = (filters: Record<string, any> | undefined) => {
    setEmployeeFilter(filters);
    dispatch({ type: 'SET_PAGE_STATE', payload: { pageKey: pageName, state: { ...state[pageName], filters: filters } } });
  };

  const handleSortUpdate = (sort: Record<string, any> | undefined) => {
    setEmployeeSort(sort);
    dispatch({ type: 'SET_PAGE_STATE', payload: { pageKey: pageName, state: { ...state[pageName], sort: sort } } });
  };

  const filterConfig: FilterConfigArray = [
    { columnName: "workCenter_company_code", type: "text" },
    { columnName: "employee_lastName1", type: "text" },
    { columnName: "employee_lastName2", type: "text" },
    { columnName: "employee_firstName", type: "text" },
    { columnName: "employee_documentNumber", type: "text" },
    { columnName: "conventionCode", type: "text" },
    { columnName: "workingDayPercentage", type: "text" },
    {
      columnName: "end",
      type: "select",
      options: [
        { value: "", label: '--- Todos ---' },
        { value: "IS NULL", label: t("employee.statusActive") },
        { value: "IS NOT NULL", label: t("employee.statusInactive") }
      ]
    },
  ];

  const sortConfig = [
    { columnName: "companyCode", key: 'WorkCenter#Company#Code' },
    { columnName: "lastName1", key: 'Employee#LastName1' },
    { columnName: "lastName2", key: 'Employee#LastName2' },
    { columnName: "firstName", key: 'Employee#FirstName' },
  ];

  return (
    <div className="px-2 sm:px-4">
      <SectionHeader title={t("employee.title")} textSize="text-3xl" />
      <div className="mt-4">
        <Tabs setTab={changeTab} activeTab={activeTab} tabs={tabs} />
        {activeTab === "employees" && (
          <div className="mt-8">
            <TableDynamic
              tableName={`${pageName}_MainView`}
              fetchData={fetchEmployeesData}
              getTotalCount={async () => {
                if (!employeeFilter) {
                  return;
                }
                return GetEmployeeVariationsCount(employeeFilter);
              }}
              getDataExport={async (type) => {
                if (type === ExportTypes.Excel) return GetEmployeeVariationsExportExcel(t("employee.list"), employeeFilter);
                return null;
              }}
              filterConfig={filterConfig}
              defaultFilters={state[pageName]?.filters || {}}
              onFilterChange={handleFilterUpdate}
              sortConfig={sortConfig}
              defaultSort={state[pageName]?.sort || []}
              onSortChange={handleSortUpdate}
              onRowButtonClick={(primaryKeyValue) => {
                navigate(`/employees/${primaryKeyValue}`)
              }}
              itemsPerPageDefault={10}
              translationNode="employee"
            />
          </div>
        )}
        {activeTab === "employees2" && (
          <>&nbsp;</>
        )}
      </div>
    </div>
  )
}
