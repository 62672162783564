import { Employee } from "../../interfaces/employee/Employee";
import { DTOEmployeeInfo } from "../../interfaces/employee/DTOEmployeeInfo";
import { GetCookie } from "../../services/cookie/CookieService";
import { axiosRRHHEmployeeAPI } from '../axios/AxiosRRHHEmployeeAPI';
import { AxiosError } from 'axios';

export async function GetEmployeesCount(
    employeeFilter?: Record<string, any>
): Promise<number> {
    try {
        const response = await axiosRRHHEmployeeAPI.post("/api/employee/GetEmployeesCount", employeeFilter ?? {});

        if (response.status === 200) {
            return response.data;
        }
    } catch (error) {
        console.error('Error en la solicitud:', error);
    }
    return 0;
}

export async function GetEmployees(
    page?: number,
    itemsPerPage?: number,
    employeeFilter?: Record<string, any>
): Promise<Employee[]> {
    try {
        let endpoint: string = "";

        if (page) {
            endpoint = `/api/employee/GetEmployees/${page}/${itemsPerPage}`;
        } else {
            endpoint = "/api/employee/GetEmployees";
        }

        const response = await axiosRRHHEmployeeAPI.post(endpoint, employeeFilter ?? {});

        if (response.status === 200) {
            return response.data;
        }
    } catch (error) {
        console.error('Error en la solicitud:', error);
    }
    return [];
}

export async function GetEmployee(
    employeeId: number
): Promise<Employee | null> {
    try {
        const response = await axiosRRHHEmployeeAPI.get(`/api/employee/${employeeId}`);

        if (response.status === 200) {
            return response.data;
        }
    } catch (error) {
        console.error('Error en la solicitud:', error);
    }
    return null;
}

export async function GetEmployeeDetail(
    employeeId: number
): Promise<DTOEmployeeInfo | null> {
    try {
        const response = await axiosRRHHEmployeeAPI.get(`/api/employee/GetEmployeeDetail/${employeeId}`);

        if (response.status === 200) {
            return response.data;
        }
    } catch (error) {
        console.error('Error en la solicitud:', error);
    }
    return null;
}

export async function GetEmployeeVariationsCount(
    employeeFilter?: Record<string, any>
): Promise<number> {
    try {
        const response = await axiosRRHHEmployeeAPI.post("/api/employee/GetEmployeeVariationsCount", employeeFilter ?? {});

        if (response.status === 200) {
            return response.data;
        }
    } catch (error) {
        console.error('Error en la solicitud:', error);
    }
    return 0;
}

export async function GetEmployeeVariations(
    page?: number,
    itemsPerPage?: number,
    employeeFilter?: Record<string, any>
): Promise<Employee[]> {
    try {
        let endpoint: string = "";

        if (page) {
            endpoint = `/api/employee/GetEmployeeVariations/${page}/${itemsPerPage}`;
        } else {
            endpoint = "/api/employee/GetEmployeeVariations";
        }

        const response = await axiosRRHHEmployeeAPI.post(endpoint, employeeFilter ?? {});

        if (response.status === 200) {
            return response.data;
        }
    } catch (error) {
        console.error('Error en la solicitud:', error);
    }
    return [];
}

export async function GetEmployeeVariationsExportExcel(
    fileName: string,
    employeeFilter?: Record<string, any>
): Promise<void> {
    try {
        const response = await axiosRRHHEmployeeAPI.post("/api/employee/GetEmployeeVariationsExportExcel", employeeFilter ?? {}, {
            responseType: 'blob',
        });

        if (response.status === 200) {
            const url = window.URL.createObjectURL(new Blob([response.data]));

            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${fileName}.xlsx`);
            document.body.appendChild(link);

            link.click();
            link.parentNode?.removeChild(link);
        }
    } catch (error) {
        console.error('Error en la solicitud:', error);
    }
}

export async function GetEmployeeFromCookie(): Promise<Employee | null> {
    try {
        const employee = JSON.parse(GetCookie("employee") || "{}") as Employee;
        return employee;
    } catch (error) {
        console.error('Error en la solicitud:', error);
    }
    return null;
}

export async function PostEmployee(
    employee: Employee
): Promise<Employee | string> {
    try {
        const response = await axiosRRHHEmployeeAPI.post("/api/employee", employee);

        if (response.status === 200) {
            return response.data;
        }
    } catch (error) {
        console.error('Error en la solicitud:', error);
        const axiosError = error as AxiosError;
        if (axiosError.response && axiosError.response.data && typeof axiosError.response.data === "string") {
            return axiosError.response.data;
        }
    }
    return "";
}

export async function PatchEmployee(
    employeeId: number,
    updates: { fieldName: string, fieldValue: any }[]
): Promise<Employee | null> {
    try {
        const patchEmployee = updates.map(update => {
            return { op: "replace", path: `/${update.fieldName}`, value: update.fieldValue };
        });

        const response = await axiosRRHHEmployeeAPI.patch(`/api/employee/${employeeId}`, patchEmployee);

        if (response.status === 200) {
            return response.data;
        }
    } catch (error) {
        console.error('Error en la solicitud:', error);
    }
    return null;
}

export async function PutEmployee(
    employee: Employee
): Promise<Employee | string> {
    try {
        const response = await axiosRRHHEmployeeAPI.put("/api/employee", employee);

        if (response.status === 200) {
            return response.data;
        }
    } catch (error) {
        console.error('Error en la solicitud:', error);
        const axiosError = error as AxiosError;
        if (axiosError.response && axiosError.response.data && typeof axiosError.response.data === "string") {
            return axiosError.response.data;
        }
    }
    return "";
}