import { useEffect, useState } from "react";
import { Calendar } from "../../interfaces/calendar/Calendar";
import { Get, GetCalendarOptionsIncludingCompanyWorkCenterYear, GetCalendarsByWorkCenterId } from "../../services/calendar/CalendarService";
import { DTOCalendarOption } from "../../interfaces/calendar/DTOCalendarOption";

export const UseCalendarsByWorkCenterId = (workCenterId: number): [Calendar[], () => Promise<void>] => {
    const [data, setData] = useState<Calendar[]>([]);

    const loadCalendar = async () => {
        try {
            await GetCalendarsByWorkCenterId(workCenterId)
                .then((result) => {
                    setData(result);
                });
        } catch (err) {
            console.error(err);
        }
    };

    useEffect(() => {
        if (workCenterId) {
            loadCalendar();
        }
    }, [workCenterId]);

    return [data, loadCalendar];
};

export const UseCalendar = (id: number): [Calendar | null, React.Dispatch<React.SetStateAction<Calendar | null>>] => {
    const [data, setData] = useState<Calendar | null>(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                await Get(id)
                    .then((result) => {
                        setData(result);
                    });
            } catch (err) {
                console.log(err as Error);
            }
        };

        if (id && id > 0) {
            fetchData();
        }
    }, [id]);

    return [data, setData];
};