import { Job } from "../job/Job";
import { WorkCenter } from "../work-center/WorkCenter";
import { User } from "../user/User";
import { AnnualCalendar } from "../common/calendar/AnnualCalendar";
import { Calendar } from "../calendar/Calendar";

export interface Employee {
    id: number;
    documentType?: string,
    documentNumber?: string,
    firstName: string;
    lastName1: string;
    lastName2?: string;
    birthDate?: Date;
    gender?: string;
    email?: string;
    userId?: number;
    workCenter?: WorkCenter;
    workCenterId?: number;
    status?: boolean;
    user?: User;
    job?: Job;
    jobId?: number;
    isAddedManually?: boolean;
    departmentId?: number;
    timeControlResponsibleId?: number;
    isExemptFromTimeControl: boolean;
    timeBalanceInMinutes: number;
    calendars?: Calendar[];
}

export function DefaultEmployee(): Employee {
    return {
        id: 0,
        documentType: "DNI",
        documentNumber: "",
        firstName: "",
        lastName1: "",
        lastName2: "",
        email: "",
        userId: 0,
        workCenter: undefined,
        workCenterId: 0,
        status: true,
        job: undefined,
        jobId: 1,
        isAddedManually: false,
        departmentId: 0,
        isExemptFromTimeControl: false,
        timeBalanceInMinutes: 0
    };
}

export type EmployeeFullNameSortTypes = 'first-name-first' | 'last-name-first';
export function GetEmployeeFullName(employee: Employee, sort?: EmployeeFullNameSortTypes): string {
    if (sort === "first-name-first") {
        return `${employee.firstName} ${employee.lastName1} ${employee.lastName2}`.trim();
    }
    else if (sort === "last-name-first") {
        return `${employee.lastName1} ${employee.lastName2}`.trim() + `, ${employee.firstName}`;
    } else {
        return `${employee.lastName1} ${employee.lastName2}`.trim() + `, ${employee.firstName}`;
    }
}