import { useState, useEffect } from 'react';
import { Department } from '../../interfaces/company/Department';
import { GetDepartments } from '../../services/company/CompanyService';

const UseDepartments = () => {
    const [data, setData] = useState<Department[]>([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const result = await GetDepartments();
                setData(result);
            } catch (err) {
                console.log(err as Error);
            }
        };

        fetchData();
    }, []);

    return data;
};

export default UseDepartments;