import { useState, useEffect, useContext, useRef } from 'react';
import { useTranslation } from "react-i18next";
import { NotificationContext } from '../../../contexts/NotificationContext';
import { PaperClipIcon } from '@heroicons/react/20/solid'
import { GetWorkShift, GetWorkShiftAttachment, GetWorkTimeSuggestionByWorkCenterIdByEmployeeIdByDate, PatchWorkShift, PostWorkShift } from '../../../services/time-control/TimeControlService';
import { useNavigate, useParams } from 'react-router-dom';
import SimpleHeaderWithTwoButtons from '../../../components/common/header/SimpleHeaderWithTwoButtons';
import { FieldTypes, FormField } from '../../../components/common/form/FormField';
import { NotificationTypes } from '../../../components/common/Notification';
import * as Yup from 'yup';
import Form from '../../../components/common/form/Form';
import { FieldArray, FieldArrayRenderProps, Formik, FormikProps } from 'formik';
import { DefaultWorkShift, WorkShift } from '../../../interfaces/time-control/WorkShift';
import { WorkTime } from '../../../interfaces/time-control/WorkTime';
import { useSpinner } from '../../../contexts/SpinnerContext';
import { format } from 'date-fns';
import React from 'react';
import { ClockIcon, InformationCircleIcon } from '@heroicons/react/24/outline';
import { InformationCircleIcon as InformationCircleIconSolid } from '@heroicons/react/20/solid';
import AlertModal from '../../../components/common/alert/AlertModal';
import { Tooltip } from 'react-tooltip';
import { Tooltip as AntdTooltip } from 'antd';
import { Popover } from 'antd';
import { WorkCenter } from '../../../interfaces/work-center/WorkCenter';
import { Job } from '../../../interfaces/job/Job';
import { GetJobs, GetWorkCenters } from '../../../services/company/CompanyService';
import { UseEmployeeInfo, UseEmployees } from '../../../hooks/employee/UseEmployee';
import { TimeLogTypeCategory, TimeLogType } from '../../../interfaces/time-control/TimeLogType';
import { UseTimeLogTypes, UseWorkShiftDetailTimeLogTypes } from '../../../hooks/time-control/UseTimeControl';
import { TimeOff } from '../../../interfaces/time-control/TimeOff';
import Alert, { AlertType } from '../../../components/common/alert/Alert';
import { UseCompanies } from '../../../hooks/company/UseCompany';
import dayjs from 'dayjs';

export default function TimeControlDetail() {
  const { t } = useTranslation();
  const { showNotification } = useContext(NotificationContext);
  const { setIsLoading } = useSpinner(true);
  const [isNew, setIsNew] = useState<boolean>(false);
  const [dataEditingMode, setDataEditingMode] = useState<boolean>(true);
  const [workShift, setWorkShift] = useState<WorkShift>(DefaultWorkShift());
  const [helperWorkShift, setHelperWorkShift] = useState<WorkShift>(DefaultWorkShift());
  const [workTimeHelpIsOpen, setWorkTimeHelpIsOpen] = useState<boolean>(false);
  const [geolocationDeniedHelpIsOpen, setGeolocationDeniedHelpIsOpen] = useState<boolean>(false);
  const employees = UseEmployees();
  const [validationErrors, setValidationErrors] = useState<string>("");
  const [workCenters, setWorkCenters] = useState<WorkCenter[]>([]);
  const [jobs, setJobs] = useState<Job[]>([]);
  const timeLogTypes = UseWorkShiftDetailTimeLogTypes();
  const companies = UseCompanies();
  const [workTimeSuggestion, setWorkTimeSuggestion] = useState<string>("");
  const [employeeInfo, reloadEmployeeInfo] = UseEmployeeInfo(workShift.employeeId || helperWorkShift.employeeId);

  const navigate = useNavigate();
  const { workCenterId, id } = useParams();

  const fetchWorkCenters = async () => {
    const result = await GetWorkCenters();
    if (result) {
      const sortedWorkCenters = result.sort((a, b) => a.name.localeCompare(b.name));
      setWorkCenters(sortedWorkCenters);
    }
  };

  const fetchJobs = async () => {
    const result = await GetJobs();
    if (result) {
      const sortedJobs = result.sort((a, b) => a.name.localeCompare(b.name));
      setJobs(sortedJobs);
    }
  };

  const workShiftFormRef = useRef<FormikProps<WorkShift>>();

  const handleValidateAndSubmit = async () => {
    if (workShiftFormRef.current) {
      const workShiftValues = await workShiftFormRef.current.submitForm();
      const workShiftErrors = await workShiftFormRef.current.validateForm();

      if (Object.keys(workShiftErrors).length === 0) {
        updateWorkShift(workShiftValues!);
      } else {
        showNotification({ color: NotificationTypes.Danger, messageTitle: t("common.errorDataValidation"), messageText: Object.values(workShiftErrors).map(error => `- ${error}`).join('\n') });
      }
    }
  };

  const updateWorkShift = async (workShift: WorkShift) => {
    setIsLoading(true);
    if (workShift.id === 0) {
      const result = await PostWorkShift(workShift);
      setIsLoading(false);
      if (result) {
        showNotification({ color: NotificationTypes.Success, messageTitle: t("common.updatedOkTitle"), messageText: t("common.updatedOkSubtitle") });
        navigate('/time-control');
      }
    } else {
      const result = await PatchWorkShift(workShift.id, [
        { fieldName: "isValidated", fieldValue: workShift.isValidated },
        { fieldName: "endShift", fieldValue: workShift.endShift },
        { fieldName: "humanResourcesRemarks", fieldValue: workShift.humanResourcesRemarks },
        { fieldName: "workTimes", fieldValue: workShift.workTimes },
        { fieldName: "timeOffs", fieldValue: workShift.timeOffs },
      ]);
      setIsLoading(false);
      if (result) {
        showNotification({ color: NotificationTypes.Success, messageTitle: t("common.updatedOkTitle"), messageText: t("common.updatedOkSubtitle") });
        reloadEmployeeInfo();
      }
    }
  };

  useEffect(() => {
    fetchWorkCenters();
    fetchJobs();
    fetchWorkShift();
  }, []);

  useEffect(() => {
    if (helperWorkShift.workCenterId && helperWorkShift.employeeId && helperWorkShift.startShift) {
      fetchWorkTimeSuggestion();
    }
  }, [helperWorkShift]);

  const fetchWorkTimeSuggestion = async () => {
    const result = await GetWorkTimeSuggestionByWorkCenterIdByEmployeeIdByDate(helperWorkShift.workCenterId, helperWorkShift.employeeId, helperWorkShift.startShift!);
    if (result) {
      setWorkTimeSuggestion(result);
    }
  };

  const fetchWorkShift = async () => {
    if (Number(id) !== 0) {
      await GetWorkShift(Number(id)).then((workShift) => {
        if (workShift) {
          setWorkShift(workShift);
          setHelperWorkShift(workShift);
          setDataEditingMode(workShift.isEditable);
        }
      })
    } else {
      setIsNew(true);
      setDataEditingMode(true);
    }
    setIsLoading(false);
  };

  const workShiftValidationSchema = Yup.object({
    employeeId: Yup.string().required('Debe seleccionar un empleado').notOneOf(['0'], 'Debe seleccionar un empleado'),
    workCenterId: Yup.string().required('Debe seleccionar un centro de trabajo').notOneOf(['0'], 'Debe seleccionar un centro de trabajo'),
  });

  const handleDownloadWorkShiftAttachment = async (workShiftId: number, attachmentId: number, fileName: string) => {
    setIsLoading(true);
    await GetWorkShiftAttachment(workShiftId, attachmentId, fileName);
    setIsLoading(false);
  };

  const emptyWorkTime = {
    timeLogTypeId: 1,
    startTime: new Date().toISOString(),
    endTime: null,
    userDenyStartLocation: false,
    userDenyEndLocation: false,
  };

  const emptyTimeOff = {
    timeLogTypeId: 0,
    minutes: 0,
  };

  return (
    <div className="px-2 sm:px-4">
      {dataEditingMode ? (
        <SimpleHeaderWithTwoButtons
          title={t("timeControl.title")}
          textSize="text-3xl"
          buttonText1={t("common.goBack")}
          buttonText2={t("common.save")}
          button1Click={() => Number(workCenterId) === 0 && workShift.id === 0 ? navigate(`/time-control`) : navigate(`/time-control/employee/${workCenterId}/${workShift.employeeId}`)}
          button2Click={handleValidateAndSubmit}
        />
      ) : (
        <>
          <SimpleHeaderWithTwoButtons
            title={t("timeControl.title")}
            textSize="text-3xl"
            buttonText1={t("common.goBack")}
            button1Click={() => Number(workCenterId) === 0 && workShift.id === 0 ? navigate(`/time-control`) : navigate(`/time-control/employee/${workCenterId}/${workShift.employeeId}`)}
          />
          <Alert type={AlertType.Success} text={t("timeControl.closeAndValidatedMonthAlert")} />
        </>
      )}
      <div className="mt-4">
        <AlertModal title={t("common.errorDataValidation")} text={validationErrors} type="warning" isOpen={validationErrors !== ""} onClose={() => setValidationErrors("")} />
        <Form initialValues={workShift} validationSchema={workShiftValidationSchema} ref={workShiftFormRef}>
          <div className="px-4 sm:px-0">
            {isNew ? (
              <dl className="divide-y divide-gray-100">
                <div className="px-4 pt-2 pb-3 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-0">
                  <dd className="text-sm leading-6 text-gray-700 sm:col-span-1 sm:mt-0">
                    <FormField
                      colspan={1}
                      label={t("timeControl.employeeFullName")}
                      name="employeeId"
                      customType={FieldTypes.SelectWithFinder}
                      options={[
                        { value: "", label: t("common.select") }
                      ].concat(
                        employees.map(employee => ({
                          value: employee.id.toString(),
                          label: `${employee.lastName1} ${employee.lastName2}, ${employee.firstName}`
                        }))
                      )}
                      onChange={(selectedValue) => {
                        setHelperWorkShift(prevWorkShift => ({
                          ...prevWorkShift,
                          employeeId: selectedValue
                        }));
                      }}
                    />
                  </dd>
                </div>
              </dl>
            ) : (
              <div className="mb-6">
                <h3 className="text-base font-semibold leading-7 text-gray-900">{workShift.employee && `${workShift.employee?.firstName} ${workShift.employee?.lastName1} ${workShift.employee?.lastName2}` || t("common.loading")}</h3>
                <p className="mt-1.5 max-w-2xl text-sm leading-6 text-gray-500">{t("timeControl.currentWorkCenter")}: {workShift.employee?.workCenter?.name ?? t("employee.statusNotActive")}</p>
                <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">{t("timeControl.currentJob")}: {workShift.employee?.job?.name ?? t("employee.statusNotActive")}</p>
              </div>
            )}
          </div>

          <div className="mt-5 border-t border-gray-100">

            {workTimeSuggestion && workTimeSuggestion !== "" &&
              <div className="p-3 mb-3 border-l-4 border-blue-400 bg-blue-50 text-blue-800">
                <div className="flex">
                  <div className="flex-shrink-0">
                    <InformationCircleIconSolid className="h-5 w-5" aria-hidden="true" />
                  </div>
                  <div className="ml-3">
                    <p className="text-sm font-medium italic text-justify">{workTimeSuggestion}</p>
                  </div>
                </div>
              </div>
            }

            <dl className="divide-y divide-gray-100">
              {!isNew ? (
                <div className="px-4 py-6 sm:grid sm:grid-cols-5 sm:gap-4 sm:px-0">
                  <dt className="text-sm font-medium leading-6 text-gray-900">{t("common.day")}</dt>
                  <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-4 sm:mt-0">
                    {workShift.startShift ? format(new Date(workShift.startShift), 'dd/MM/yyyy') : "-"}
                  </dd>
                </div>
              ) : (
                <div className="px-4 py-6 sm:grid sm:grid-cols-5 sm:gap-4 sm:px-0">
                  <dt className="text-sm font-medium leading-6 text-gray-900">{t("common.day")}</dt>
                  <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-1 sm:mt-0">
                    <FormField
                      name="startShift"
                      customType={FieldTypes.DatePicker}
                      onChange={(selectedValue: Date) => {
                        setHelperWorkShift(prevWorkShift => ({
                          ...prevWorkShift,
                          startShift: selectedValue
                        }));
                      }}
                    />
                  </dd>
                </div>
              )}

              {!isNew ? (
                <div className="px-4 py-6 sm:grid sm:grid-cols-5 sm:gap-4 sm:px-0">
                  <dt
                    className="text-sm font-medium leading-6 text-gray-900"
                    data-tooltip-id="workShiftWorkCenterInfo"
                    data-tooltip-content={t("timeControl.workShiftWorkCenterInfo")}
                    data-tooltip-place="right"
                  >
                    <Tooltip id="workShiftWorkCenterInfo" />
                    {t("timeControl.workShiftWorkCenter")}
                  </dt>
                  <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-4 sm:mt-0">
                    {workCenters && workCenters.find(workCenter => String(workCenter.id) === String(workShift.workCenterId))?.name || "-"}
                  </dd>
                </div>
              ) : (
                <div className="px-4 py-6 sm:grid sm:grid-cols-5 sm:gap-4 sm:px-0">
                  <dt className="text-sm font-medium leading-6 text-gray-900">{`${t("employee.company")} | ${t("employee.workCenter")}`}</dt>
                  <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-3 sm:mt-0">
                    <FormField
                      name="workCenterId"
                      customType={FieldTypes.SelectWithFinder}
                      options={[{ value: "", label: t("common.select") }]
                        .concat(
                          companies
                            .flatMap(company =>
                              company.workCenters!.map(workCenter => ({
                                value: workCenter.id.toString(),
                                label: `${company.code} ${company.name} | ${workCenter.code} ${workCenter.name}`,
                                companyCode: +company.code!,
                                workCenterCode: +workCenter.code!
                              }))
                            )
                            .sort((a, b) => {
                              if (a.companyCode !== b.companyCode) {
                                return a.companyCode - b.companyCode;
                              }
                              return a.workCenterCode - b.workCenterCode;
                            })
                            .map(option => ({
                              value: option.value,
                              label: option.label
                            }))
                        )
                      }
                      onChange={(selectedValue) => {
                        setHelperWorkShift(prevWorkShift => ({
                          ...prevWorkShift,
                          workCenterId: selectedValue
                        }));
                      }}
                    />
                  </dd>
                </div>
              )}

              <div className="px-4 py-6 sm:grid sm:grid-cols-5 sm:gap-4 sm:px-0">
                <dt className="text-sm font-medium leading-6 text-gray-900">{t("timeControl.isValidated")}</dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-1 sm:mt-0">
                  {dataEditingMode ? (
                    <FormField colspan={1} name="isValidated" customType={FieldTypes.Select}
                      options={[
                        { value: "true", label: t("common.yes") },
                        { value: "false", label: t("common.no") },
                      ]}
                    />
                  ) : (
                    <div className="flex items-center justify-end gap-x-2 sm:justify-start">
                      <div className={`${workShift.isValidated ? "text-green-400 bg-green-400/10" : "text-rose-400 bg-rose-400/10"} flex-none rounded-full p-1`}>
                        <div className="h-1.5 w-1.5 rounded-full bg-current" />
                      </div>
                      <div className="hidden sm:block">{workShift.isValidated ? t("common.yes") : t("common.no")}</div>
                    </div>
                  )}
                </dd>
              </div>

              {!isNew &&
                <div className="px-4 py-6 sm:grid sm:grid-cols-5 sm:gap-4 sm:px-0">
                  <dt
                    className="text-sm font-medium leading-6 text-gray-900"
                    data-tooltip-id="workShiftJobInfo"
                    data-tooltip-content={t("timeControl.workShiftJobInfo")}
                    data-tooltip-place="right"
                  >
                    <Tooltip id="workShiftJobInfo" />
                    {t("timeControl.workShiftJob")}
                  </dt>
                  <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-4 sm:mt-0">
                    {jobs && jobs.find(job => String(job.id) === String(workShift.jobId))?.name || "-"}
                  </dd>
                </div>
              }

              {!isNew &&
                <div className="px-4 py-6 sm:grid sm:grid-cols-5 sm:gap-4 sm:px-0">
                  <dt className="text-sm font-medium leading-6 text-gray-900">{t("timeControl.employeeRemarks")}</dt>
                  <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-4 sm:mt-0">
                    {workShift.employeeRemarks
                      ? workShift.employeeRemarks.split('\n').map((line, index, array) => (
                        <React.Fragment key={index}>
                          {line}
                          {index === array.length - 1 ? null : <br />}
                        </React.Fragment>
                      ))
                      : '-'}
                  </dd>
                </div>
              }

              <div className="px-4 py-6 sm:grid sm:grid-cols-5 sm:gap-4 sm:px-0">
                <dt className="text-sm font-medium leading-6 text-gray-900">
                  {t("timeControl.humanResourcesRemarks")}
                  <p className="mt-3 text-sm italic leading-6 text-gray-600">{t("timeControl.humanResourcesRemarksSubtitle")}</p>
                </dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-4 sm:mt-0">
                  {dataEditingMode ? (
                    <FormField colspan={1} name="humanResourcesRemarks" customType={FieldTypes.Textarea} placeholder={t("timeControl.humanResourcesRemarksPlaceholder").toString()} />
                  ) : (
                    workShift.humanResourcesRemarks
                      ? workShift.humanResourcesRemarks.split('\n').map((line, index, array) => (
                        <React.Fragment key={index}>
                          {line}
                          {index === array.length - 1 ? null : <br />}
                        </React.Fragment>
                      ))
                      : '-'
                  )}
                </dd>
              </div>

              {!isNew &&
                <div className="px-4 py-6 sm:grid sm:grid-cols-5 sm:gap-4 sm:px-0">
                  <dt className="text-sm font-medium leading-6 text-gray-900">{t("timeControl.systemRemarks")}</dt>
                  <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-4 sm:mt-0">
                    {workShift.systemRemarks
                      ? workShift.systemRemarks.split('\n').map((line, index, array) => (
                        <React.Fragment key={index}>
                          {line}
                          {index === array.length - 1 ? null : <br />}
                        </React.Fragment>
                      ))
                      : '-'}
                  </dd>
                </div>
              }

              {!isNew &&
                <div className="px-4 py-6 sm:grid sm:grid-cols-5 sm:gap-4 sm:px-0">
                  <dt className="text-sm font-medium leading-6 text-gray-900">{t("timeControl.attachments")}</dt>
                  <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                    {
                      workShift.attachments && workShift.attachments.length > 0 ? (
                        <ul role="list" className="divide-y divide-gray-100 rounded-md border border-gray-200">
                          {workShift.attachments.map((attachment, index) => (
                            <li className="flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6" key={index}>
                              <div className="flex w-0 flex-1 items-center">
                                <PaperClipIcon className="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                                <div className="ml-4 flex min-w-0 flex-1 gap-2">
                                  <span className="truncate font-medium">{attachment.fileName}</span>
                                </div>
                              </div>
                              <div className="ml-4 flex-shrink-0">
                                <a href="#" className="font-medium text-indigo-600 hover:text-indigo-500" onClick={(e) => { e.preventDefault(); handleDownloadWorkShiftAttachment(workShift.id, attachment.id, attachment.fileName); }}>
                                  {t("common.download")}
                                </a>
                              </div>
                            </li>
                          ))}
                        </ul>
                      ) : (
                        <i>{t("timeControl.noAttachments")}</i>
                      )
                    }
                  </dd>
                </div>
              }
            </dl>
          </div>
          <div className="px-4 py-6 sm:grid sm:grid-cols-5 sm:gap-4 sm:px-0">
            <dt className="flex items-start text-sm font-medium leading-6 text-gray-900">
              <span className="mr-2">{t("timeControl.workTimesTitle")}</span>
              <button
                data-tooltip-id="workTimeHelp"
                data-tooltip-content={t("common.information")}
                data-tooltip-place="top"
                className="rounded bg-indigo-50 px-2 py-1 text-sm font-semibold text-indigo-600 shadow-sm hover:bg-indigo-100"
                onClick={() => setWorkTimeHelpIsOpen(true)}
              >
                <InformationCircleIcon className="h-5 w-5" aria-hidden="true" />
                <Tooltip id="workTimeHelp" />
                <AlertModal title={t("common.help")} text={t("timeControl.workTimesSubtitle")} type="info" isOpen={workTimeHelpIsOpen} onClose={() => setWorkTimeHelpIsOpen(false)} />
              </button>
            </dt>
            <dd className="mt-0 text-sm leading-6 text-gray-700 sm:col-span-4 sm:mt-0">
              <div className="-mx-4 mt-8 sm:-mx-0">
                <table className={`${dataEditingMode ? "w-4/4" : 'w-1/4'} divide-y divide-gray-300 min-w-max`}>
                  <thead>
                    <tr>
                      <th className="px-8 py-3.5 text-left text-sm font-semibold text-gray-900">{t("timeControl.workTimeStartTime")}</th>
                      <th className="px-8 py-3.5 text-left text-sm font-semibold text-gray-900">{t("timeControl.workTimeEndTime")}</th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {dataEditingMode ? (
                      <FieldArray
                        name="workTimes"
                        render={(arrayHelpers: FieldArrayRenderProps) => {
                          const { workTimes: workTimes } = arrayHelpers.form.values;
                          return (
                            <>
                              {workTimes && workTimes.length > 0 && (
                                workTimes.map((workTime: WorkTime, index: number) => (
                                  <tr key={index}>
                                    <td className="px-8 py-3.5 text-left text-sm font-normal text-gray-900">
                                      <FormField name={`workTimes.${index}.startTime`} customType={FieldTypes.DateTimePicker} />
                                    </td>
                                    <td className="px-8 py-3.5 text-left text-sm font-normal text-gray-900">
                                      <FormField name={`workTimes.${index}.endTime`} customType={FieldTypes.DateTimePicker} />
                                    </td>
                                    <td className="px-8 py-3.5 text-left text-sm font-normal text-gray-900">
                                      <button
                                        type="button"
                                        className="rounded bg-red-50 px-2 py-1 text-sm font-semibold text-red-600 shadow-sm hover:bg-red-100"
                                        onClick={() => arrayHelpers.remove(index)}
                                      >
                                        {t("timeControl.deleteWorkTime")}
                                      </button>
                                    </td>
                                  </tr>
                                ))
                              )}
                              <tr>
                                <td className="py-3.5 text-left text-sm font-normal text-gray-900">
                                  <button
                                    type="button"
                                    className="rounded bg-indigo-50 px-2 py-1 text-sm font-semibold text-indigo-600 shadow-sm hover:bg-indigo-100"
                                    onClick={() => arrayHelpers.push(emptyWorkTime)}
                                  >
                                    {t("timeControl.addWorkTime")}
                                  </button>
                                </td>
                              </tr>
                            </>
                          );
                        }}
                      />
                    ) : (
                      workShift?.workTimes && workShift?.workTimes.length > 0 ? (
                        workShift?.workTimes?.map((workTime, index) => (
                          <tr key={index}>
                            <td className="px-8 py-3.5 text-left text-sm font-normal text-gray-900">
                              <div className="flex items-center">
                                {format(new Date(workTime.startTime), 'dd/MM/yyyy HH:mm:ss')}
                                {workTime.userDenyStartLocation ? (
                                  <button
                                    type="button"
                                    className="ml-8 rounded bg-red-50 px-2 py-1 text-sm font-semibold text-red-600 shadow-sm hover:bg-red-100"
                                    onClick={() => setGeolocationDeniedHelpIsOpen(true)}
                                  >
                                    {t("timeControl.geolocationDeniedByUser")}
                                  </button>
                                ) : !workTime.userDenyStartLocation && workTime.startLatitude && workTime.startLongitude ? (
                                  <a
                                    href={`https://www.google.com/maps?q=${workTime.startLatitude},${workTime.startLongitude}`}
                                    target="_blank"
                                    type="button"
                                    className="ml-8 rounded bg-indigo-50 px-2 py-1 text-sm font-semibold text-indigo-600 shadow-sm hover:bg-indigo-100"
                                  >
                                    {t("timeControl.geolocationView")}
                                  </a>
                                ) : (
                                  <></>
                                )}
                              </div>
                            </td>
                            <td className="px-8 py-3.5 text-left text-sm font-normal text-gray-900">
                              {workTime.endTime ? (
                                <div className="flex items-center">
                                  {format(new Date(workTime.endTime), 'dd/MM/yyyy HH:mm:ss')}
                                  {workTime.userDenyEndLocation ? (
                                    <button
                                      type="button"
                                      className="ml-8 rounded bg-red-50 px-2 py-1 text-sm font-semibold text-red-600 shadow-sm hover:bg-red-100"
                                      onClick={() => setGeolocationDeniedHelpIsOpen(true)}
                                    >
                                      {t("timeControl.geolocationDeniedByUser")}
                                    </button>
                                  ) : !workTime.userDenyEndLocation && workTime.endLatitude && workTime.endLongitude ? (
                                    <a
                                      href={`https://www.google.com/maps?q=${workTime.endLatitude},${workTime.endLongitude}`}
                                      target="_blank"
                                      type="button"
                                      className="ml-8 rounded bg-indigo-50 px-2 py-1 text-sm font-semibold text-indigo-600 shadow-sm hover:bg-indigo-100"
                                    >
                                      {t("timeControl.geolocationView")}
                                    </a>
                                  ) : (
                                    <></>
                                  )}
                                </div>
                              ) : ""}
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan={2} className="px-6 py-4 text-center italic">{t("timeControl.noWorkTimes")}</td>
                        </tr>
                      )
                    )}
                  </tbody>
                </table>
                <AlertModal title={t("timeControl.geolocationDeniedByUser")} text={t("timeControl.geolocationDeniedByUserSubtitle")} type="error" isOpen={geolocationDeniedHelpIsOpen} onClose={() => setGeolocationDeniedHelpIsOpen(false)} />
              </div>
            </dd>
          </div>

          <div className="px-4 py-6 sm:grid sm:grid-cols-5 sm:gap-4 sm:px-0">
            <dt className="flex items-start text-sm font-medium leading-6 text-gray-900">
              <span className="mr-2">{t("timeControl.allocateTimeFor")}</span>
              {employeeInfo && employeeInfo.timeBalance &&
                <Popover
                  placement="right"
                  title={
                    <div className="flex justify-start items-center mt-1">
                      <AntdTooltip placement="topLeft" title={t("timeControl.currentTimeBalanceHelp")} color="geekblue">
                        <div className="flex justify-start items-center">
                          <InformationCircleIcon className="h-8 w-8 text-indigo-500" />
                          <div className="ml-2.5 font-semibold text-base">
                            {t("employee.timeBalance")}
                          </div>
                        </div>
                      </AntdTooltip>
                    </div>
                  }
                  content={
                    <div className="flex justify-start items-center mt-1 mb-0.5">
                      <p className="mt-1.5 mb-4 mx-2 text-sm leading-5 text-gray-600 font-medium">
                        {t("employee.currentTimeBalance", { timeBalance: employeeInfo.timeBalance })}
                      </p>
                    </div>
                  }
                >
                  <button className="rounded bg-orange-50 px-2 py-1 text-sm font-semibold text-orange-600 shadow-sm cursor-default">
                    <ClockIcon className="h-5 w-5" aria-hidden="true" />
                  </button>
                </Popover>
              }
            </dt>
            <dd className="mt-0 text-sm leading-6 text-gray-700 sm:col-span-4 sm:mt-0">
              <div className="-mx-4 mt-0 sm:-mx-0">
                <table className={`${dataEditingMode ? "w-4/4" : 'w-1/2'} divide-y divide-gray-300 min-w-max`}>
                  <thead>
                    <tr>
                      <th className="w-1/3 px-8 py-3.5 text-left text-sm font-semibold text-gray-900">{t("timeControl.eventDescription")}</th>
                      <th className="w-1/5 py-3.5 text-center text-sm font-semibold text-gray-900">{t("timeControl.eventDescriptionComputedTime")}</th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {dataEditingMode ? (
                      <FieldArray
                        name="timeOffs"
                        render={(arrayHelpers: FieldArrayRenderProps) => {
                          const { timeOffs } = arrayHelpers.form.values;
                          return (
                            <>
                              {timeOffs && timeOffs.length > 0 && (
                                timeOffs.map((timeOff: TimeOff, index: number) => (
                                  <tr key={index}>
                                    <td className="px-8 py-3.5 text-left text-sm font-normal text-gray-900">
                                      <FormField
                                        name={`timeOffs.${index}.timeLogTypeId`}
                                        customType={FieldTypes.Select}
                                        options={[{ value: "0", label: t("common.select") }].concat(timeLogTypes.map((timeLogType: TimeLogType) => ({ value: timeLogType.id.toString(), label: timeLogType.description })))}
                                      />
                                    </td>
                                    <td className="px-8 py-3.5 text-sm font-normal text-gray-900">
                                      <FormField
                                        name={`timeOffs.${index}.minutes`}
                                        customType={FieldTypes.TimePicker}
                                      />
                                    </td>
                                    <td className="px-8 py-3.5 text-left text-sm font-normal text-gray-900">
                                      <button
                                        type="button"
                                        className="rounded bg-red-50 px-2 py-1 text-sm font-semibold text-red-600 shadow-sm hover:bg-red-100"
                                        onClick={() => arrayHelpers.remove(index)}
                                      >
                                        {t("common.delete")}
                                      </button>
                                    </td>
                                  </tr>
                                ))
                              )}
                              <tr>
                                <td className="py-3.5 text-left text-sm font-normal text-gray-900">
                                  <button
                                    type="button"
                                    className="rounded bg-indigo-50 px-2 py-1 text-sm font-semibold text-indigo-600 shadow-sm hover:bg-indigo-100"
                                    onClick={() => arrayHelpers.push(emptyTimeOff)}
                                  >
                                    {t("common.add")}
                                  </button>
                                </td>
                              </tr>
                            </>
                          );
                        }}
                      />
                    ) : (
                      workShift?.timeOffs && workShift?.timeOffs.length > 0 ? (
                        workShift?.timeOffs?.map((timeOff, index) => (
                          <tr key={index}>
                            <td className="px-8 py-3.5 text-left text-sm font-normal text-gray-900">
                              {timeOff.timeLogType?.description}
                            </td>
                            <td className="px-8 py-3.5 text-center text-sm font-normal text-gray-900" style={{ textAlign: 'center' }}>
                              {timeOff.minutesInHHMMFormat}
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan={2} className="px-6 py-4 text-center italic">{t("timeControl.noTimeOffs")}</td>
                        </tr>
                      )
                    )}
                  </tbody>
                </table>
              </div>
            </dd>
          </div>
        </Form>
      </div>
    </div>
  )
}
