import { useState, useRef } from 'react';
import { Button, Input, InputRef, TableColumnType, Checkbox } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';

type CheckboxFilterItem = {
    text: string;
    value: string | number;
    defaultChecked?: boolean;
};

export const useColumnFilterProps = <RecordType extends object>(
    dataIndex: keyof RecordType,
    filterType: 'text' | 'checkbox' = 'text',
    checkboxItems?: CheckboxFilterItem[],
    placeholder?: string,
): TableColumnType<RecordType> => {
    const { t } = useTranslation();
    const [searchText, setSearchText] = useState<string>('');
    const [checkedList, setCheckedList] = useState<Array<string | number>>(
        checkboxItems?.filter(item => item.defaultChecked).map(item => item.value) || []
    );
    const searchInput = useRef<InputRef>(null);

    const handleSearch = (selectedKeys: string[], confirm: () => void) => {
        confirm();
        setSearchText(selectedKeys[0]);
    };

    const handleReset = (clearFilters: () => void, confirm: () => void) => {
        clearFilters();
        setSearchText('');
        setCheckedList(checkboxItems?.filter(item => item.defaultChecked).map(item => item.value) || []);
        confirm();
    };

    const onCheckboxChange = (selectedKeys: Array<CheckboxValueType>, setSelectedKeys: (keys: string[]) => void, confirm: () => void) => {
        const filteredKeys = selectedKeys.filter(key => typeof key === 'string' || typeof key === 'number');
        setCheckedList(filteredKeys as Array<string | number>);
        setSelectedKeys(filteredKeys.map(String));
        confirm();
    };

    return {
        defaultFilteredValue: checkedList || [],
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            filterType === 'text' ? (
                <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
                    <Input
                        ref={searchInput}
                        className="block w-full rounded-md border-0 py-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        placeholder={placeholder}
                        value={selectedKeys[0]}
                        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                        onPressEnter={() => handleSearch(selectedKeys as string[], confirm)}
                        style={{ marginBottom: 8, display: 'block', width: '100%' }}
                    />
                    <div className="flex flex-row items-center justify-center">
                        <Button
                            className="inline-flex w-1/2 items-center justify-center"
                            onClick={() => clearFilters && handleReset(clearFilters, confirm)}
                            size="small"
                            style={{ flex: 1, marginRight: '8px' }}
                        >
                            {t("common.reset")}
                        </Button>
                        <Button
                            type="primary"
                            className="inline-flex w-1/2 items-center justify-center"
                            onClick={() => handleSearch(selectedKeys as string[], confirm)}
                            icon={<SearchOutlined />}
                            size="small"
                            style={{ flex: 1 }}
                        >
                            {t("common.search")}
                        </Button>
                    </div>
                </div>
            ) : (
                <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
                    <Checkbox.Group
                        className="mb-3 space-y-1"
                        options={checkboxItems?.map(item => ({ label: item.text, value: item.value }))}
                        value={checkedList}
                        onChange={(selectedKeys: Array<CheckboxValueType>) => {
                            onCheckboxChange(selectedKeys, setSelectedKeys, confirm);
                        }}
                        style={{ display: 'flex', flexDirection: 'column' }}
                    />
                </div>
            )
        ),
        onFilter: (_, record) => {
            if (filterType === 'text') {
                console.log('text:', record[dataIndex]);
                return record[dataIndex]?.toString().toLowerCase().includes(searchText.toLowerCase()) || false;
            } else if (filterType === 'checkbox') {
                return checkedList.includes(Number(record[dataIndex])) || false;
            }
            return false;
        },
        onFilterDropdownOpenChange: visible => {
            if (visible && filterType === 'text') {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
    };
};