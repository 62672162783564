import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Descriptions, DescriptionsProps } from 'antd';
import { UseWorkCenter } from '../../../hooks/company/UseCompany';
import { Company } from '../../../interfaces/company/Company';
import { GetCompany } from '../../../services/company/CompanyService';

interface TimeControlSummaryWorkCenterInfoProps {
    workCenterId: number;
    incidencesCount: number;
}

const TimeControlSummaryWorkCenterInfo: React.FC<TimeControlSummaryWorkCenterInfoProps> = ({ workCenterId, incidencesCount }) => {
    const { t } = useTranslation();
    const workCenter = UseWorkCenter(workCenterId);

    const items: DescriptionsProps['items'] = [
        {
            key: '1',
            label: t("company.name"),
            children: workCenter?.company?.name ?? "-",
            span: 2
        },
        {
            key: '2',
            label: t("workCenter.responsibleEmployee"),
            children: workCenter?.responsibleEmployee ? `${workCenter?.responsibleEmployee?.lastName1} ${workCenter?.responsibleEmployee?.lastName2}, ${workCenter?.responsibleEmployee?.firstName}` : t("common.noAssigned"),
            span: 1
        },
        {
            key: '3',
            label: t("timeControl.incidencesCount"),
            children: incidencesCount ?? "-",
            span: 1
        },
    ];

    return (
        <>
            {workCenter &&
                <>
                    <h3 className="mb-3 text-lg font-semibold text-gray-700">{workCenter?.name}</h3>
                    <Descriptions column={4} title="" items={items} size="small" layout="vertical" colon={false} />
                </>
            }
        </>
    );
};

export default TimeControlSummaryWorkCenterInfo;