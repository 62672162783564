import { useState, useCallback } from 'react';
import { useTranslation } from "react-i18next";
import { BarsArrowUpIcon, BarsArrowDownIcon, UsersIcon } from '@heroicons/react/20/solid'
import SectionHeader from "../../../components/common/header/SimpleHeader";
import Tabs from '../../../components/common/navigation/Tabs';
import { IDropdownBlock } from '../../../components/common/dropdown/Dropdown';
import TableDynamic, { FilterConfigArray } from '../../../components/common/list/TableDynamic';
import { useNavigate } from 'react-router-dom';
import { GetWorkShiftsExportExcel, GetWorkShiftsGroupByWorkCenter, GetWorkShiftsGroupByWorkCenterCount, GetSupervisedEmployeesWorkShiftsCount, GetSupervisedEmployeesWorkShifts, GetTimeOffsReport, GetIncidencesReport, GetEmployeesCertificatesReport } from '../../../services/time-control/TimeControlService';
import { ExportTypes } from '../../../interfaces/export/Export';
import { useSpinner } from '../../../contexts/SpinnerContext';
import AlertModal from '../../../components/common/alert/AlertModal';
import { SortCriteria } from '../../../components/common/sort/SortDynamic';
import { usePageState } from '../../../contexts/PageStateContext';
import Modal from '../../../components/common/overlay/Modal';
import EmployeeAdd from '../rrhh/EmployeeAdd';
import { UseEmployeeIsTimeControlSupervisor, UseWorkCentersIncidencesStatistics, UseWorkShiftDistributionStatisticsByYearByMonth } from '../../../hooks/time-control/UseTimeControl';
import PieChart from '../../../components/common/statistics/PieChart';
import { Alert, Avatar, Carousel, Collapse, Divider, List, Tooltip } from 'antd';
import BarChart from '../../../components/common/statistics/BarChart';
import ModalAdvanced from '../../../components/common/overlay/ModalAdvanced';
import { getImagePath } from '../../../resources/utils/common';
import { InformationCircleIcon } from '@heroicons/react/24/solid';

export default function TimeControlList() {
  const pageName = 'TimeControl';
  const { t } = useTranslation();
  const { setIsLoading } = useSpinner();
  const { state, dispatch } = usePageState();
  const [activeTab, setActiveTab] = useState<string>(state[pageName]?.activeTab || "workshiftsMainView");
  const [workShiftFilter, setWorkShiftFilter] = useState<Record<string, any> | undefined>(state[pageName]?.workShiftFilter || {});
  const [workShiftSort, setWorkShiftSort] = useState<Record<string, any> | undefined>(state[pageName]?.workShiftSort || undefined);
  const [supervisedEmployeesWorkShiftFilter, setSupervisedEmployeesWorkShiftFilter] = useState<Record<string, any> | undefined>(state[pageName]?.supervisedEmployeesWorkShiftFilter || {});
  const [supervisedEmployeesWorkShiftSort, setSupervisedEmployeesWorkShiftSort] = useState<Record<string, any> | undefined>(state[pageName]?.supervisedEmployeesWorkShiftSort || undefined);
  const [modalEmployeeAddResult, setModalEmployeeAddResult] = useState<string>("");
  const [validationErrors, setValidationErrors] = useState<string>("");
  const [noDataErrors, setNoDataErrors] = useState<string>("");
  const [modalEmployeeAddIsOpen, setModalEmployeeAddIsOpen] = useState<boolean>(false);

  const [employeeIsTimeControlSupervisor] = UseEmployeeIsTimeControlSupervisor();
  const workCentersIncidencesStatistics = UseWorkCentersIncidencesStatistics();
  const workCentersDistributionStatistics = UseWorkShiftDistributionStatisticsByYearByMonth(new Date().getFullYear(), new Date().getMonth() + 1);

  const [reportingModalIsVisible, setReportingModalIsVisible] = useState<boolean>(false);

  const navigate = useNavigate();

  const tabs = [
    { key: "workshiftsMainView", name: t("timeControl.workshiftsMainView"), icon: UsersIcon, current: true },
  ];

  const dropdownWorkTimesOptions: IDropdownBlock[] = [
    {
      items: [
        { label: t("timeControl.workTimeStartTimeAscendingSort"), icon: BarsArrowUpIcon, action: () => console.log(true) },
        { label: t("timeControl.workTimeStartTimeDescendingSort"), icon: BarsArrowDownIcon, action: () => console.log(false) },
      ]
    },
  ];

  const updateParent = () => {
    setIsLoading(true);
  };

  const changeTab = (tabName: string) => {
    setActiveTab(tabName);
    dispatch({ type: 'SET_PAGE_STATE', payload: { pageKey: pageName, state: { ...state[pageName], activeTab: tabName } } });
  };

  const updateParentFromEmployeeAdd = () => {
    setModalEmployeeAddResult(t("employee.addEmployeeSuccessInfo").toString());

  };

  const closeEmployeeAddModal = () => {
    setModalEmployeeAddIsOpen(false)
  };

  const isLoadingChildren = (state: boolean) => {
    setIsLoading(state)
  };

  const filterConfig: FilterConfigArray = [
    { columnName: "companyCode", type: "text" },
    { columnName: "companyName", type: "text" },
    { columnName: "workCenterCode", type: "text" },
    { columnName: "workCenterName", type: "text" },
    {
      columnName: "hasIncidences",
      type: "select",
      options: [
        { value: "", label: '--- Todos ---' },
        { value: "true", label: t("timeControl.withIncidences") },
      ]
    },
  ];

  const sortConfig = [
    { columnName: "companyCode", key: 'companyCode' },
    { columnName: "companyName", key: 'companyName' },
    { columnName: "workCenterCode", key: 'workCenterCode' },
    { columnName: "workCenterName", key: 'workCenterName' },
  ];

  const fetchWorkShiftData = useCallback(async (page: number, itemsPerPage: number) => {
    if (!workShiftFilter) {
      return;
    }
    if (workShiftSort) {
      workShiftFilter.SortCriteria = workShiftSort.map((sort: SortCriteria) => ({
        Field: sort.field,
        Order: sort.order,
      }));
    }
    return GetWorkShiftsGroupByWorkCenter(page, itemsPerPage, workShiftFilter);
  }, [workShiftFilter, workShiftSort]);

  const handleFilterUpdate = (filter: Record<string, any> | undefined) => {
    setWorkShiftFilter(filter);
    dispatch({ type: 'SET_PAGE_STATE', payload: { pageKey: pageName, state: { ...state[pageName], workShiftFilter: filter } } });
  };

  const handleSortUpdate = (sort: Record<string, any> | undefined) => {
    setWorkShiftSort(sort);
    dispatch({ type: 'SET_PAGE_STATE', payload: { pageKey: pageName, state: { ...state[pageName], workShiftSort: sort } } });
  };

  const supervisedEmployeesWorkShiftFilterConfig: FilterConfigArray = [
    { columnName: "employeeFullName", type: "text" },
    {
      columnName: "hasIncidences",
      type: "select",
      options: [
        { value: "", label: '--- Todos ---' },
        { value: "true", label: t("timeControl.withIncidences") },
      ]
    },
  ];

  const supervisedEmployeesWorkShiftSortConfig = [
    { columnName: "employeeFullName", key: 'employeeFullName' },
  ];

  const fetchSupervisedEmployeesWorkShiftData = useCallback(async (page: number, itemsPerPage: number) => {
    if (!supervisedEmployeesWorkShiftFilter) {
      return;
    }
    if (supervisedEmployeesWorkShiftSort) {
      supervisedEmployeesWorkShiftFilter.SortCriteria = supervisedEmployeesWorkShiftSort.map((sort: SortCriteria) => ({
        Field: sort.field,
        Order: sort.order,
      }));
    }
    return GetSupervisedEmployeesWorkShifts(page, itemsPerPage, supervisedEmployeesWorkShiftFilter);
  }, [supervisedEmployeesWorkShiftFilter, supervisedEmployeesWorkShiftSort]);

  const handleSupervisedEmployeesFilterUpdate = (filter: Record<string, any> | undefined) => {
    setSupervisedEmployeesWorkShiftFilter(filter);
    dispatch({ type: 'SET_PAGE_STATE', payload: { pageKey: pageName, state: { ...state[pageName], supervisedEmployeesWorkShiftFilter: filter } } });
  };

  const handleSupervisedEmployeesSortUpdate = (sort: Record<string, any> | undefined) => {
    setSupervisedEmployeesWorkShiftSort(sort);
    dispatch({ type: 'SET_PAGE_STATE', payload: { pageKey: pageName, state: { ...state[pageName], supervisedEmployeesWorkShiftSort: sort } } });
  };

  const carouselStyle: React.CSSProperties = {
    height: 'auto',
    textAlign: 'center',
    background: 'transparent',
  };

  return (
    <div className="px-2 sm:px-4">
      <AlertModal title={t("common.errorDataValidation")} text={validationErrors} type="warning" isOpen={validationErrors !== ""} onClose={() => setValidationErrors("")} />
      <AlertModal title={t("common.noData")} text={noDataErrors} type="info" isOpen={noDataErrors !== ""} onClose={() => setNoDataErrors("")} />
      <SectionHeader title={t("timeControl.title")} textSize="text-3xl" />
      <div className="mt-4">
        <Tabs setTab={changeTab} activeTab={activeTab} tabs={tabs} />
        {activeTab === "workshiftsMainView" && (
          <>
            <div className="mt-5">
              <Divider orientation="left" >{t("timeControl.statistics")}</Divider>
              <Collapse
                className="italic"
                items={[{
                  key: '1',
                  label: t("timeControl.statisticsCollapseTitle"),
                  children: (
                    <Carousel autoplay autoplaySpeed={5000}>
                      <div>
                        <h3 style={carouselStyle}>
                          <Alert className="mb-3" message={t("timeControl.incidencesStatistics")} type="info" />
                          <PieChart
                            data={workCentersIncidencesStatistics}
                            size={400}
                          />
                        </h3>
                      </div>
                      <div>
                        <h3 style={carouselStyle}>
                          <Alert className="mb-3" message={t("timeControl.timeOffsStatistics")} type="info" />
                          <BarChart
                            data={workCentersDistributionStatistics}
                            size={400}
                            legend={true}
                          />
                        </h3>
                      </div>
                    </Carousel>
                  )
                }]}
              />
            </div>
            <div className="mt-7">
              <div className="space-x-4">
                <button
                  type="button"
                  className="min-w-[150px] rounded-md bg-green-50 px-3.5 py-2.5 text-sm font-semibold text-green-600 shadow-sm hover:bg-green-100"
                  onClick={() => navigate(`/time-control/0/0`)}
                >
                  {t("timeControl.addWorkShift")}
                </button>
                <button
                  type="button"
                  className="min-w-[150px] rounded-md bg-indigo-50 px-3.5 py-2.5 text-sm font-semibold text-indigo-600 shadow-sm hover:bg-indigo-100"
                  onClick={() => setModalEmployeeAddIsOpen(true)}
                >
                  {t("employee.addEmployee")}
                </button>
                <Modal isOpen={modalEmployeeAddIsOpen} onClose={() => setModalEmployeeAddIsOpen(false)}>
                  <EmployeeAdd onUpdateParent={updateParentFromEmployeeAdd} closeModal={closeEmployeeAddModal} isLoading={isLoadingChildren} />
                </Modal>
                <AlertModal title={t("common.updatedOkTitle")} text={modalEmployeeAddResult} type="success" isOpen={modalEmployeeAddResult !== ""} onClose={() => setModalEmployeeAddResult("")} />
                <button
                  type="button"
                  className="min-w-[150px] rounded-md bg-cyan-50 px-3.5 py-2.5 text-sm font-semibold text-cyan-600 shadow-sm hover:bg-cyan-100"
                  onClick={() => setReportingModalIsVisible(true)}
                >
                  {t("common.reports")}
                </button>
                <ModalAdvanced
                  title={t("timeControl.reportModalTitle")}
                  subtitle={t("timeControl.reportModalSubtitle")}
                  okText={t("common.close")}
                  closeVisible={false}
                  isOpen={reportingModalIsVisible}
                  onOk={() => setReportingModalIsVisible(false)}
                  onClose={() => setReportingModalIsVisible(false)}
                >
                  <List
                    pagination={{
                      position: "bottom",
                      align: "center",
                      pageSize: 5
                    }}
                    dataSource={[
                      {
                        icon: getImagePath("images/excel.png"),
                        title: t("timeControl.reportModalReport1Title"),
                        description: t("timeControl.reportModalReport1Tooltip"),
                        onClick: () => GetWorkShiftsExportExcel(t("timeControl.reportModalReport1Title"), workShiftFilter)
                      },
                      {
                        icon: getImagePath("images/excel.png"),
                        title: t("timeControl.reportModalReport2Title"),
                        description: t("timeControl.reportModalReport2Tooltip"),
                        onClick: () => GetTimeOffsReport(t("timeControl.reportModalReport2Title"), workShiftFilter)
                      },
                      {
                        icon: getImagePath("images/excel.png"),
                        title: t("timeControl.reportModalReport3Title"),
                        description: t("timeControl.reportModalReport3Tooltip"),
                        onClick: () => GetIncidencesReport(t("timeControl.reportModalReport3Title"), workShiftFilter)
                      },
                      {
                        icon: getImagePath("images/excel.png"),
                        title: t("timeControl.reportModalReport4Title"),
                        description: t("timeControl.reportModalReport4Tooltip"),
                        onClick: () => GetEmployeesCertificatesReport(t("timeControl.reportModalReport4Title"), workShiftFilter)
                      }
                    ]}
                    renderItem={(item, _) => (
                      <List.Item>
                        <List.Item.Meta
                          avatar={<Avatar className="mt-2" src={item.icon} alt={item.title} size="large" />}
                          description=
                          {
                            <div className="py-2 relative flex justify-between">
                              <div className="pl-2 min-w-0 flex-auto">
                                <p className="text-sm font-semibold leading-6 text-gray-900">
                                  {item.title}
                                </p>
                                <p className="mt-1 pr-10 flex text-xs text-justify leading-5 text-gray-500">
                                  {item.description}
                                </p>
                              </div>
                              <div className="flex shrink-0 items-center gap-x-4">
                                <button
                                  type="button"
                                  className="rounded-md bg-indigo-50 px-2.5 py-1.5 text-sm font-semibold text-indigo-600 shadow-sm hover:bg-indigo-100"
                                  onClick={async () => {
                                    setIsLoading(true);
                                    await item.onClick();
                                    setIsLoading(false);
                                  }}
                                >
                                  {t("common.generate")}
                                </button>
                              </div>
                            </div>
                          }
                        />
                      </List.Item>
                    )}
                  />
                </ModalAdvanced>
              </div>
              <Divider className="pt-2" orientation="left">{t("timeControl.workCenterViewTitle")}</Divider>
              <TableDynamic
                tableName={`${pageName}_MainView`}
                fetchData={fetchWorkShiftData}
                getTotalCount={async () => {
                  if (!workShiftFilter) {
                    return;
                  }
                  return GetWorkShiftsGroupByWorkCenterCount(workShiftFilter);
                }}
                filterConfig={filterConfig}
                defaultFilters={state[pageName]?.workShiftFilter || {}}
                onFilterChange={handleFilterUpdate}
                sortConfig={sortConfig}
                defaultSort={state[pageName]?.workShiftSort || []}
                onSortChange={handleSortUpdate}
                onRowButtonClick={(primaryKeyValue) => {
                  navigate(`/time-control/work-center/${primaryKeyValue}`);
                }}
                itemsPerPageDefault={10}
                translationNode="timeControl" />
              {employeeIsTimeControlSupervisor === true &&
                <div className="mt-8">
                  <h2 className="text-base font-semibold leading-7 text-gray-900">{t("timeControl.supervisedEmployeesViewTitle")}</h2>
                  <p className="mt-3 mb-4 text-sm italic text-gray-700">
                    {t("timeControl.supervisedEmployeesViewSubtitle")}
                  </p>
                  <TableDynamic
                    tableName={`${pageName}_Supervisor`}
                    fetchData={fetchSupervisedEmployeesWorkShiftData}
                    getTotalCount={async () => {
                      if (!supervisedEmployeesWorkShiftFilter) {
                        return;
                      }
                      return GetSupervisedEmployeesWorkShiftsCount(supervisedEmployeesWorkShiftFilter);
                    }}
                    filterConfig={supervisedEmployeesWorkShiftFilterConfig}
                    defaultFilters={state[pageName]?.supervisedEmployeesWorkShiftFilter || {}}
                    onFilterChange={handleSupervisedEmployeesFilterUpdate}
                    sortConfig={supervisedEmployeesWorkShiftSortConfig}
                    defaultSort={state[pageName]?.supervisedEmployeesWorkShiftSort || []}
                    onSortChange={handleSupervisedEmployeesSortUpdate}
                    onRowButtonClick={(primaryKeyValue) => {
                      navigate(`/time-control/employee/0/${primaryKeyValue}`);
                    }}
                    itemsPerPageDefault={10}
                    translationNode="timeControl" />
                </div>}
            </div>
          </>
        )}
        {activeTab === "workshiftByMonthByUserView" && (
          <div>&nbsp;</div>
        )}
      </div >
    </div >
  )
}
