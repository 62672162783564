import React, { useState } from 'react';
import type { TableColumnsType, TableProps } from 'antd';
import { Input, Table, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { CheckIcon, ExclamationTriangleIcon, EyeIcon, HandThumbUpIcon } from '@heroicons/react/24/outline';
import { useNavigate } from 'react-router-dom';
import { DTOTimeControlWorkCenterSummary, DTOTimeControlWorkCenterSummaryByEmployee } from '../../../interfaces/time-control/DTOTimeControlWorkCenterSummary';
import { ConfigProvider } from 'antd';
import esES from 'antd/lib/locale/es_ES';
import { EmployeeStatus, TimeControlSummaryByWorkCenterAlertType as TimeControlWorkCenterSummaryAlertType } from '../../../resources/utils/enums';
import { useColumnFilterProps } from '../../../components/common/filter/AntdFilter';

interface TimeControlWorkCenterSummaryTableProps {
    workCenterId: number;
    timeControlWorkCenterSummary: DTOTimeControlWorkCenterSummary | null;
}

const TimeControlWorkCenterSummaryTable: React.FC<TimeControlWorkCenterSummaryTableProps> = ({ workCenterId, timeControlWorkCenterSummary }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const employeeFullNameFilterProps = useColumnFilterProps<DTOTimeControlWorkCenterSummaryByEmployee>('employeeFullName');
    const statusFilterProps = useColumnFilterProps<DTOTimeControlWorkCenterSummaryByEmployee>(
        'status',
        'checkbox',
        [
            { text: t("employee.statusActive"), value: EmployeeStatus.Active, defaultChecked: true },
            { text: t("employee.statusInactiveLast30Days"), value: EmployeeStatus.InactiveLast30Days, defaultChecked: true },
            { text: t("employee.statusInactive"), value: EmployeeStatus.Inactive },
        ]
    );

    const columns: TableColumnsType<DTOTimeControlWorkCenterSummaryByEmployee> = [
        {
            key: 'isValidated',
            align: 'center',
            className: 'min-w-min w-[85px]',
            render: (_, employee) => (
                <div className="flex items-center justify-center space-x-2 ml-1">
                    {employee.alertType === TimeControlWorkCenterSummaryAlertType.OK ? (
                        <CheckIcon className="h-7 w-7 text-green-500" />
                    ) : employee.alertType === TimeControlWorkCenterSummaryAlertType.HasIncidences ? (
                        <Tooltip placement="topLeft" title={t("timeControl.hasIncidences")} color="red">
                            <ExclamationTriangleIcon className="h-7 w-7 text-red-600" />
                        </Tooltip>
                    ) : employee.alertType === TimeControlWorkCenterSummaryAlertType.SupervisedByTimeControlResponsible ? (
                        <Tooltip placement="topLeft" title={t("timeControl.supervisedByTimeControlResponsible")} color="geekblue">
                            <HandThumbUpIcon className="h-7 w-7 text-indigo-500" />
                        </Tooltip>
                    ) : null}
                </div>
            ),
        },
        {
            title: '',
            key: 'operation',
            align: 'center',
            className: 'min-w-min w-[85px]',
            render: (_, employee) => (
                <div className="flex items-center justify-center space-x-2 ml-1">
                    <Tooltip placement="topLeft" title={t("timeControl.viewEmployeeSummary")} color="geekblue">
                        <button
                            className="rounded-md bg-indigo-50 px-2 py-1 text-sm font-semibold text-indigo-600 shadow-sm hover:bg-indigo-100"
                            onClick={() => (
                                navigate(`/time-control/employee/${workCenterId}/${employee.employeeId}`)
                            )}
                        >
                            <EyeIcon className="h-5 w-5" aria-hidden="true" />
                        </button>
                    </Tooltip>
                </div >
            )
        },
        {
            title: t("employee.firstName"),
            dataIndex: 'employeeFullName',
            key: 'employeeFullName',
            align: 'left',
            ...employeeFullNameFilterProps,
            sorter: (a, b) => a.employeeFullName.localeCompare(b.employeeFullName),
            sortDirections: ['ascend', 'descend'],
            defaultSortOrder: 'ascend'
        },
        {
            title: t("employee.currentStatus"),
            dataIndex: 'status',
            key: 'status',
            align: 'center',
            render: (_, employee) => (
                employee.status === EmployeeStatus.Active ? (
                    <p className="text-green-600 font-semibold">{t("employee.statusActive")}</p>
                ) : employee.status === EmployeeStatus.InactiveLast30Days ? (
                    <p className="text-amber-600 font-semibold">{t("employee.statusInactiveLast30Days")}</p>
                ) : employee.status === EmployeeStatus.Inactive ? (
                    <p className="text-red-600 font-semibold">{t("employee.statusInactive")}</p>
                ) : null
            ),
            ...statusFilterProps,
            sorter: (a, b) => a.status - b.status,
            sortDirections: ['ascend', 'descend'],
        },
        {
            title: t("timeControl.incidencesCount"),
            dataIndex: 'incidencesCount',
            key: 'incidencesCount',
            align: 'center',
            sorter: (a, b) => a.incidencesCount - b.incidencesCount,
            sortDirections: ['ascend', 'descend'],
        }
    ];

    const data = timeControlWorkCenterSummary ? timeControlWorkCenterSummary.employees : [];

    return (
        <ConfigProvider locale={esES}>
            <Table
                rowKey={employee => employee.employeeId.toString()}
                columns={columns}
                dataSource={data}
                pagination={false}
            />
        </ConfigProvider>
    );
};

export default TimeControlWorkCenterSummaryTable;