import { useState, useEffect, useContext } from 'react';
import { useNavigate } from "react-router-dom";
import { NotificationContext } from '../../contexts/NotificationContext';
import { useTranslation } from "react-i18next";
import Alert from '../../components/common/alert/Alert'
import { GetEmployeeFromCookie, PatchEmployee } from "../../services/employee/EmployeeService";
import { Employee } from '../../interfaces/employee/Employee';
import { XMarkIcon, CheckIcon } from '@heroicons/react/24/solid';
import { DeleteCookie, SetCookie } from '../../services/cookie/CookieService';
import { NotificationTypes } from '../../components/common/Notification';
import { getImagePath } from '../../resources/utils/common';

export default function Profile() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { showNotification } = useContext(NotificationContext);
  const [employee, setEmployee] = useState<Employee | null>(null);

  const fetchEmployee = async () => {
    const result = await GetEmployeeFromCookie();
    if (result !== undefined) {
      setEmployee(result);
    }
  };

  useEffect(() => {
    fetchEmployee();
  }, []);

  const handleUpdateEmployee = async () => {
    if (employee !== null) {
      const updatedEmployee: Employee | null = await PatchEmployee(employee.id, [
        { fieldName: "email", fieldValue: employee.email },
      ]);
      if (updatedEmployee !== null) {
        setEmployee(updatedEmployee);
        DeleteCookie("employee");
        SetCookie("employee", JSON.stringify(updatedEmployee), 1000);
        showNotification({ color: NotificationTypes.Success, messageTitle: t("common.updatedOkTitle"), messageText: t("common.updatedOkSubtitle") });
        navigate("/");
      } else {
        showNotification({ color: NotificationTypes.Warning, messageTitle: t("common.updatedErrorTitle"), messageText: t("common.updatedErrorSubtitle") });
      }
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    setEmployee((prevEmployee) => {
      if (prevEmployee === null) {
        return prevEmployee;
      }

      return {
        ...prevEmployee,
        [name]: value,
      };
    });
  };

  return (
    <>
      <div className="border-b border-gray-200 bg-white px-4 py-5 sm:px-6">
        <div className="-ml-4 -mt-4 flex flex-wrap items-center justify-between sm:flex-nowrap">
          <div className="ml-4 mt-4">
            <div className="flex items-center">
              <div className="flex-shrink-0">
                <img
                  className="h-12 w-12 rounded-full"
                  src={getImagePath("images/default-user-icon.webp")}
                  alt=""
                />
              </div>
              <div className="ml-4">
                <h3 className="text-base font-semibold leading-6 text-gray-900">{employee?.firstName} {employee?.lastName1} {employee?.lastName2}</h3>
                <p className="text-sm text-gray-500">
                  {employee?.email}
                </p>
              </div>
            </div>
          </div>
          <div className="ml-4 mt-4 flex flex-shrink-0">
            <a
              href="/"
              type="button"
              className="relative inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
            >
              <XMarkIcon className="-ml-0.5 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
              <span>Cancelar</span>
            </a>
            <button
              type="button"
              className="relative ml-3 inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
              onClick={handleUpdateEmployee}
            >
              <CheckIcon className="-ml-0.5 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
              <span>Guardar</span>
            </button>
          </div>
        </div>
      </div>

      <div className="mx-auto max-w-7xl px-4 py-8 sm:px-6 lg:px-8">
        <div className="mx-auto grid max-w-2xl grid-cols-1 grid-rows-1 items-start gap-x-8 gap-y-8 lg:mx-0 lg:max-w-none lg:grid-cols-1">
          <Alert text='Esta pantalla está en construcción' type='warning' />
          <div className="space-y-12">
            <div className="border-b border-gray-900/10 pb-12">
              <h2 className="text-base font-semibold leading-7 text-gray-900">Datos personales</h2>
              <div className="mt-5 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-9">
                <div className="sm:col-span-4">
                  <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                    Email
                  </label>
                  <div className="mt-2">
                    <input
                      id="email"
                      name="email"
                      type="email"
                      autoComplete="email"
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      value={employee?.email || ""}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
