import React, { ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useField } from 'formik';
import { ExclamationCircleIcon } from "@heroicons/react/20/solid";
import { DefaultTFuncReturn } from 'i18next';
import { DatePicker, InputNumber, Select, TimePicker, Tooltip } from 'antd';
import type { DatePickerProps } from 'antd/es/date-picker';
import locale from 'antd/es/date-picker/locale/es_ES';
import dayjs from 'dayjs';
import 'dayjs/locale/es';

export enum FieldTypes {
  Text = 0,
  Select = 1,
  SelectWithFinder = 2,
  Textarea = 3,
  DatePicker = 4,
  DateTimePicker = 5,
  TimePicker = 6,
  Numeric = 7
}

type OptionType = {
  value: string | number;
  label: string;
};

type FormFieldProps = {
  colspan?: number;
  label?: string | DefaultTFuncReturn;
  name: string;
  type?: string;
  customType?: FieldTypes;
  placeholder?: string;
  options?: OptionType[];
  children?: ReactNode;
  tooltip?: string;
  onChange?: (value: any) => void;
};

export function FormField({ colspan, label, name, type = "text", customType = FieldTypes.Text, placeholder, options, children, tooltip, onChange }: FormFieldProps) {
  const { t } = useTranslation();
  const [field, meta, helpers] = useField(name);
  const isError = meta.touched && meta.error;
  const [shadow, setShadow] = useState<boolean>(true);

  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);

  dayjs.locale('es');

  useEffect(() => {
    if (customType === FieldTypes.TimePicker) {
      const totalMinutes = hours * 60 + minutes;
      helpers.setValue(totalMinutes);
      setShadow(false);
    }
  }, [hours, minutes, helpers, customType]);

  useEffect(() => {
    if (customType === FieldTypes.TimePicker && field.value) {
      const initialMinutes = parseInt(field.value);
      const initialHours = Math.floor(initialMinutes / 60);
      const initialMin = initialMinutes % 60;
      setHours(initialHours);
      setMinutes(initialMin);
    }
  }, [field.value, customType]);

  const inputClasses = isError
    ? "block w-full rounded-md border-0 py-1.5 pr-10 text-red-900 ring-1 ring-inset ring-red-300 placeholder:text-red-300 focus:ring-2 focus:ring-inset focus:ring-red-500 sm:text-sm sm:leading-6"
    : "block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6";

  let enhancedChild;

  if (children) {
    const formElement = React.Children.only(children) as React.ReactElement;
    if (formElement && (formElement as React.ReactElement).type) {
      const elementType = (formElement as React.ReactElement).type;
      if (elementType === "input" || elementType === "select") {

        enhancedChild = React.cloneElement(formElement, {
          ...field,
          type: type,
          placeholder: placeholder,
          className: inputClasses
        });
      } else {
        enhancedChild = formElement;
      }
    }
  } else if (customType === FieldTypes.Select && options) {
    const selectOptions = options.map(option => ({
      label: option.label,
      value: String(option.value),
    }));

    const initialValue = selectOptions.find(option => option.value === String(field.value)) || selectOptions[0];

    const handleChange = (option: { value: string; label: string }) => {
      field.onChange({ target: { name: field.name, value: option.value } });
      if (onChange) {
        onChange(option.value);
      }
    };

    const filterOption = (input: string, option: any) =>
      option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;

    enhancedChild = (
      <Select
        style={{ width: '100%', height: '36px' }}
        labelInValue
        value={initialValue ? { label: initialValue.label, value: initialValue.value } : undefined}
        onChange={handleChange}
        placeholder=""
        optionFilterProp="children"
        filterOption={filterOption}
        options={selectOptions}
      />
    );
  } else if (customType === FieldTypes.SelectWithFinder && options) {
    const selectOptions = options.map(option => ({
      label: option.label,
      value: String(option.value),
    }));

    const initialValue = selectOptions.find(option => option.value === String(field.value)) || selectOptions[0];

    const handleChange = (option: { value: string; label: string }) => {
      field.onChange({ target: { name: field.name, value: option.value } });
      if (onChange) {
        onChange(option.value);
      }
    };

    const filterOption = (input: string, option: any) =>
      option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;

    enhancedChild = (
      <Select
        showSearch
        style={{ width: '100%', height: '36px' }}
        labelInValue
        value={initialValue ? { label: initialValue.label, value: initialValue.value } : undefined}
        onChange={handleChange}
        placeholder=""
        optionFilterProp="children"
        filterOption={filterOption}
        options={selectOptions}
      />
    );
  } else if (customType === FieldTypes.Textarea) {
    const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      field.onChange({ target: { name: field.name, value: e.target.value } });
      if (onChange) {
        onChange(e.target.value);
      }
    };

    enhancedChild = (
      <textarea
        {...field}
        rows={3}
        placeholder={placeholder}
        className={inputClasses}
        value={field.value ? field.value : ""}
        onChange={handleChange}
      />
    );
  } else if (customType === FieldTypes.DatePicker) {
    const handleChange = (
      value: DatePickerProps['value'],
    ) => {
      field.onChange({ target: { name: field.name, value: value ? dayjs(value).hour(0).minute(0).second(0).millisecond(0).format('YYYY-MM-DD') : null } });
      if (onChange) {
        onChange(dayjs(value).hour(0).minute(0).second(0).millisecond(0).format('YYYY-MM-DD'));
      }
    };

    enhancedChild = (
      <DatePicker
        style={{ fontFamily: 'Inter var' }}
        className={inputClasses}
        format="DD-MM-YYYY"
        value={field.value ? dayjs(field.value) : null}
        onChange={handleChange}
        placeholder={t("common.selectDate").toString()}
        locale={{
          ...locale,
          lang: {
            ...locale.lang,
            now: t("common.selectDateNow"),
            ok: t("common.selectDateConfirmation"),
          }
        }}
      />
    );
  } else if (customType === FieldTypes.DateTimePicker) {
    const handleChange = (
      value: DatePickerProps['value'],
    ) => {
      field.onChange({ target: { name: field.name, value: value ? dayjs(value).format('YYYY-MM-DD HH:mm:ss') : null } });
      if (onChange) {
        onChange(dayjs(value).format('YYYY-MM-DD HH:mm:ss'));
      }
    };

    enhancedChild = (
      <DatePicker
        style={{ fontFamily: 'Inter var' }}
        className={inputClasses}
        showTime={{ format: 'HH:mm:ss' }}
        format="DD/MM/YYYY HH:mm:ss"
        value={field.value ? dayjs(field.value) : null}
        onChange={handleChange}
        placeholder={t("common.selectDate").toString()}
        locale={{
          ...locale,
          lang: {
            ...locale.lang,
            now: t("common.selectDateNow"),
            ok: t("common.selectDateConfirmation"),
          }
        }}
      />
    );
  } else if (customType === FieldTypes.Numeric) {
    enhancedChild = (
      <div className="flex space-x-2">
        <InputNumber
          size="small"
          className={`form-field-timepicker ${inputClasses}`}
          min={0}
          max={23}
          value={hours}
          onChange={(value) => field.onChange({ target: { name: field.name, value: value } })}
        />
      </div>
    );
  } else if (customType === FieldTypes.TimePicker) {
    enhancedChild = (
      <div className="flex space-x-2">
        <InputNumber
          style={{ height: '36px' }}
          className="form-field-timepicker"
          min={0}
          max={23}
          value={hours}
          onChange={(value) => setHours(value ?? 0)}
        />
        <InputNumber
          className="form-field-timepicker"
          min={0}
          max={59}
          value={minutes}
          onChange={(value) => setMinutes(value ?? 0)}
        />
      </div>
    );
  } else {
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      field.onChange({ target: { name: field.name, value: e.target.value } });
      if (onChange) {
        onChange(e.target.value);
      }
    };

    enhancedChild = (
      <input
        {...field}
        type={type}
        placeholder={placeholder}
        className={inputClasses}
        onChange={handleChange}
      />
    );
  }

  return (
    <div className={colspan ? `sm:col-span-${colspan}` : ""}>
      {label && (
        tooltip ? (
          <>
            <Tooltip placement="topLeft" title={tooltip} color="geekblue">
              <label htmlFor={name} className="block text-sm font-medium leading-6 text-gray-900">
                {label}
              </label>
            </Tooltip>
          </>
        ) : (
          <label htmlFor={name} className="block text-sm font-medium leading-6 text-gray-900">
            {label}
          </label>
        )
      )}
      <div className={`relative ${label ? "mt-2 " : " "}rounded-md${children && React.Children.only(children) && (React.Children.only(children) as React.ReactElement).type ? " h-9" : (shadow ? " shadow-sm" : "")}`}>
        {enhancedChild}
        {isError && (
          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
            <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
          </div>
        )}
      </div>
      {isError && <p className="mt-1.5 ml-1 text-xs text-red-600 italic" id={`${name}-error`}>{meta.error}</p>}
    </div>
  );
}