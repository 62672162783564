import axiosRRHHEmployeeAPI from "../axios/AxiosRRHHEmployeeAPI";
import { Calendar } from "../../interfaces/calendar/Calendar";
import { AnnualCalendar } from "../../interfaces/common/calendar/AnnualCalendar";
import { DTOEmployeeOptions } from "../../interfaces/employee/DTOEmployeeOptions";
import { DTOCalendarOption } from "../../interfaces/calendar/DTOCalendarOption";

export async function Get(
    id: number
): Promise<Calendar> {
    const response = await axiosRRHHEmployeeAPI.get(`/api/Calendar/${id}`);
    return response?.status === 200 ? response.data : {};
}

export async function GetAnnualCalendar(
    id: number,
    year?: number
): Promise<AnnualCalendar> {
    const response = await axiosRRHHEmployeeAPI.get(year ? `/api/Calendar/GetAnnualCalendar/${id}/${year}` : `/api/Calendar/GetAnnualCalendar/${id}`);
    return response?.status === 200 ? response.data : {};
}

export async function GetCalendarsByWorkCenterId(
    workCenterId: number
): Promise<Calendar[]> {
    const response = await axiosRRHHEmployeeAPI.get(`/api/Calendar/GetCalendarsByWorkCenterId/${workCenterId}`);
    return response?.status === 200 ? response.data : [];
}

export async function GetBulkAllocationToEmployeesByWorkCenterId(
    workCenterId: number
): Promise<DTOEmployeeOptions[]> {
    const response = await axiosRRHHEmployeeAPI.get(`/api/Calendar/GetBulkAllocationToEmployeesByWorkCenterId/${workCenterId}`);
    return response?.status === 200 ? response.data : [];
}

export async function PostBulkAllocationToEmployeesByCalendarId(
    calendarId: number,
    employeeIds: number[]
): Promise<boolean> {
    const response = await axiosRRHHEmployeeAPI.post(`/api/Calendar/PostBulkAllocationToEmployeesByCalendarId/${calendarId}`, employeeIds);
    return response?.status === 200;
}

export async function PostBulkAllocationToWorkCentersByCalendarId(
    calendarId: number,
    workCenterIds: number[]
): Promise<boolean> {
    const response = await axiosRRHHEmployeeAPI.post(`/api/Calendar/PostBulkAllocationToWorkCentersByCalendarId/${calendarId}`, workCenterIds);
    return response?.status === 200;
}

export async function GetCalendarByEmployeeIdByYear(
    employeeId: number,
    year: number
): Promise<Calendar[]> {
    const response = await axiosRRHHEmployeeAPI.get(`/api/Calendar/${employeeId}/${year}`);
    return response?.status === 200 ? response.data : [];
}

export async function Post(
    calendar: Calendar
): Promise<Calendar | null> {
    const response = await axiosRRHHEmployeeAPI.post("/api/Calendar", calendar);
    return response?.status === 200 ? response.data : null;
}

export async function Put(
    calendar: Calendar
): Promise<boolean> {
    const response = await axiosRRHHEmployeeAPI.put("/api/Calendar", calendar);
    return response?.status === 200;
}

export async function DeleteCalendar(
    calendarId: number
): Promise<boolean> {
    const response = await axiosRRHHEmployeeAPI.delete(`/api/Calendar/${calendarId}`);
    return response?.status === 200;
}

export async function GetCalendarOptionsIncludingCompanyWorkCenterYear(): Promise<DTOCalendarOption[]> {
    const response = await axiosRRHHEmployeeAPI.get("/api/Calendar/GetCalendarOptionsIncludingCompanyWorkCenterYear");
    return response?.status === 200 ? response.data : [];
}

export async function ImportCalendarToEmployee(
    employeeId: number,
    calendarId: number
): Promise<Calendar> {
    const response = await axiosRRHHEmployeeAPI.get(`/api/Calendar/ImportCalendarToEmployee/${employeeId}/${calendarId}`);
    return response?.status === 200 ? response.data : [];
}