import React, { useState, useEffect } from 'react';
import { Radio } from 'antd';
import { Tree } from 'antd';
import { useTranslation } from "react-i18next";
import { useFormikContext } from 'formik';

interface UserPermissionsProps {
  permissions: { [key: string]: { [key: string]: {} } };
}

interface TreeNodeData {
  title: string;
  key: string;
  children?: TreeNodeData[];
  hasRadioButtons?: boolean;
  radioOptions?: number[];
}

type RadioSelections = {
  [key: string]: string;
};

const UserPermissions: React.FC<UserPermissionsProps> = ({ permissions }) => {
  const { t } = useTranslation();
  const formik = useFormikContext();
  const [checkedKeys, setCheckedKeys] = useState<string[]>([]);

  const permissionsSchema = {
    "EmployeePortal": {
      "timeControl": {},
      "home": {},
    },
    "ERP": {
      "home": {},
      "rrhh": {
        "timeControl": [
          "roleRRHH",
          "roleDepartmentResponsible",
          "roleWorkCenterTimeControlResponsible",
        ]
      },
      "maintenance": {
        "companies": [
          "roleRRHH",
          "roleWorkCenterTimeControlResponsible",
        ],
        "employees": [
          "roleRRHH",
          "roleDepartmentResponsible",
          "roleWorkCenterTimeControlResponsible",
        ]
      }
    }
  };

  const [radioSelections, setRadioSelections] = useState<RadioSelections>({});

  useEffect(() => {
    setRadioSelections({});
  }, []);

  const generateTreeNodes = (schema: any, prefix: string = ''): TreeNodeData[] => {
    return Object.keys(schema).map((key) => {
      const newKey = prefix ? `${prefix}_${key}` : key;
      const node = schema[key];
      let children: any = [];

      if (Array.isArray(node) && node.length) {
        return {
          title: t(`user.permissions${newKey}`),
          key: newKey,
          hasRadioButtons: true,
          radioOptions: node,
          children: undefined,
        };
      } else if (typeof node === 'object') {
        children = generateTreeNodes(node, newKey);
      }

      return {
        title: t(`user.permissions${newKey}`),
        key: newKey,
        hasRadioButtons: false,
        radioOptions: undefined,
        children: children.length > 0 ? children : undefined,
      };
    });
  };

  const flattenPermissions = (input: any, prefix: string = '', localRadioSelections: RadioSelections = {}): [string[], RadioSelections] => {
    let result: string[] = [];

    const obj = (typeof input === 'string') ? JSON.parse(input) : input;

    for (let key in obj) {
      if (obj.hasOwnProperty(key)) {
        const newKey = prefix ? `${prefix}_${key}` : key;
        if (typeof obj[key] !== 'object' || typeof obj[key] === 'object' && Object.keys(obj[key]).length === 0) {
          result.push(newKey);
        } else if (typeof obj[key] === 'object' && Array.isArray(obj[key])) {
          result.push(newKey);
          let newRadioSelections = { ...localRadioSelections };
          if (!newRadioSelections[newKey]) {
            localRadioSelections = { ...localRadioSelections, [newKey]: obj[key].toString() };
          }
        } else {
          const [nestedResult, nestedRadioSelections] = flattenPermissions(obj[key], newKey, localRadioSelections);
          result = [...result, ...nestedResult];
          localRadioSelections = { ...localRadioSelections, ...nestedRadioSelections };
        }
      }
    }

    return [result, localRadioSelections];
  };

  const permissionsToObject = (checkedKeys: string[], schema: any, radioSelections: RadioSelections, prefix: string = ''): any => {
    const result: { [key: string]: any } = {};

    for (const key in schema) {
      const fullKey = prefix ? `${prefix}_${key}` : key;

      if (Array.isArray(schema[key])) {
        if (checkedKeys.includes(fullKey)) {
          const selectedValue = radioSelections[fullKey];
          if (schema[key].includes(selectedValue)) {
            result[key] = [selectedValue];
          }
        }
      } else if (checkedKeys.includes(fullKey)) {
        result[key] = permissionsToObject(checkedKeys, schema[key], radioSelections, fullKey);
      } else {
        const childKeys = checkedKeys.filter(ck => ck.startsWith(fullKey + '_'));
        if (childKeys.length > 0) {
          const nested = permissionsToObject(childKeys, schema[key], radioSelections, fullKey);
          if (Object.keys(nested).length > 0) {
            result[key] = nested;
          }
        }
      }
    }

    return result;
  };

  const sortObject = (schema: any, json: any): any => {
    const result: { [key: string]: any } = {};

    for (const key in schema) {
      if (json.hasOwnProperty(key)) {
        if (typeof schema[key] === 'object' && !isEmpty(schema[key])) {
          result[key] = sortObject(schema[key], json[key]);
        } else {
          result[key] = json[key];
        }
      }
    }

    return result;
  };

  const isEmpty = (obj: any) => Object.keys(obj).length === 0;

  useEffect(() => {
    if (permissions) {
      const [resultCheckedKeys, resultRadioSelections] = flattenPermissions(permissions);
      setCheckedKeys(resultCheckedKeys)
      setRadioSelections(resultRadioSelections);
    }
  }, [permissions]);

  const getNodeFullPath = (key: string, schema: any, prefix: string = ''): string => {
    for (const schemaKey in schema) {
      const fullKey = prefix ? `${prefix}_${schemaKey}` : schemaKey;
      if (fullKey === key) {
        return fullKey;
      } else if (typeof schema[schemaKey] === 'object') {
        const path = getNodeFullPath(key, schema[schemaKey], fullKey);
        if (path) return path;
      }
    }
    return '';
  };

  const updatePermissions = (checkedKeys: string[], radioSelections: RadioSelections) => {
    const finalPermissions = permissionsToObject(checkedKeys, permissionsSchema, radioSelections);
    formik.setFieldValue('permissions', finalPermissions);
  };

  const handleCheck = (checkedKeys: any) => {
    const checkedKeysValues = checkedKeys.checked ? checkedKeys.checked : checkedKeys;
    let newRadioSelections = { ...radioSelections };

    checkedKeysValues.forEach((key: string) => {
      const node = getNodeFromKey(key, permissionsSchema);
      if (node && Array.isArray(node) && node.length) {
        if (!newRadioSelections[key]) {
          newRadioSelections[key] = node[0];
        }
      }
    });

    Object.keys(radioSelections).forEach(radioKey => {
      const fullPath = getNodeFullPath(radioKey, permissionsSchema);
      if (!checkedKeysValues.includes(fullPath)) {
        delete newRadioSelections[radioKey];
      }
    });

    setCheckedKeys(checkedKeysValues);
    setRadioSelections(newRadioSelections);
    updatePermissions(checkedKeysValues, newRadioSelections);
  };

  const handleRadioChange = (key: string, value: string) => {
    let updatedCheckedKeys = checkedKeys;

    if (!checkedKeys.includes(key)) {
      updatedCheckedKeys = [...checkedKeys, key];
      setCheckedKeys(updatedCheckedKeys);
    }

    setRadioSelections(prevSelections => {
      const newRadioSelections = {
        ...prevSelections,
        [key]: value
      };

      updatePermissions(updatedCheckedKeys, newRadioSelections);
      return newRadioSelections;
    });
  };

  const renderTreeNode = (nodeData: TreeNodeData) => {
    if (nodeData.hasRadioButtons && nodeData.radioOptions) {
      return (
        <div>
          <span>{nodeData.title}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{`=>`}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
          <Radio.Group
            value={radioSelections[nodeData.key]}
            onChange={(e) => handleRadioChange(nodeData.key, e.target.value)}
          >
            {nodeData.radioOptions.map(option => (
              <Radio key={option} value={option}>{t(`permissions.${option}`)}</Radio>
            ))}
          </Radio.Group>
        </div>
      );
    }

    return <span>{nodeData.title}</span>;
  };

  const getNodeFromKey = (key: string, schema: any, prefix: string = ''): any => {
    for (const schemaKey in schema) {
      const fullKey = prefix ? `${prefix}_${schemaKey}` : schemaKey;

      if (fullKey === key) {
        return schema[schemaKey];
      } else if (typeof schema[schemaKey] === 'object') {
        const foundNode = getNodeFromKey(key, schema[schemaKey], fullKey);
        if (foundNode) return foundNode;
      }
    }

    return null;
  };

  return (
    <Tree
      checkable
      checkStrictly={false}
      onCheck={handleCheck}
      defaultExpandAll
      checkedKeys={checkedKeys}
      treeData={generateTreeNodes(permissionsSchema)}
      titleRender={renderTreeNode}
    />
  );
}

export default UserPermissions;