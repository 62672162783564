import React from 'react';
import { ResponsiveBar, BarTooltipProps } from '@nivo/bar';
import { DTOBarChartItem } from '../../../interfaces/common/statistics/DTOBarChartItem';

interface BarChartProps {
  data: DTOBarChartItem[];
  legend?: boolean;
  size?: number;
}

interface ChartData {
  category: string;
  value: number;
}

const transformDataForBarChart = (data: DTOBarChartItem[]): any[] => {
  return data.map(item => {
    const result: { [key: string]: any } = { category: item.category, categoryLabel: item.category };
    item.values.forEach(valueItem => {
      result[valueItem.key] = valueItem.value;
      result[`${valueItem.key}Label`] = valueItem.label;
    });
    return result;
  });
};

const MyCustomTooltip = (bar: any) => {
  const label = bar.data[`${bar.id}Label`];

  return (
    <div className="bg-white p-2 border border-gray-300 shadow-lg rounded-lg">
      <div className="text-xs">{label}</div>
      <div className="mt-1.5 text-lg font-bold text-gray-700">{bar.id}</div>
      <div className="mt-0.5 text-lg text-gray-700">{bar.value}</div>
    </div>
  );
};

const BarChart: React.FC<BarChartProps> = ({ data, legend = false, size = 300 }) => {
  const barData = transformDataForBarChart(data);

  const keys = data.flatMap(item => item.values.map(v => v.key))
    .filter((value, index, self) => self.indexOf(value) === index);

  return (
    <div className="mb-10" style={{ height: size }}>
      <ResponsiveBar
        data={barData}
        keys={keys}
        indexBy="category"
        tooltip={MyCustomTooltip as any}
        margin={{ top: 50, right: 60, bottom: 50, left: 250 }}
        padding={0.3}
        valueScale={{ type: 'linear' }}
        indexScale={{ type: 'band', round: true }}
        colors={{ scheme: 'nivo' }}
        borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
        axisTop={null}
        axisRight={null}
        axisBottom={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: '',
          legendPosition: 'middle',
          legendOffset: 32
        }}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: '',
          legendPosition: 'middle',
          legendOffset: -40
        }}
        legends={legend ? [
          {
            dataFrom: 'keys',
            anchor: 'top-left',
            direction: 'column',
            justify: false,
            translateX: -240,
            translateY: 0,
            itemsSpacing: 10,
            itemWidth: 100,
            itemHeight: 20,
            itemDirection: 'left-to-right',
            itemOpacity: 0.85,
            symbolSize: 20,
            effects: [
              {
                on: 'hover',
                style: {
                  itemOpacity: 1
                }
              }
            ]
          }
        ] : undefined}
      />
    </div>
  );
};

export default BarChart;