import { Alert, Button, Modal, Skeleton, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { GetManualMonthClosingData, GetManualMonthClosingConfirmation, SaveManualMonthClosing } from '../../../services/time-control/TimeControlService';
import { DTOTimeControlManualMonthClosingConfirmationData, DTOTimeControlManualMonthClosingData, DTOWorkShift } from '../../../interfaces/time-control/DTOTimeControlEmployeeSummary';
import { useEffect, useState } from 'react';
import { useSpinner } from '../../../contexts/SpinnerContext';

interface TimeControlManualMonthClosingProps {
    employeeId: number;
    workCenterId: number;
    year: number;
    month: number;
    onOk: () => void;
    onCancel: () => void;
}

interface CompensationTime {
    hours: number;
    minutes: number;
}

const TimeControlManualMonthClosing: React.FC<TimeControlManualMonthClosingProps> = ({ employeeId, workCenterId, year, month, onOk, onCancel }) => {
    const { t } = useTranslation();
    const { setIsLoading } = useSpinner(false);
    const [manualMonthClosingData, setManualMonthClosingData] = useState<DTOTimeControlManualMonthClosingData | null | undefined>(undefined);
    const [remarksField, setRemarksField] = useState<string>("");
    const [selectedOption, setSelectedOption] = useState("option1");

    useEffect(() => {
        async function fetchData() {
            await GetManualMonthClosingData(employeeId, workCenterId, year, month)
                .then((data) => {
                    setManualMonthClosingData(data);
                    setIsLoading(false);
                });
        }

        fetchData();
        setRemarksField(t("timeControl.manualMonthClosingRemarksOption1").toString());
    }, [employeeId, year, month, t]);

    const handleRadioChange = (event: any) => {
        setSelectedOption(event.target.value);
        if (event.target.value === "option1") {
            setRemarksField(t("timeControl.manualMonthClosingRemarksOption1").toString());
        } else {
            if (remarksField.trim() !== "") {
                setRemarksField("");
            }
        }
    };

    const handleTextareaChange = (event: any) => {
        if (selectedOption === "option2") {
            setRemarksField(event.target.value);
        }
    };

    const handleManualMonthClosingConfirmation = async () => {
        const data: DTOTimeControlManualMonthClosingConfirmationData = {
            remarks: remarksField,
        };
        setIsLoading(true);
        if (selectedOption === "option1") {
            handleSaveMonthClosing(false, data);
        } else {
            const result: string | null = await GetManualMonthClosingConfirmation(employeeId, workCenterId, year, month);
            setIsLoading(false);
            if (result && result !== null) {
                Modal.confirm({
                    title: t("timeControl.manualMonthClosingConfirmation"),
                    content: (
                        <div className="my-4">
                            <p className="leading-6 text-justify" style={{ whiteSpace: 'pre-wrap' }}>{result}</p>
                        </div>
                    ),
                    centered: true,
                    width: 650,
                    footer: () => (
                        <div>
                            <Button key="back" onClick={() => Modal.destroyAll()}>
                                {t("common.cancel")}
                            </Button>
                            <Tooltip placement="top" title={t("timeControl.manualMonthClosingWithoutSavingTimeDesviationTooltip")} color="geekblue">
                                <Button
                                    key="submit"
                                    type="primary"
                                    onClick={() => handleSaveMonthClosing(false, data)}
                                >
                                    {t("timeControl.manualMonthClosingWithoutSavingTimeDesviationButton")}
                                </Button>
                            </Tooltip>
                            <Tooltip placement="top" title={t("timeControl.manualMonthClosingWithSavingTimeDesviationTooltip")} color="geekblue">
                                <Button
                                    key="submit"
                                    type="primary"
                                    onClick={() => handleSaveMonthClosing(true, data)}
                                >
                                    {t("timeControl.manualMonthClosingWithSavingTimeDesviationButton")}
                                </Button>
                            </Tooltip >
                        </div >
                    ),
                });
            }
        }
    };

    const handleSaveMonthClosing = async (withTimeCompensation: boolean, data: DTOTimeControlManualMonthClosingConfirmationData) => {
        setIsLoading(true);
        Modal.destroyAll();
        await SaveManualMonthClosing(employeeId, workCenterId, year, month, withTimeCompensation, data)
            .then((savingResult) => {
                if (savingResult === true) {
                    onOk();
                }
            })
    };

    return (
        <div>
            <div className="col-span-full">
                <label htmlFor="cover-photo" className="block text-xl font-medium leading-6 text-gray-900">
                    {t("timeControl.manualMonthClosing")}: {t(`common.month${month}`)} {year}
                </label>
                <p className="mt-6 mb-4 text-sm italic text-gray-700">
                    {t("timeControl.manualMonthClosingSubtitle")}
                </p>

                <div className="mt-6 space-y-4">
                    {manualMonthClosingData === undefined ? (
                        <Skeleton className="my-8" active paragraph={{ rows: 2 }} />
                    ) : (
                        <dl className="mt-5 grid grid-cols-4 divide-x divide-y-0 divide-gray-200 overflow-hidden rounded-lg bg-zinc-50 shadow">
                            <div className="px-4 py-5">
                                <dt className="text-sm font-normal text-gray-900">{t("timeControl.monthlyExpectedWorkTime")}</dt>
                                <dd className="mt-1 flex items-baseline justify-between md:block lg:flex">
                                    <div className="flex items-baseline text-2xl font-semibold text-indigo-600">
                                        {manualMonthClosingData?.expectedWorkTime}
                                    </div>
                                </dd>
                            </div>
                            <div className="px-4 py-5">
                                <dt className="text-sm font-normal text-gray-900">{t("timeControl.monthlyRealWorkTime")}</dt>
                                <dd className="mt-1 flex items-baseline justify-between md:block lg:flex">
                                    <div className="flex items-baseline text-2xl font-semibold text-indigo-600">
                                        {manualMonthClosingData?.totalComputedWorkTime}
                                    </div>
                                </dd>
                            </div>
                            <div className="px-4 py-5">
                                {manualMonthClosingData?.desviationTime.startsWith('-') ? (
                                    <Tooltip placement="top" title={t("timeControl.monthlyDifferenceTimeNegativeTooltip")} color="green">
                                        <dt className="text-sm font-normal text-gray-900">{t("timeControl.monthlyDifferenceTime")}</dt>
                                        <dd className="mt-1 flex items-baseline justify-between md:block lg:flex">
                                            <div className="flex items-baseline text-2xl font-semibold text-green-500">
                                                {manualMonthClosingData?.desviationTime}
                                            </div>
                                        </dd>
                                    </Tooltip>
                                ) : (
                                    <Tooltip placement="top" title={t("timeControl.monthlyDifferenceTimePositiveTooltip")} color="red">
                                        <dt className="text-sm font-normal text-gray-900">{t("timeControl.monthlyDifferenceTime")}</dt>
                                        <dd className="mt-1 flex items-baseline justify-between md:block lg:flex">
                                            <div className="flex items-baseline text-2xl font-semibold text-red-500">
                                                {manualMonthClosingData?.desviationTime}
                                            </div>
                                        </dd>
                                    </Tooltip>
                                )}
                            </div>
                            <div className="px-4 py-5">
                                <Tooltip placement="top" title={t("timeControl.manualMonthClosingTimeBalanceTooltip")} color="geekblue">
                                    <dt className="text-sm font-normal text-gray-900">{t("employee.timeBalance")}</dt>
                                    <dd className="mt-1 flex items-baseline justify-between md:block lg:flex">
                                        {manualMonthClosingData?.desviationTime.startsWith('-') ? (
                                            <div className="flex items-baseline text-2xl font-semibold text-orange-600">
                                                {manualMonthClosingData?.timeBalance}
                                            </div>
                                        ) : (
                                            <div className="flex items-baseline text-2xl font-semibold text-indigo-600">
                                                {manualMonthClosingData?.timeBalance}
                                            </div>
                                        )}
                                    </dd>
                                </Tooltip>
                            </div>
                        </dl>
                    )}
                </div>

                {manualMonthClosingData?.validationErrorMessage &&
                    <div className="mt-8">
                        <Alert showIcon type="warning" message={manualMonthClosingData?.validationErrorMessage} />
                    </div>
                }

                <div className="mt-8 space-y-4">
                    <label htmlFor="comment" className="block text-base font-medium leading-6 text-gray-900">
                        {t("timeControl.remarks")}
                    </label>
                    <fieldset className="mt-4">
                        <div className="space-y-4">
                            <div className="flex items-center">
                                <input
                                    id="option1"
                                    name="notification-method"
                                    type="radio"
                                    value="option1"
                                    checked={selectedOption === "option1"}
                                    onChange={handleRadioChange}
                                    className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                />
                                <Tooltip placement="top" title={t("timeControl.manualMonthClosingRemarksOption1Tooltip")} color="geekblue">
                                    <label htmlFor="option1" className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                                        {t("timeControl.manualMonthClosingRemarksOption1")}
                                    </label>
                                </Tooltip>
                            </div>
                            <div className="flex items-center">
                                <input
                                    id="option2"
                                    name="notification-method"
                                    type="radio"
                                    value="option2"
                                    checked={selectedOption === "option2"}
                                    onChange={handleRadioChange}
                                    className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                />
                                <label htmlFor="option2" className="ml-3 block text-sm font-medium italic leading-6 text-gray-900">
                                    {t("timeControl.manualMonthClosingRemarksOption2")}
                                </label>
                            </div>
                        </div>
                    </fieldset>
                    <textarea
                        rows={4}
                        name="comment"
                        id="comment"
                        placeholder={t("timeControl.remarksPlaceholder").toString()}
                        className={`block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 ${selectedOption !== "option2" ? "bg-gray-100 text-gray-500 cursor-not-allowed" : "bg-white text-gray-900"}`}
                        defaultValue={''}
                        onChange={handleTextareaChange}
                        disabled={selectedOption !== "option2"}
                    />
                </div>
                <div className="mt-8 flex flex-col sm:flex-row items-center justify-end gap-x-6 gap-y-4 sm:gap-y-0">
                    <button
                        type="button"
                        className="inline-flex w-full justify-center rounded-md bg-gray-200 px-3 py-2 text-sm font-semibold text-gray-600 shadow-sm hover:bg-gray-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-300"
                        onClick={() => onCancel()}
                    >
                        {t("common.cancel")}
                    </button>
                    <button
                        disabled={manualMonthClosingData?.validationErrorMessage !== null}
                        type="button"
                        className={`${manualMonthClosingData?.validationErrorMessage !== null ? "cursor-not-allowed " : ""}inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600`}
                        onClick={() => handleManualMonthClosingConfirmation()}
                    >
                        {t("common.save")}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default TimeControlManualMonthClosing;