import { axiosRRHHEmployeeAPI } from '../axios/AxiosRRHHEmployeeAPI';

export async function GetUserManual(): Promise<void> {
    try {
        const response = await axiosRRHHEmployeeAPI.get("/api/Documentation/erp__user-manual.pdf", {
            responseType: 'blob',
        });

        if (response.status === 200) {
            const url = window.URL.createObjectURL(new Blob([response.data]));

            const contentDisposition = response.headers['content-disposition'];
            let fileName = 'download.pdf';
            if (contentDisposition) {
                const filenameMatch = contentDisposition.match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/);
                if (filenameMatch && filenameMatch[1]) {
                    fileName = filenameMatch[1].replace(/['"]/g, '');
                }
            }

            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);

            link.click();

            window.URL.revokeObjectURL(url);
            link.parentNode?.removeChild(link);
        }
    } catch (error) {
        console.error('Error en la solicitud:', error);
    }
}