import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Employee } from "../../interfaces/employee/Employee";
import { GetCookie } from "../../services/cookie/CookieService";
import { getImagePath } from "../../resources/utils/common";

export default function Home() {
  const { t } = useTranslation();
  const [employee, setEmployee] = useState<Employee | undefined>(undefined);
  
  useEffect(() => {
    setEmployee(JSON.parse(GetCookie("employee") || "{}"));
  }, []);

  return (
    <div className="overflow-hidden rounded-lg bg-white shadow">
      <div className="bg-white p-6">
        <div className="sm:flex sm:items-center sm:justify-between">
          <div className="sm:flex sm:space-x-5">
            <div className="flex-shrink-0">
              <img className="mx-auto h-20 w-20 rounded-full" src={getImagePath("images/default-user-icon.webp")} alt="" />
            </div>
            <div className="mt-4 text-center sm:mt-0 sm:pt-1 sm:text-left">
              <p className="text-sm font-medium text-gray-600">{t("home.welcome")},</p>
              <p className="text-xl font-bold text-gray-900 sm:text-2xl">{employee?.firstName} {employee?.lastName1}</p>
              <p className="text-sm font-medium text-gray-600">{employee?.email}</p>
            </div>
          </div>
          <div className="mt-5 flex justify-center sm:mt-0">
          </div>
        </div>
      </div>
    </div>
  )
}
