import { useState, useEffect, useContext, useRef } from 'react';
import { useTranslation } from "react-i18next";
import { NotificationContext } from '../../../contexts/NotificationContext';
import { UserIcon, LockOpenIcon, CalendarIcon, SquaresPlusIcon } from '@heroicons/react/20/solid'
import Tabs from '../../../components/common/navigation/Tabs';
import { GetEmployee, GetEmployeeDetail, PostEmployee, PutEmployee } from '../../../services/employee/EmployeeService';
import { useNavigate, useParams } from 'react-router-dom';
import { DTOEmployeeInfo } from '../../../interfaces/employee/DTOEmployeeInfo';
import SimpleHeaderWithTwoButtons from '../../../components/common/header/SimpleHeaderWithTwoButtons';
import { DefaultUser, User } from '../../../interfaces/user/User';
import { GetUser } from '../../../services/user/UserService';
import { FieldTypes, FormField } from '../../../components/common/form/FormField';
import { NotificationTypes } from '../../../components/common/Notification';
import * as Yup from 'yup';
import Form from '../../../components/common/form/Form';
import { FormikProps } from 'formik';
import UserPermissions from '../../../components/user/UserPermissions';
import { useSpinner } from '../../../contexts/SpinnerContext';
import AlertModal from '../../../components/common/alert/AlertModal';
import Alert from '../../../components/common/alert/Alert';
import { FormFieldReadOnly } from '../../../components/common/form/FormFieldReadOnly';
import { getFirstTab, userHasPermission } from '../../../resources/utils/common';
import { DefaultEmployee, Employee, GetEmployeeFullName } from '../../../interfaces/employee/Employee';
import UseDepartments from '../../../hooks/company/UseDepartment';
import { Department } from '../../../interfaces/company/Department';
import { UseEmployees } from '../../../hooks/employee/UseEmployee';
import { OptionType, UseYesNoOptions } from '../../../hooks/utils/UseUtils';
import { AnnualCalendar } from '../../../interfaces/common/calendar/AnnualCalendar';
import EmployeeAnnualCalendar from '../../../components/common/calendar/EmployeeAnnualCalendar';
import ModalAdvanced from '../../../components/common/overlay/ModalAdvanced';
import { Cascader, InputNumber, Radio, Select, Skeleton } from 'antd';
import { GetCalendarOptionsIncludingCompanyWorkCenterYear, ImportCalendarToEmployee } from '../../../services/calendar/CalendarService';
import { DTOCalendarOption } from '../../../interfaces/calendar/DTOCalendarOption';
import { Calendar } from '../../../interfaces/calendar/Calendar';

export default function EmployeeDetail() {
  const { t } = useTranslation();
  const { showNotification } = useContext(NotificationContext);
  const { setIsLoading } = useSpinner();
  const [employeeInfo, setEmployeeInfo] = useState<DTOEmployeeInfo>();
  const [employee, setEmployee] = useState<Employee>(DefaultEmployee());
  const [user, setUser] = useState<User>(DefaultUser());
  const [validationErrors, setValidationErrors] = useState<string>("");
  const departments = UseDepartments();
  const employees = UseEmployees();
  const yesNoOptions = UseYesNoOptions();

  const [importModalIsOpen, setImportModalIsOpen] = useState(false);
  const [calendarOptions, setCalendarOptions] = useState<DTOCalendarOption[]>([]);
  const [calendarOptionsIsLoading, setCalendarOptionsIsLoading] = useState(false);
  const [selectedCalendarToImportId, setSelectedCalendarToImportId] = useState<number>(0);
  const [selectedCalendar, setSelectedCalendar] = useState<Calendar | null | undefined>(undefined);

  const [timeBalanceHours, setTimeBalanceHours] = useState(0);
  const [timeBalanceMinutes, setTimeBalanceMinutes] = useState(0);
  const [timeBalanceIsNegative, setTimeBalanceIsNegative] = useState<boolean>(false);

  const navigate = useNavigate();
  const { id } = useParams();

  const tabs = [
    { key: "employees", name: t("employee.generalData"), icon: UserIcon, current: true },
    { key: "user", name: t("employee.user"), icon: LockOpenIcon, current: false, permission: ["roleRRHH"] },
    { key: "calendar", name: t("calendar.calendar"), icon: CalendarIcon, current: false },
  ];
  const sectionKey = "maintenance__employees";
  const [activeTab, setActiveTab] = useState<string>(getFirstTab(tabs, sectionKey));

  const employeeFormRef = useRef<FormikProps<Employee>>();
  const userFormRef = useRef<FormikProps<User>>();

  const handleValidateAndSubmit = async () => {
    if (employeeFormRef.current && userFormRef.current) {
      const employeeValues = await employeeFormRef.current.submitForm();
      const userValues = await userFormRef.current.submitForm();

      const employeeErrors = await employeeFormRef.current.validateForm();
      const userErrors = await userFormRef.current.validateForm();
      const allErrors = { ...employeeErrors, ...userErrors };

      if (Object.keys(allErrors).length === 0) {
        updateEmployee(employeeValues!, userValues!);
      } else {
        showNotification({ color: NotificationTypes.Danger, messageTitle: t("common.errorDataValidation"), messageText: Object.values(allErrors).map(error => `- ${error}`).join('\n') });
      }
    }
  };

  const updateEmployee = async (employee: Employee, user: User) => {
    employee.user = user;
    let timeBalanceInMinutes = timeBalanceHours * 60 + timeBalanceMinutes;
    if (timeBalanceIsNegative) {
      timeBalanceInMinutes = timeBalanceInMinutes - (timeBalanceInMinutes * 2);
    }
    employee.timeBalanceInMinutes = timeBalanceInMinutes;
    setIsLoading(true);
    const result = employee.id === 0 ? await PostEmployee(employee) : await PutEmployee(employee);
    if (result) {
      setIsLoading(false);
      if (typeof result === 'string') {
        setIsLoading(false);
        setValidationErrors(result);
      } else {
        showNotification({ color: NotificationTypes.Success, messageTitle: t("common.updatedOkTitle"), messageText: t("common.updatedOkSubtitle") });
        //navigate("/employees")
      }
    } else {
      setIsLoading(false);
      showNotification({ color: NotificationTypes.Warning, messageTitle: t("common.updatedErrorTitle"), messageText: t("common.updatedErrorSubtitle") });
    }
  };

  const changeTab = (tabName: string) => {
    setActiveTab(tabName);
  };

  useEffect(() => {
    if (Number(id) > 0) {
      fetchEmployee();
    }
  }, []);

  useEffect(() => {
    if (employeeInfo?.userId) {
      fetchUser(employeeInfo.userId);
    }
  }, [employeeInfo]);

  const fetchEmployee = async () => {
    const resultEmployeeInfo = await GetEmployeeDetail(Number(id));
    if (resultEmployeeInfo) {
      setEmployeeInfo(resultEmployeeInfo);
    }
    const resultEmployee = await GetEmployee(Number(id));
    if (resultEmployee) {
      setEmployee(resultEmployee);
      setSelectedCalendar(resultEmployee.calendars?.find(calendar => calendar.year === new Date().getFullYear()) || resultEmployee.calendars?.at(0) || null);

      let timeBalance: number = resultEmployee.timeBalanceInMinutes < 0 ? resultEmployee.timeBalanceInMinutes - (resultEmployee.timeBalanceInMinutes * 2) : resultEmployee.timeBalanceInMinutes;
      const initialMinutes = timeBalance;
      const initialHours = Math.floor(initialMinutes / 60);
      const initialMin = initialMinutes % 60;
      setTimeBalanceHours(initialHours);
      setTimeBalanceMinutes(initialMin);
      setTimeBalanceIsNegative(resultEmployee.timeBalanceInMinutes < 0);
    }
    setIsLoading(false);
  };

  const fetchUser = async (userId: number) => {
    const result = await GetUser(userId);
    if (result) {
      setUser(result);
    }
    setIsLoading(false);
  };

  const userValidationSchema = Yup.object({
    username: Yup.string().required('El usuario es obligatorio'),
    password: Yup.string().required('La contraseña es obligatoria'),
  });

  const handleCalendarComponentChange = (annualCalendar: AnnualCalendar | null) => {
    if (annualCalendar) {
      setEmployee(prevEmployee => {
        const updatedCalendars = prevEmployee.calendars?.map(calendar => {
          if (calendar.year === selectedCalendar?.year) {
            return { ...calendar, annualCalendar: annualCalendar };
          }
          return calendar;
        });

        return { ...prevEmployee, calendars: updatedCalendars };
      });
    }
  };

  const handleImportCalendarChange = (value: (string | number)[]) => {
    if (value) {
      setSelectedCalendarToImportId(Number(value[value.length - 1]));
    }
  };

  const importCalendarToEmployee = async () => {
    if (selectedCalendarToImportId > 0) {
      setIsLoading(true);
      await ImportCalendarToEmployee(Number(id), selectedCalendarToImportId).then((updatedCalendar) => {
        if (updatedCalendar) {
          setImportModalIsOpen(false);
          showNotification({ color: NotificationTypes.Success, messageTitle: t("calendar.importSuccessfulTitle"), messageText: t("calendar.importSuccessfulSubtitle") });
          setEmployee(prevEmployee => {
            const updatedCalendars = prevEmployee.calendars?.map(calendar => {
              if (calendar.id === updatedCalendar.id) {
                return {
                  ...calendar,
                  annualCalendar: updatedCalendar.annualCalendar,
                  remarks: updatedCalendar.remarks
                };
              }
              return calendar;
            });

            return { ...prevEmployee, calendars: updatedCalendars };
          });
        }
      })
      setIsLoading(false);
    }
  };

  return (
    <div className="px-2 sm:px-4">
      <SimpleHeaderWithTwoButtons
        title={employeeInfo
          ? `${t("employee.titleDetails")}: ${employeeInfo.documentNumber} - ${employeeInfo.firstName} ${employeeInfo.lastName1}`
          : t("employee.newEmployee")}
        textSize="text-3xl"
        buttonText1={t("common.goBack")}
        buttonText2={t("common.save")}
        button1Click={() => navigate("/employees")}
        button2Click={handleValidateAndSubmit}
      />
      <div className="mt-4">
        <Tabs setTab={changeTab} activeTab={activeTab} tabs={tabs} section={sectionKey} />
        {tabs.map(tab => (
          <div key={tab.key} className={`mt-8 ${activeTab === tab.key && userHasPermission(tab, sectionKey) ? '' : ' hidden'}`}>
            {tab.key === "employees" && (
              <>
                <AlertModal title={t("common.errorDataValidation")} text={t(validationErrors)} type="warning" isOpen={validationErrors !== ""} onClose={() => setValidationErrors("")} />
                <Form initialValues={employee} ref={employeeFormRef}>
                  <div className="mb-7">
                    <Alert type="info" text={t("employee.personalDataSectionSubtitle")} />
                  </div>
                  <div className="space-y-10">
                    <div className="border-b border-gray-900/10 pb-12">
                      <h2 className="text-base font-semibold leading-7 text-gray-900">{t("employee.personalData")}</h2>
                      <div className="mt-4 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                        <FormFieldReadOnly colspan={1} label={t("employee.documentType")} value={employeeInfo?.documentType} />
                        <FormFieldReadOnly colspan={1} label={t("employee.documentNumber")} value={employeeInfo?.documentNumber} />
                      </div>
                      <div className="mt-4 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                        <FormFieldReadOnly colspan={1} label={t("employee.firstName")} value={employeeInfo?.firstName} />
                        <FormFieldReadOnly colspan={1} label={t("employee.lastName1")} value={employeeInfo?.lastName1} />
                        <FormFieldReadOnly colspan={1} label={t("employee.lastName2")} value={employeeInfo?.lastName2} />
                        <FormFieldReadOnly colspan={2} label={t("employee.email")} value={employeeInfo?.email} />
                      </div>
                    </div>
                    <div className="border-b border-gray-900/10 pb-12">
                      <h2 className="text-base font-semibold leading-7 text-gray-900">{t("employee.currentStatus")}</h2>
                      <div className="mt-4 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                        <FormField colspan={1} label={t("employee.status")} name="status">
                          <div className="flex items-center justify-start gap-x-2 h-full">
                            <div className={`${employeeInfo?.status ? "text-green-400 bg-green-400/10" : "text-rose-400 bg-rose-400/10"} flex-none rounded-full p-1`}>
                              <div className="h-1.5 w-1.5 rounded-full bg-current" />
                            </div>
                            <div className="block">{employeeInfo?.status ? t("common.yes") : t("common.no")}</div>
                          </div>
                        </FormField>
                        <FormFieldReadOnly colspan={2} label={t("employee.company")} value={employeeInfo?.company} />
                        <FormFieldReadOnly colspan={2} label={t("employee.workCenter")} value={employeeInfo?.workCenter} />
                        <FormFieldReadOnly colspan={1} label={t("employee.job")} value={employeeInfo?.job} />
                      </div>
                      <div className="mt-4 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                        <FormField
                          colspan={2}
                          label={t("employee.department")}
                          name="departmentId"
                          customType={FieldTypes.Select}
                          options={[{ value: "", label: t("common.noAssigned") }].concat(departments.map((department: Department) => ({ value: department.id.toString(), label: department.name })))}
                        />
                        <FormField
                          colspan={2}
                          label={t("employee.timeControlResponsible")}
                          name="timeControlResponsibleId"
                          customType={FieldTypes.SelectWithFinder}
                          options={[{ value: "", label: t("common.noAssigned") }].concat(employees.map((employee: Employee) => ({ value: employee.id.toString(), label: GetEmployeeFullName(employee) })))}
                          tooltip={t("employee.timeControlResponsibleTooltip").toString()}
                        />
                        <FormField
                          colspan={1}
                          label={t("employee.isExemptFromTimeControl")}
                          name="isExemptFromTimeControl"
                          customType={FieldTypes.Select}
                          options={yesNoOptions.map((yesNoOption: OptionType) => ({ value: yesNoOption.value, label: yesNoOption.label }))}
                          tooltip={t("employee.isExemptFromTimeControlTooltip").toString()}
                        />
                      </div>
                      <div className="mt-4 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                        <FormField
                          colspan={4}
                          label={t("employee.timeBalanceInMinutes")}
                          name="timeBalanceInMinutes"
                          tooltip={t("employee.timeBalanceInMinutesTooltip").toString()}
                        >
                          <div className="flex space-x-3 items-center">
                            <InputNumber
                              style={{ height: '36px' }}
                              className="form-field-timepicker"
                              min={0}
                              max={99}
                              value={timeBalanceHours}
                              onChange={(value) => setTimeBalanceHours(value ?? 0)}
                            />
                            <InputNumber
                              style={{ height: '36px' }}
                              className="form-field-timepicker"
                              min={0}
                              max={59}
                              value={timeBalanceMinutes}
                              onChange={(value) => setTimeBalanceMinutes(value ?? 0)}
                            />
                            <Radio.Group name="radiogroup" value={timeBalanceIsNegative}>
                              <Radio value={true} onChange={() => setTimeBalanceIsNegative(true)}>{t("employee.timeBalanceInMinutesNegative")}</Radio>
                              <Radio value={false} onChange={() => setTimeBalanceIsNegative(false)}>{t("employee.timeBalanceInMinutesPositive")}</Radio>
                            </Radio.Group>
                          </div>
                        </FormField>
                      </div>
                    </div>
                    <div className="border-b border-gray-900/10 pb-12">
                      <h2 className="text-base font-semibold leading-7 text-gray-900">{t("employeeVariation.title")}</h2>
                      <p className="mt-3 mb-4 text-sm italic text-gray-700">
                        {t("employeeVariation.subtitle")}
                      </p>
                      {employeeInfo ? (
                        <table className="min-w-full divide-y divide-gray-300">
                          <thead>
                            <tr>
                              <th scope="col" className="py-3 pr-3 text-center text-xs font-medium uppercase tracking-wide text-gray-500 sm:pl-0">{t("employeeVariation.start")}</th>
                              <th scope="col" className="py-3 pr-3 text-center text-xs font-medium uppercase tracking-wide text-gray-500 sm:pl-0">{t("employeeVariation.end")}</th>
                              <th scope="col" className="py-3 pr-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500 sm:pl-0">{t("employeeVariation.company")}</th>
                              <th scope="col" className="py-3 pr-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500 sm:pl-0">{t("employeeVariation.workCenter")}</th>
                              <th scope="col" className="py-3 pr-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500 sm:pl-0">{t("employeeVariation.job")}</th>
                              <th scope="col" className="py-3 pr-3 text-center text-xs font-medium uppercase tracking-wide text-gray-500 sm:pl-0">{t("employeeVariation.conventionCode")}</th>
                              <th scope="col" className="py-3 pr-3 text-center text-xs font-medium uppercase tracking-wide text-gray-500 sm:pl-0">{t("employeeVariation.conventionAnnualHours")}</th>
                              <th scope="col" className="py-3 pr-3 text-center text-xs font-medium uppercase tracking-wide text-gray-500 sm:pl-0">{t("employeeVariation.conventionWeeklyHours")}</th>
                              <th scope="col" className="py-3 pr-3 text-center text-xs font-medium uppercase tracking-wide text-gray-500 sm:pl-0">{t("employeeVariation.workingDayPercentage")}</th>
                            </tr>
                          </thead>
                          <tbody className="divide-y divide-gray-200 bg-white">
                            {employeeInfo?.variations && employeeInfo?.variations.map((variation, index) => (
                              <tr key={index}>
                                <td className="whitespace-nowrap py-4 text-sm text-gray-500 text-center">{variation.start}</td>
                                <td className="whitespace-nowrap py-4 text-sm text-gray-500 text-center">{variation.end}</td>
                                <td className="whitespace-nowrap py-4 text-sm text-gray-500">{variation.company}</td>
                                <td className="whitespace-nowrap py-4 text-sm text-gray-500">{variation.workCenter}</td>
                                <td className="whitespace-nowrap py-4 text-sm text-gray-500">{variation.job}</td>
                                <td className="whitespace-nowrap py-4 text-sm text-gray-500 text-center">{variation.conventionCode}</td>
                                <td className="whitespace-nowrap py-4 text-sm text-gray-500 text-center">{variation.conventionAnnualHours}</td>
                                <td className="whitespace-nowrap py-4 text-sm text-gray-500 text-center">{variation.conventionWeeklyHours}</td>
                                <td className="whitespace-nowrap py-4 text-sm text-gray-500 text-center">{variation.workingDayPercentage}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      ) : (
                        <Skeleton className="mt-8 -mb-4" active paragraph={{ rows: 2 }} />
                      )}
                    </div>
                  </div>
                </Form>
              </>
            )}
            {tab.key === "user" && (
              <Form initialValues={user} validationSchema={userValidationSchema} ref={userFormRef}>
                <div className="space-y-12">
                  <div className="border-b border-gray-900/10 pb-12">
                    <h2 className="text-base font-semibold leading-7 text-gray-900">{t("user.dataSectionTitle")}</h2>
                    <p className="mt-2 text-sm italic text-gray-700">
                      {t("user.dataSectionSubtitle")}
                    </p>
                    <div className="mt-4 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                      <FormField colspan={1} label={t("user.username")} name="username" />
                      <FormField colspan={1} label={t("user.password")} name="password" />
                      <FormField colspan={1} label={t("user.isActive")} name="isActive" customType={FieldTypes.Select}
                        options={[
                          { value: 'true', label: t("common.yes") },
                          { value: 'false', label: t("common.no") }
                        ]}
                      />
                    </div>
                  </div>
                  <div className="mt-4">
                    <h2 className="text-base font-semibold leading-7 text-gray-900">{t("user.permissionsSectionTitle")}</h2>
                    <p className="mt-2 mb-4 text-sm italic text-gray-700">
                      {t("user.permissionsSectionSubtitle")}
                    </p>
                    <FormField colspan={4} label="" name="permissions">
                      <UserPermissions permissions={user.permissions} />
                    </FormField>
                  </div>
                </div>
              </Form>
            )}
            {tab.key === "calendar" && (
              <div className="space-y-12">
                <div className="pb-12">
                  <h2 className="text-base font-semibold leading-7 text-gray-900">{t("calendar.calendar")}</h2>
                  <div className="flex justify-between items-center mb-6">
                    <div className="flex items-center space-x-4">
                      <h3 className="text-xl font-semibold leading-6 text-gray-900">
                        {t("common.year")}
                      </h3>
                      <Select
                        options={employee.calendars?.length ?? 0 > 0 ? employee.calendars?.map(calendar => ({ value: calendar.id, label: calendar.year })) : [{ value: 0, label: new Date().getFullYear() }]}
                        placeholder=""
                        size="large"
                        onChange={async (calendarId: number) => {
                          setSelectedCalendar(employee.calendars?.find(calendar => calendar.id === calendarId) || null);
                        }}
                        value={selectedCalendar?.year || employee.calendars?.find(calendar => calendar.year === new Date().getFullYear())?.id || employee.calendars?.at(0)?.id || null}
                      />
                    </div>
                    <button
                      type="button"
                      className="mt-3 mb-6 inline-flex items-center gap-x-2 rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                      onClick={() => setImportModalIsOpen(true)}
                    >
                      <SquaresPlusIcon className="h-5 w-5" aria-hidden="true" />
                      {t("calendar.import")}
                    </button>
                    <ModalAdvanced title={t("calendar.import")} subtitle={t("calendar.importSubtitle")} isOpen={importModalIsOpen} onClose={() => setImportModalIsOpen(false)} okText={t("calendar.import")} onOk={importCalendarToEmployee}>
                      <div>
                        <label htmlFor="timeLogType" className="mb-2 block text-sm font-medium leading-6 text-gray-900">
                          {t("calendar.importHelp")}
                        </label>
                        <Cascader
                          style={{ width: '100%', height: '36px' }}
                          onChange={handleImportCalendarChange}
                          placeholder={t("calendar.importCalendarPlaceholder")}
                          onDropdownVisibleChange={async open => {
                            if (open && calendarOptions.length === 0) {
                              setCalendarOptionsIsLoading(true);
                              await GetCalendarOptionsIncludingCompanyWorkCenterYear().then((options) => {
                                setCalendarOptions(options);
                              });
                              setCalendarOptionsIsLoading(false);
                            }
                          }}
                          loading={calendarOptionsIsLoading}
                          options={calendarOptions}
                        />
                      </div>
                    </ModalAdvanced>
                  </div>
                  <EmployeeAnnualCalendar
                    calendar={selectedCalendar && employee.calendars?.find(calendar => calendar.id === selectedCalendar.id) || employee.calendars?.find(calendar => calendar.year === new Date().getFullYear()) || employee.calendars?.at(0) || null}
                    annualCalendar={selectedCalendar === undefined ? undefined : selectedCalendar && employee.calendars?.find(calendar => calendar.id === selectedCalendar.id)?.annualCalendar || employee.calendars?.find(calendar => calendar.year === new Date().getFullYear())?.annualCalendar || employee.calendars?.at(0)?.annualCalendar || null}
                    onCalendarChange={handleCalendarComponentChange}
                  />
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  )
}
