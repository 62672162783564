import { Fragment, useContext, useState, useEffect } from "react";
import { Transition } from "@headlessui/react";
import { CheckCircleIcon, ExclamationTriangleIcon, ExclamationCircleIcon } from "@heroicons/react/24/outline";
import { XMarkIcon } from "@heroicons/react/20/solid";
import { NotificationContext } from "../../contexts/NotificationContext";

export enum NotificationTypes {
  Success = "green",
  Warning = "orange",
  Danger = "red",
}

export default function Notification() {
  const { state } = useContext(NotificationContext);
  const [show, setShow] = useState(state.visible);

  useEffect(() => {
    setShow(state.visible);
  }, [state.visible]);

  useEffect(() => {
    if (show) {
      const timer = setTimeout(() => {
        setShow(false);
      }, 5000);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [show, setShow]);

  return (
    <>
      <div aria-live="assertive" className="pointer-events-none fixed z-50 inset-0 flex items-end px-4 py-6 sm:items-start sm:p-6">
        <div className="flex w-full flex-col items-center space-y-4 sm:items-end">
          <Transition
            show={show}
            as={Fragment}
            enter="transform ease-out duration-300 transition"
            enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            enterTo="translate-y-0 opacity-100 sm:translate-x-0"
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
              <div className="p-4">
                <div className="flex items-start">
                  <div className="flex-shrink-0">
                    {state.color === "green" ? (
                      <CheckCircleIcon className="h-6 w-6 text-green-400" aria-hidden="true" />
                    ) : state.color === "orange" ? (
                      <ExclamationTriangleIcon className="h-6 w-6 text-orange-400" aria-hidden="true" />
                    ) : state.color === "red" ? (
                      <ExclamationCircleIcon className="h-6 w-6 text-red-400" aria-hidden="true" />
                    ) : null}
                  </div>
                  <div className="ml-3 w-0 flex-1 pt-0.5">
                    <p className="text-sm font-medium text-gray-900">
                      {state.messageTitle}
                    </p>
                    <p className="mt-1 text-sm text-gray-500" style={{ whiteSpace: "pre-line" }}>
                      {state.messageText}
                    </p>
                  </div>
                  <div className="ml-4 flex flex-shrink-0">
                    <button
                      type="button"
                      className="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      onClick={() => {
                        setShow(false);
                      }}
                    >
                      <span className="sr-only">Cerrar</span>
                      <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Transition>
        </div>
      </div>
    </>
  );
}