import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AnnualCalendar } from '../../../interfaces/common/calendar/AnnualCalendar';
import { Calendar } from '../../../interfaces/calendar/Calendar';
import { Month } from '../../../interfaces/common/calendar/Month';
import { Day } from '../../../interfaces/common/calendar/Day';
import { CalendarDayType } from '../../../resources/utils/enums';
import ModalAdvanced from '../overlay/ModalAdvanced';
import { Alert, Collapse, DatePicker, Divider, Popover, Select, Skeleton, Tooltip } from 'antd';
import { RangePickerProps } from 'antd/es/date-picker/generatePicker';
import locale from 'antd/es/date-picker/locale/es_ES';
import 'dayjs/locale/es';
import dayjs, { Dayjs } from 'dayjs';
import BarChart from '../statistics/BarChart';
import { DTOBarChartItem } from '../../../interfaces/common/statistics/DTOBarChartItem';

const { RangePicker } = DatePicker;

interface EmployeeAnnualCalendarProps {
    calendar: Calendar | null;
    annualCalendar: AnnualCalendar | null | undefined;
    onCalendarChange: (calendar: AnnualCalendar | null) => void;
}

const EmployeeAnnualCalendar: React.FC<EmployeeAnnualCalendarProps> = ({ calendar, annualCalendar, onCalendarChange }) => {
    const { t } = useTranslation();
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [selectedTimeLogType, setSelectedTimeLogType] = useState<CalendarDayType>(CalendarDayType.WorkingDay);
    const [selectedRangeValue, setSelectedRangeValue] = useState<[string, string] | null>(null);
    const [barChartData, setBarChartData] = useState<DTOBarChartItem[]>([]);

    const toggleWorkingDay = (clickedDay: Day) => {
        setSelectedRangeValue([dayjs(clickedDay.date).format("DD-MM-YYYY"), dayjs(clickedDay.date).format("DD-MM-YYYY")]);
        setSelectedTimeLogType(clickedDay.timeLogTypeId);
        setModalIsOpen(true);
    };

    const applyChanges = () => {
        if (!annualCalendar || !selectedTimeLogType || !selectedRangeValue) return;

        const startDate = dayjs(selectedRangeValue[0], "DD-MM-YYYY").startOf('day');
        const endDate = dayjs(selectedRangeValue[1], "DD-MM-YYYY").startOf('day');

        const newAnnualCalendar = { ...annualCalendar };
        newAnnualCalendar.months = annualCalendar.months.map(month => ({
            ...month,
            days: month.days.map(day => {
                const dayDate = dayjs(day.date, "YYYY-MM-DD").startOf('day');
                if (dayDate.isSame(startDate) || dayDate.isSame(endDate) || (dayDate.isAfter(startDate) && dayDate.isBefore(endDate))) {
                    if (day.timeLogTypeId !== CalendarDayType.NonWorkingDay && day.timeLogTypeId !== CalendarDayType.PublicHoliday) {
                        return { ...day, timeLogTypeId: selectedTimeLogType };
                    }
                }
                return day;
            })
        }));

        onCalendarChange(newAnnualCalendar);
        setModalIsOpen(false);
    };

    const revertToWorkingDay = (date: string) => {
        if (!annualCalendar || !date) return;

        const newAnnualCalendar = { ...annualCalendar };
        newAnnualCalendar.months = annualCalendar.months.map(month => ({
            ...month,
            days: month.days.map(day => {
                const dayDate = dayjs(day.date, "YYYY-MM-DD").startOf('day');
                if (dayDate.isSame(date) || dayDate.isSame(date) || (dayDate.isAfter(date) && dayDate.isBefore(date))) {
                    return { ...day, timeLogTypeId: CalendarDayType.WorkingDay };
                }
                return day;
            })
        }));

        onCalendarChange(newAnnualCalendar);
        setModalIsOpen(false);
    };

    function getTimeLogTypeNameById(timeLogTypeId: number) {
        switch (timeLogTypeId) {
            case CalendarDayType.WorkingDay:
                return "";
            case CalendarDayType.NonWorkingDay:
                return t("timeLogType.nonWorkingDay");
            case CalendarDayType.PublicHoliday:
                return t("timeLogType.publicHoliday");
            case CalendarDayType.MedicalSickLeave:
                return t("timeLogType.medicalSickLeave");
            case CalendarDayType.Causa:
                return t("timeLogType.causa");
            case CalendarDayType.MedicalVisit:
                return t("timeLogType.medicalVisit");
            case CalendarDayType.Holiday:
                return t("timeLogType.holiday");
            case CalendarDayType.PaternityLeave:
                return t("timeLogType.paternityLeave");
            case CalendarDayType.MarriagePermit:
                return t("timeLogType.marriagePermit");
            case CalendarDayType.GreaterCause:
                return t("timeLogType.greaterCause");
            case CalendarDayType.ClockInWithoutApp:
                return t("timeLogType.clockInWithoutApp");
            case CalendarDayType.WithoutClockIn:
                return t("timeLogType.withoutClockIn");
            case CalendarDayType.GuardInNonWorkingDay:
                return t("timeLogType.guardInNonWorkingDay");
            case CalendarDayType.GuardInPublicHolidayDay:
                return t("timeLogType.guardInPublicHolidayDay");
            case CalendarDayType.FamilyHospitalization:
                return t("timeLogType.familyHospitalization");
            case CalendarDayType.SigningOnSheetOfPaper:
                return t("timeLogType.signingOnSheetOfPaper");
            case CalendarDayType.HolidayPreviousYear:
                return t("timeLogType.holidayPreviousYear");
            case CalendarDayType.WorkLeave:
                return t("timeLogType.workLeave");
            case CalendarDayType.FamilyDeath:
                return t("timeLogType.familyDeath");
            default:
                return "";
        }
    }

    function getDayTypeClass(day: Day) {
        if (!day.isCurrentMonth) {
            return 'bg-gray-500 text-gray-50 hover:bg-gray-500 cursor-default';
        }

        switch (day.timeLogTypeId) {
            case CalendarDayType.WorkingDay:
                return 'bg-white text-gray-900';
            case CalendarDayType.NonWorkingDay:
                return 'bg-orange-300 text-orange-900 font-semibold hover:bg-orange-400';
            case CalendarDayType.PublicHoliday:
                return 'bg-red-400 text-red-900 font-semibold hover:bg-red-500';
            case CalendarDayType.MedicalSickLeave:
                return 'bg-blue-200 text-blue-900 hover:bg-blue-300 bg-medical-sick-leave';
            case CalendarDayType.Causa:
                return 'bg-blue-200 text-blue-900 hover:bg-blue-300 bg-medical-sick-leave';
            case CalendarDayType.MedicalVisit:
                return 'bg-blue-200 text-blue-900 hover:bg-blue-300 bg-medical-sick-leave';
            case CalendarDayType.Holiday:
                return 'bg-green-200 text-green-900 hover:bg-green-300 bg-holiday';
            case CalendarDayType.PaternityLeave:
                return 'bg-purple-200 text-purple-900 hover:bg-purple-300 bg-paternity-leave';
            case CalendarDayType.MarriagePermit:
                return 'bg-pink-200 text-pink-900 hover:bg-pink-300 bg-marriage-permit';
            case CalendarDayType.GreaterCause:
                return 'bg-yellow-200 text-yellow-900 hover:bg-yellow-300 bg-greater-cause';
            case CalendarDayType.ClockInWithoutApp:
                return 'bg-black-200 text-black-900 hover:bg-black-300 bg-clock-in-without-app';
            case CalendarDayType.WithoutClockIn:
                return 'bg-black-200 text-black-900 hover:bg-black-300 bg-without-clock-in';
            case CalendarDayType.GuardInNonWorkingDay:
                return 'bg-sky-200 text-sky-900 hover:bg-sky-300 bg-guard';
            case CalendarDayType.GuardInPublicHolidayDay:
                return 'bg-sky-200 text-sky-900 hover:bg-sky-300 bg-guard';
            case CalendarDayType.FamilyHospitalization:
                return 'bg-sky-200 text-sky-900 hover:bg-sky-300 bg-family-hospitalization';
            case CalendarDayType.SigningOnSheetOfPaper:
                return 'bg-sky-200 text-sky-900 hover:bg-sky-300 bg-signing-on-sheet-of-paper';
            case CalendarDayType.HolidayPreviousYear:
                return 'bg-sky-200 text-sky-900 hover:bg-sky-300 bg-holiday-previous-year';
            case CalendarDayType.WorkLeave:
                return 'bg-sky-200 text-sky-900 hover:bg-sky-300 bg-work-leave';
            case CalendarDayType.FamilyDeath:
                return 'bg-gray-200 text-gray-900 hover:bg-gray-300 bg-family-death';
            default:
                return '';
        }
    }

    //"blue" | "purple" | "cyan" | "green" | "magenta" | "pink" | "red" | "orange" | "yellow" | "volcano" | "geekblue" | "lime" | "gold"
    function getTooltipBackColor(day: Day) {
        switch (day.timeLogTypeId) {
            case CalendarDayType.WorkingDay:
                return '';
            case CalendarDayType.NonWorkingDay:
                return 'orange';
            case CalendarDayType.PublicHoliday:
                return 'red';
            case CalendarDayType.MedicalSickLeave:
                return 'geekblue';
            case CalendarDayType.Causa:
                return 'geekblue';
            case CalendarDayType.MedicalVisit:
                return 'geekblue';
            case CalendarDayType.Holiday:
                return 'green';
            case CalendarDayType.PaternityLeave:
                return 'purple';
            case CalendarDayType.MarriagePermit:
                return 'pink';
            case CalendarDayType.GreaterCause:
                return 'yellow';
            case CalendarDayType.ClockInWithoutApp:
                return 'cyan';
            case CalendarDayType.WithoutClockIn:
                return 'volcano';
            case CalendarDayType.GuardInNonWorkingDay:
                return 'geekblue';
            case CalendarDayType.GuardInPublicHolidayDay:
                return 'geekblue';
            case CalendarDayType.FamilyHospitalization:
                return 'geekblue';
            case CalendarDayType.SigningOnSheetOfPaper:
                return 'geekblue';
            case CalendarDayType.HolidayPreviousYear:
                return 'geekblue';
            case CalendarDayType.WorkLeave:
                return 'geekblue';
            case CalendarDayType.FamilyDeath:
                return 'geekblue';
            default:
                return '';
        }
    }

    function getTooltipTitle(day: Day) {
        if (!day.isCurrentMonth) return "";

        return getTimeLogTypeNameById(day.timeLogTypeId);
    }

    function classNames(...classes: any[]) {
        return classes.filter(Boolean).join(' ');
    }

    const handleSelectedTimeLogTypeChange = (calendarDayType: CalendarDayType) => {
        setSelectedTimeLogType(calendarDayType);
    };

    const handleRangeChange: RangePickerProps<Dayjs>['onChange'] = (dates, dateStrings) => {
        if (dates) {
            const [start, end] = dates;
            if (start && end) {
                setSelectedRangeValue([start.format("DD-MM-YYYY"), end.format("DD-MM-YYYY")]);
            } else {
                setSelectedRangeValue(null);
            }
        } else {
            setSelectedRangeValue(null);
        }
    };

    useEffect(() => {
        if (!annualCalendar) {
            setBarChartData([]);
            return;
        }

        let counts: Record<CalendarDayType, number> = {} as Record<CalendarDayType, number>;

        annualCalendar.months.forEach(month => {
            month.days.forEach(day => {
                if (day.timeLogTypeId !== CalendarDayType.WorkingDay && day.timeLogTypeId !== CalendarDayType.NonWorkingDay && day.isCurrentMonth) {
                    counts[day.timeLogTypeId] = (counts[day.timeLogTypeId] || 0) + 1;
                }
            });
        });

        const newBarChartData: DTOBarChartItem[] = Object.entries(counts).map(([key, value], index) => ({
            category: getTimeLogTypeNameById(Number(key)),
            values: [{
                key: getTimeLogTypeNameById(Number(key)),
                value,
                label: getTimeLogTypeNameById(Number(key)),
            }]
        }));

        setBarChartData(newBarChartData);
    }, [annualCalendar, t]);

    return (
        <div>
            <div className="bg-white">
                {annualCalendar ? (
                    <>
                        <div className="mb-3">
                            <Divider orientation="left" >{t("calendar.statistics")}</Divider>
                            <Collapse
                                className="italic"
                                items={[{
                                    key: '1',
                                    label: t("timeControl.statisticsCollapseTitle"),
                                    children: (
                                        <div>
                                            <Alert className="mb-3" message={t("calendar.timeOffsStatistics")} type="info" />
                                            <BarChart
                                                data={barChartData}
                                                size={400}
                                                legend={true}
                                            />
                                        </div>
                                    )
                                }]}
                            />
                        </div>
                        {calendar?.remarks &&
                            <h2 className="mb-10 text-sm font-medium text-gray-900 italic">
                                <Alert message={t("calendar.remarksForEmployee", { remarks: calendar.remarks })} type="info" showIcon />
                            </h2>
                        }
                        <div className="mx-auto grid max-w-3xl grid-cols-1 gap-x-8 gap-y-16 px-4 pt-2 pb-14 sm:grid-cols-2 sm:px-6 xl:max-w-none xl:grid-cols-3 xl:px-8 2xl:grid-cols-4">
                            {annualCalendar.months.map((month: Month) => (
                                <section key={month.number} className="text-center">
                                    <h2 className="text-sm font-semibold text-gray-900">{t(`common.month${month.number}`)}</h2>
                                    <div className="mt-6 grid grid-cols-7 text-xs leading-6 text-gray-500">
                                        <div>{t("common.week1")}</div>
                                        <div>{t("common.week2")}</div>
                                        <div>{t("common.week3")}</div>
                                        <div>{t("common.week4")}</div>
                                        <div>{t("common.week5")}</div>
                                        <div>{t("common.week6")}</div>
                                        <div>{t("common.week7")}</div>
                                    </div>
                                    <div className="isolate mt-2 grid grid-cols-7 gap-px rounded-lg bg-gray-200 text-sm shadow ring-1 ring-gray-200">
                                        {month.days.map((day: Day, dayIdx: number) => (
                                            (day.timeLogTypeId === CalendarDayType.NonWorkingDay || day.timeLogTypeId === CalendarDayType.PublicHoliday) && day.isCurrentMonth ? (
                                                <Popover
                                                    key={day.date}
                                                    placement="bottom"
                                                    content={
                                                        <div className="flex justify-start my-3">
                                                            <button
                                                                className="rounded-md bg-indigo-50 px-2 py-2.5 text-sm font-semibold text-indigo-600 shadow-sm hover:bg-indigo-100"
                                                                onClick={() => revertToWorkingDay(day.date)}
                                                            >
                                                                {t("calendar.revertToWorkingDay", { "day": dayjs(day.date).format("DD"), "month": t(`common.month${dayjs(day.date).format("M")}`) })}
                                                            </button>
                                                        </div>
                                                    }
                                                >
                                                    <button
                                                        key={day.date}
                                                        type="button"
                                                        className={classNames(
                                                            getDayTypeClass(day),
                                                            dayIdx === 0 && 'rounded-tl-lg',
                                                            dayIdx === 6 && 'rounded-tr-lg',
                                                            dayIdx === month.days.length - 7 && 'rounded-bl-lg',
                                                            dayIdx === month.days.length - 1 && 'rounded-br-lg',
                                                            'py-1.5 hover:bg-gray-100 focus:z-10 cursor-default'
                                                        )}
                                                    >
                                                        <time dateTime={day.date} className="mx-auto flex h-7 w-7 items-center justify-center rounded-full">
                                                            {day?.date?.split('-').pop()?.replace(/^0/, '')}
                                                        </time>
                                                    </button>
                                                </Popover>
                                            ) : (
                                                <Tooltip key={day.date} placement="topLeft" title={classNames(getTooltipTitle(day))} color={classNames(getTooltipBackColor(day))}>
                                                    <button
                                                        key={day.date}
                                                        type="button"
                                                        className={classNames(
                                                            getDayTypeClass(day),
                                                            dayIdx === 0 && 'rounded-tl-lg',
                                                            dayIdx === 6 && 'rounded-tr-lg',
                                                            dayIdx === month.days.length - 7 && 'rounded-bl-lg',
                                                            dayIdx === month.days.length - 1 && 'rounded-br-lg',
                                                            'py-1.5 hover:bg-gray-100 focus:z-10'
                                                        )}
                                                        onClick={() => toggleWorkingDay(day)}
                                                    >
                                                        <time dateTime={day.date} className="mx-auto flex h-7 w-7 items-center justify-center rounded-full">
                                                            {day?.date?.split('-').pop()?.replace(/^0/, '')}
                                                        </time>
                                                    </button>
                                                </Tooltip>
                                            )
                                        ))}
                                    </div>
                                </section>
                            ))}
                        </div>
                    </>
                ) : annualCalendar === null ? (
                    <p className="px-6 py-4 text-base text-center italic">{t("calendar.noCalendarForEmployee")}</p>
                ) : (
                    <Skeleton className="mt-8" active paragraph={{ rows: 14 }} />
                )}
            </div>

            <ModalAdvanced title={t("calendar.timeOffConfiguration")} isOpen={modalIsOpen} onClose={() => setModalIsOpen(false)} onOk={applyChanges}>
                <div>
                    <label htmlFor="timeLogType" className="mb-2 block text-sm font-medium leading-6 text-gray-900">
                        {t("calendar.timeOffType")}
                    </label>
                    <Select
                        style={{ width: '100%', height: '36px' }}
                        value={selectedTimeLogType}
                        onChange={handleSelectedTimeLogTypeChange}
                        placeholder=""
                        optionFilterProp="children"
                    >
                        <Select.Option key={CalendarDayType.WorkingDay} value={CalendarDayType.WorkingDay}>{t("timeLogType.workingDay")}</Select.Option>
                        <Select.Option key={CalendarDayType.NonWorkingDay} value={CalendarDayType.NonWorkingDay}>{t("timeLogType.nonWorkingDay")}</Select.Option>
                        <Select.Option key={CalendarDayType.PublicHoliday} value={CalendarDayType.PublicHoliday}>{t("timeLogType.publicHoliday")}</Select.Option>
                        <Select.Option key={CalendarDayType.MedicalSickLeave} value={CalendarDayType.MedicalSickLeave}>{t("timeLogType.medicalSickLeave")}</Select.Option>
                        <Select.Option key={CalendarDayType.Causa} value={CalendarDayType.Causa}>{t("timeLogType.causa")}</Select.Option>
                        <Select.Option key={CalendarDayType.MedicalVisit} value={CalendarDayType.MedicalVisit}>{t("timeLogType.medicalVisit")}</Select.Option>
                        <Select.Option key={CalendarDayType.Holiday} value={CalendarDayType.Holiday}>{t("timeLogType.holiday")}</Select.Option>
                        <Select.Option key={CalendarDayType.PaternityLeave} value={CalendarDayType.PaternityLeave}>{t("timeLogType.paternityLeave")}</Select.Option>
                        <Select.Option key={CalendarDayType.MarriagePermit} value={CalendarDayType.MarriagePermit}>{t("timeLogType.marriagePermit")}</Select.Option>
                        <Select.Option key={CalendarDayType.GreaterCause} value={CalendarDayType.GreaterCause}>{t("timeLogType.greaterCause")}</Select.Option>
                        <Select.Option key={CalendarDayType.ClockInWithoutApp} value={CalendarDayType.ClockInWithoutApp}>{t("timeLogType.clockInWithoutApp")}</Select.Option>
                        <Select.Option key={CalendarDayType.WithoutClockIn} value={CalendarDayType.WithoutClockIn}>{t("timeLogType.withoutClockIn")}</Select.Option>
                        <Select.Option key={CalendarDayType.GuardInNonWorkingDay} value={CalendarDayType.GuardInNonWorkingDay}>{t("timeLogType.guardInNonWorkingDay")}</Select.Option>
                        <Select.Option key={CalendarDayType.GuardInPublicHolidayDay} value={CalendarDayType.GuardInPublicHolidayDay}>{t("timeLogType.guardInPublicHolidayDay")}</Select.Option>
                        <Select.Option key={CalendarDayType.FamilyHospitalization} value={CalendarDayType.FamilyHospitalization}>{t("timeLogType.familyHospitalization")}</Select.Option>
                        <Select.Option key={CalendarDayType.SigningOnSheetOfPaper} value={CalendarDayType.SigningOnSheetOfPaper}>{t("timeLogType.signingOnSheetOfPaper")}</Select.Option>
                        <Select.Option key={CalendarDayType.HolidayPreviousYear} value={CalendarDayType.HolidayPreviousYear}>{t("timeLogType.holidayPreviousYear")}</Select.Option>
                        <Select.Option key={CalendarDayType.WorkLeave} value={CalendarDayType.WorkLeave}>{t("timeLogType.workLeave")}</Select.Option>
                        <Select.Option key={CalendarDayType.FamilyDeath} value={CalendarDayType.FamilyDeath}>{t("timeLogType.familyDeath")}</Select.Option>
                    </Select>
                    <label htmlFor="timeLogType" className="mt-4 mb-0.5 block text-sm font-medium leading-6 text-gray-900">
                        {t("calendar.timeOffRange")}
                    </label>
                    <p className="mb-3 text-xs italic text-gray-700">
                        {t("calendar.timeRangeTooltip")}
                    </p>
                    <RangePicker
                        format="DD-MM-YYYY"
                        locale={{
                            ...locale,
                            lang: {
                                ...locale.lang
                            }
                        }}
                        value={selectedRangeValue ? [dayjs(selectedRangeValue[0], "DD-MM-YYYY"), dayjs(selectedRangeValue[1], "DD-MM-YYYY")] : null}
                        onChange={handleRangeChange}
                    />
                </div>
            </ModalAdvanced>
        </div>
    );
};

export default EmployeeAnnualCalendar;