import { Employee } from "../employee/Employee";
import { Company } from "../company/Company";

export interface WorkCenter {
    id: number,
    code?: string;
    name: string;
    responsibleEmployeeId?: number;
    responsibleEmployee?: Employee;
    companyId?: number;
    company?: Company;
}

export function DefaultWorkCenter(): WorkCenter {
    return {
        id: 0,
        code: "",
        name: "",
    };
}