import { useState, useEffect } from 'react';
import { GetEmployeeIsTimeControlSupervisor, GetTimeLogTypes, GetWorkCentersIncidencesStatistics, GetWorkShiftDetailTimeLogTypes, GetWorkShiftDistributionStatisticsByYearByMonth } from '../../services/time-control/TimeControlService';
import { TimeLogTypeCategory, TimeLogType } from '../../interfaces/time-control/TimeLogType';
import { DTOPieChartItem } from '../../interfaces/common/statistics/DTOPieChartItem';
import { DTOBarChartItem } from '../../interfaces/common/statistics/DTOBarChartItem';

export const UseEmployeeIsTimeControlSupervisor = () => {
    const [data, setData] = useState<boolean>(false);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const result = await GetEmployeeIsTimeControlSupervisor();
                setData(result);
            } catch (err) {
                console.log(err as Error);
            }
        };

        fetchData();
    }, []);

    return [data];
};

export const UseTimeLogTypes = (timeLogCategory?: TimeLogTypeCategory) => {
    const [data, setData] = useState<TimeLogType[]>([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const result = await GetTimeLogTypes(timeLogCategory);
                if (result) {
                    setData(result);
                }
            } catch (err) {
                console.log(err as Error);
            }
        };

        fetchData();
    }, []);

    return data;
};

export const UseWorkShiftDetailTimeLogTypes = () => {
    const [data, setData] = useState<TimeLogType[]>([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const result = await GetWorkShiftDetailTimeLogTypes();
                if (result) {
                    setData(result);
                }
            } catch (err) {
                console.log(err as Error);
            }
        };

        fetchData();
    }, []);

    return data;
};

export const UseWorkCentersIncidencesStatistics = () => {
    const [data, setData] = useState<DTOPieChartItem[]>([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const result = await GetWorkCentersIncidencesStatistics();
                if (result) {
                    setData(result);
                }
            } catch (err) {
                console.log(err as Error);
            }
        };

        fetchData();
    }, []);

    return data;
};

export const UseWorkShiftDistributionStatisticsByYearByMonth = (year: number, month: number) => {
    const [data, setData] = useState<DTOBarChartItem[]>([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const result = await GetWorkShiftDistributionStatisticsByYearByMonth(year, month);
                if (result) {
                    setData(result);
                }
            } catch (err) {
                console.log(err as Error);
            }
        };

        fetchData();
    }, []);

    return data;
};