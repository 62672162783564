import { useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import { AnnualCalendar } from '../../../interfaces/common/calendar/AnnualCalendar';
import { Month } from '../../../interfaces/common/calendar/Month';
import { Day } from '../../../interfaces/common/calendar/Day';
import { CalendarDayType } from '../../../resources/utils/enums';

function classNames(...classes: any[]) {
    return classes.filter(Boolean).join(' ')
}

function getDayTypeClass(day: Day) {
    if (!day.isCurrentMonth) {
        return 'bg-gray-500 text-gray-50 hover:bg-gray-500 cursor-default';
    }

    switch (day.timeLogTypeId) {
        case CalendarDayType.WorkingDay:
            return 'bg-white text-gray-900';
        case CalendarDayType.NonWorkingDay:
            return 'bg-orange-300 text-orange-900 font-semibold hover:bg-orange-400';
        case CalendarDayType.PublicHoliday:
            return 'bg-red-400 text-red-900 font-semibold hover:bg-red-500';
        default:
            return '';
    }
}

function updateDayType(calendarDayType: CalendarDayType) {
    switch (calendarDayType) {
        case CalendarDayType.WorkingDay:
            return CalendarDayType.NonWorkingDay;
        case CalendarDayType.NonWorkingDay:
            return CalendarDayType.PublicHoliday;
        case CalendarDayType.PublicHoliday:
            return CalendarDayType.WorkingDay;
        default:
            return CalendarDayType.WorkingDay;
    }
}

interface AnnualCalendarTemplateProps {
    calendar: AnnualCalendar | null;
    onCalendarChange: (calendar: AnnualCalendar | null) => void;
}

const AnnualCalendarTemplate: React.FC<AnnualCalendarTemplateProps> = ({ calendar, onCalendarChange }) => {
    const { t } = useTranslation();

    const toggleWorkingDay = (clickedDayDate: string, isCurrentMonth: boolean) => {
        if (!calendar || !isCurrentMonth) return;

        const newCalendar = { ...calendar };
        newCalendar.months = calendar.months.map(month => {
            return {
                ...month,
                days: month.days.map(day => {
                    if (day.date === clickedDayDate) {
                        return { ...day, timeLogTypeId: updateDayType(day.timeLogTypeId) };
                    } else {
                        return day;
                    }
                })
            };
        });

        onCalendarChange(newCalendar);
    };

    useEffect(() => {
        onCalendarChange(calendar);
    }, [calendar]);

    return (
        <div>
            <div className="bg-white">
                <div className="mx-auto grid max-w-3xl grid-cols-1 gap-x-8 gap-y-16 px-4 py-14 sm:grid-cols-2 sm:px-6 xl:max-w-none xl:grid-cols-3 xl:px-8 2xl:grid-cols-4">
                    {calendar?.months.map((month: Month) => (
                        <section key={month.number} className="text-center">
                            <h2 className="text-sm font-semibold text-gray-900">{`${t(`common.month${month.number}`)}`}</h2>
                            <div className="mt-6 grid grid-cols-7 text-xs leading-6 text-gray-500">
                                <div>{t("common.week1")}</div>
                                <div>{t("common.week2")}</div>
                                <div>{t("common.week3")}</div>
                                <div>{t("common.week4")}</div>
                                <div>{t("common.week5")}</div>
                                <div>{t("common.week6")}</div>
                                <div>{t("common.week7")}</div>
                            </div>
                            <div className="isolate mt-2 grid grid-cols-7 gap-px rounded-lg bg-gray-200 text-sm shadow ring-1 ring-gray-200">
                                {month.days.map((day: Day, dayIdx: number) => (
                                    <button
                                        key={day.date}
                                        type="button"
                                        className={classNames(
                                            getDayTypeClass(day),
                                            dayIdx === 0 && 'rounded-tl-lg',
                                            dayIdx === 6 && 'rounded-tr-lg',
                                            dayIdx === month.days.length - 7 && 'rounded-bl-lg',
                                            dayIdx === month.days.length - 1 && 'rounded-br-lg',
                                            'py-1.5 hover:bg-gray-100 focus:z-10'
                                        )}
                                        onClick={() => toggleWorkingDay(day.date, day.isCurrentMonth)}
                                    >
                                        <time
                                            dateTime={day.date}
                                            className="mx-auto flex h-7 w-7 items-center justify-center rounded-ful"
                                        >
                                            {day?.date?.split('-').pop()?.replace(/^0/, '')}
                                        </time>
                                    </button>
                                ))}
                            </div>
                        </section>
                    ))}
                </div>
            </div>
        </div >
    )
};

export default AnnualCalendarTemplate;