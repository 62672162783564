import { useTranslation } from 'react-i18next';
import { DefaultOptionType } from 'antd/es/select';

export const UseMonths = (): DefaultOptionType[] => {
    const { t } = useTranslation();

    const months: DefaultOptionType[] = [
        { value: "1", label: t("common.month1") },
        { value: "2", label: t("common.month2") },
        { value: "3", label: t("common.month3") },
        { value: "4", label: t("common.month4") },
        { value: "5", label: t("common.month5") },
        { value: "6", label: t("common.month6") },
        { value: "7", label: t("common.month7") },
        { value: "8", label: t("common.month8") },
        { value: "9", label: t("common.month9") },
        { value: "10", label: t("common.month10") },
        { value: "11", label: t("common.month11") },
        { value: "12", label: t("common.month12") }
    ];

    return months;
};

export const UseYears = () => {
    const currentYear = new Date().getFullYear();
    const years: DefaultOptionType[] = [];
    for (let year = 2024; year <= currentYear; year++) {
        years.push({ value: year.toString(), label: year.toString() });
    }
    return years;
};

export type OptionType = {
    value: string;
    label: string;
};

export const UseYesNoOptions = () => {
    const { t } = useTranslation();

    const yesNoOptions: OptionType[] = [
        { value: 'true', label: t("common.yes") },
        { value: 'false', label: t("common.no") }
    ]

    return yesNoOptions;
};