import React, { useEffect, useState } from 'react';
import type { TableColumnsType } from 'antd';
import { Badge, Button, Modal, Popover, Table, Tooltip } from 'antd';
import { DTOMonthSummary, DTOTimeControlEmployeeSummary, DTOWorkShift } from '../../../interfaces/time-control/DTOTimeControlEmployeeSummary';
import { useTranslation } from 'react-i18next';
import { CalendarDaysIcon, ChatBubbleBottomCenterTextIcon, CheckIcon, DocumentCheckIcon, EnvelopeIcon, EnvelopeOpenIcon, ExclamationCircleIcon, ExclamationTriangleIcon, EyeIcon, HandThumbDownIcon, HandThumbUpIcon, InboxIcon, LockClosedIcon, PaperClipIcon, PlayIcon, TrashIcon } from '@heroicons/react/24/outline';
import { useNavigate } from 'react-router-dom';
import { useSpinner } from '../../../contexts/SpinnerContext';
import { AddWorkShiftMonthSummaryAttachment, CloseWorkShiftIncidenceAsWithoutClockIn, DeleteWorkShift, DeleteWorkShiftMonthSummaryAttachment, GetMonthSummaryForEmployeeByWorkCenter, GetWorkShiftMonthSummaryAttachment, RevertManualMonthClosing, SyncMonthlyTimeControlWithEmployeeCalendar } from '../../../services/time-control/TimeControlService';
import { IncidenceType, TimeControlSummaryByEmployeeStatusType } from '../../../resources/utils/enums';
import TimeControlManualMonthClosing from './TimeControlManualMonthClosing';
import ModalContainer from '../../../components/common/overlay/Modal';
import { usePageState } from '../../../contexts/PageStateContext';
import Attachments from '../../../components/common/upload/Attachments';
import ModalAdvanced from '../../../components/common/overlay/ModalAdvanced';

interface TimeControlSummaryTableProps {
    workCenterId: number;
    employeeId: number;
    year: number;
    timeControlSummary: DTOTimeControlEmployeeSummary | null;
    onRefresh: () => Promise<void>;
}

const TimeControlSummaryTable: React.FC<TimeControlSummaryTableProps> = ({ workCenterId, employeeId, year, timeControlSummary, onRefresh }) => {
    const pageName = `TimeControlSummaryTable_${workCenterId}_${employeeId}_${year}`;
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { setIsLoading } = useSpinner(false);
    const { state, dispatch } = usePageState();
    const [expandedRowKeys, setExpandedRowKeys] = React.useState<React.Key[]>([]);
    const [manualMonthClosingOpenModalMonthNumber, setManualMonthClosingOpenModalMonthNumber] = useState<number | null>(null);
    const [monthlyDocumentationOpenModalMonthNumber, setMonthlyDocumentationOpenModalMonthNumber] = useState<number | null>(null);
    const [validateIncidenceAsWithoutClockIn, setValidateIncidenceAsWithoutClockIn] = useState<boolean>(false);

    const handleReversionClick = (handleManualMonthClosingReversion: any, month: number) => {
        Modal.confirm({
            title: t("timeControl.revertCloseMonthManually").toString(),
            content: <div className="text-justify py-3" dangerouslySetInnerHTML={{ __html: `${t("timeControl.revertCloseMonthManuallyConfirmation")}` }} />,
            okText: t("common.accept").toString(),
            okType: 'danger',
            cancelText: t("common.cancel").toString(),
            centered: true,
            width: 550,
            async onOk() {
                await handleManualMonthClosingReversion(month)
            },
        });
    };

    const handleManualMonthClosingOk = async () => {
        setIsLoading(true);
        onRefresh()
            .then(() => {
                setManualMonthClosingOpenModalMonthNumber(null);
            });
    };

    const handleManualMonthClosingReversion = async (month: number) => {
        setIsLoading(true);
        Modal.destroyAll();
        await RevertManualMonthClosing(employeeId, workCenterId, year, month)
            .then((savingResult) => {
                if (savingResult === true) {
                    onRefresh();
                }
            });
    };

    const handleSyncMonthWithEmployeeCalendar = async (month: number) => {
        setIsLoading(true);
        Modal.destroyAll();
        await SyncMonthlyTimeControlWithEmployeeCalendar(employeeId, workCenterId, year, month)
            .then((syncResult) => {
                if (syncResult === true) {
                    onRefresh();
                } else {
                    setIsLoading(false);
                }
            });
    };

    const columns: TableColumnsType<DTOMonthSummary> = [
        {
            key: 'isValidated',
            align: 'center',
            render: (_, month) => (
                <div className="flex items-center justify-center space-x-3 ml-1">
                    {month.status === TimeControlSummaryByEmployeeStatusType.InProgress ? (
                        <Tooltip placement="topLeft" title={t("timeControl.monthInProgress")} color="geekblue">
                            <PlayIcon className="h-7 w-7 text-indigo-500" />
                        </Tooltip>
                    ) : month.status === TimeControlSummaryByEmployeeStatusType.Validated ? (
                        <>
                            <Tooltip placement="topLeft" title={t("timeControl.monthValidated")} color="geekblue">
                                <CheckIcon className="h-7 w-7 text-green-500" />
                            </Tooltip>
                            {month.certificateDownloadByEmployee && month.certificateDownloadByEmployee !== "" ? (
                                <Tooltip placement="topLeft" title={month.certificateDownloadByEmployee} color="geekblue">
                                    <EnvelopeOpenIcon className="h-6 w-6 text-indigo-500" />
                                </Tooltip>
                            ) : (
                                <Popover
                                    placement="topLeft"
                                    title={t("timeControl.certificateNotDownloadByEmployee")}
                                    content={
                                        <div className="flex justify-start mt-3 mb-2">
                                            <button
                                                className="rounded-md bg-orange-50 px-2 py-1 text-sm font-semibold text-orange-600 shadow-sm hover:bg-orange-100"
                                                onClick={() => handleReversionClick(handleManualMonthClosingReversion, month.number)}
                                            >
                                                {t("timeControl.revertCloseMonthManually")}
                                            </button>
                                        </div>
                                    }
                                >
                                    <EnvelopeIcon className="h-6 w-6 text-orange-500" />
                                </Popover>
                            )}
                        </>
                    ) : month.status === TimeControlSummaryByEmployeeStatusType.NotValidated ? (
                        <Tooltip placement="topLeft" title={t("timeControl.monthNonValidated")} color="red">
                            <ExclamationTriangleIcon className="h-7 w-7 text-red-600" />
                        </Tooltip>
                    ) : null}
                </div>
            ),
        },
        {
            title: '',
            key: 'operation',
            align: 'center',
            render: (_, month) => (
                <div className="flex items-center justify-center space-x-2 ml-1">
                    {month.status === TimeControlSummaryByEmployeeStatusType.InProgress ? (
                        <Tooltip placement="topLeft" title={t("timeControl.closeMonthManually")} color="yellow">
                            <button
                                className="rounded-md bg-yellow-50 px-2 py-1 text-sm font-semibold text-yellow-600 shadow-sm hover:bg-yellow-100"
                                onClick={() => (
                                    Modal.warning({
                                        title: t("timeControl.generateMonthlyCertificateMonthInProgressErrorTitle"),
                                        content: <div className="text-justify" dangerouslySetInnerHTML={{ __html: `${t("timeControl.generateMonthlyCertificateMonthInProgressError")}` }} />,
                                        centered: true,
                                        width: 550
                                    })
                                )}
                            >
                                <LockClosedIcon className="h-5 w-5" aria-hidden="true" />
                            </button>
                        </Tooltip>
                    ) : month.status === TimeControlSummaryByEmployeeStatusType.Validated ? (
                        <Tooltip placement="topLeft" title={t("timeControl.generateMonthlyCertificate")} color="orange">
                            <button
                                className="rounded-md bg-orange-50 px-2 py-1 text-sm font-semibold text-orange-600 shadow-sm hover:bg-orange-100"
                                onClick={() => (
                                    exportMonthSummary(month.number, year)
                                )}
                            >
                                <DocumentCheckIcon className="h-5 w-5" aria-hidden="true" />
                            </button>
                        </Tooltip>
                    ) : month.status === TimeControlSummaryByEmployeeStatusType.NotValidated ? (
                        <>
                            <Tooltip placement="topLeft" title={t("timeControl.closeMonthManually")} color="orange">
                                <button
                                    className="rounded-md bg-orange-50 px-2 py-1 text-sm font-semibold text-orange-600 shadow-sm hover:bg-orange-100"
                                    onClick={() => (
                                        setManualMonthClosingOpenModalMonthNumber(month.number)
                                    )}
                                >
                                    <LockClosedIcon className="h-5 w-5" aria-hidden="true" />
                                </button>
                            </Tooltip>
                            <ModalContainer isOpen={manualMonthClosingOpenModalMonthNumber === month.number} onClose={() => setManualMonthClosingOpenModalMonthNumber(null)}>
                                <TimeControlManualMonthClosing
                                    employeeId={employeeId}
                                    workCenterId={workCenterId}
                                    year={year}
                                    month={month.number}
                                    onOk={() => handleManualMonthClosingOk()}
                                    onCancel={() => setManualMonthClosingOpenModalMonthNumber(null)}
                                />
                            </ModalContainer>
                        </>
                    ) : null
                    }
                    {month.status !== TimeControlSummaryByEmployeeStatusType.Validated &&
                        <Popover
                            placement="topLeft"
                            title={t("timeControl.syncWithEmployeeCalendarTitle")}
                            content={
                                <div className="flex justify-start mt-3 mb-2 space-x-2">
                                    <Tooltip placement="topLeft" title={t("timeControl.syncWithEmployeeCalendarViewEmployeeCalendarTooltip")} color="green">
                                        <a
                                            href={`/employees/${employeeId}`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="rounded-md bg-emerald-50 px-2 py-1 text-sm font-semibold text-emerald-600 hover:text-emerald-600 shadow-sm hover:bg-emerald-100"
                                        >
                                            {t("timeControl.syncWithEmployeeCalendarViewEmployeeCalendar")}
                                        </a>
                                    </Tooltip>
                                    <button
                                        className="rounded-md bg-sky-50 px-2 py-1 text-sm font-semibold text-sky-600 shadow-sm hover:bg-sky-100"
                                        onClick={() =>
                                            Modal.confirm({
                                                title: t("timeControl.syncWithEmployeeCalendarConfirmTitle").toString(),
                                                content: <div className="text-justify py-3" dangerouslySetInnerHTML={{ __html: `${t("timeControl.syncWithEmployeeCalendarConfirmContent", { month: t(`common.month${month.number}`), year: year })}` }} />,
                                                okText: t("common.accept").toString(),
                                                okType: 'danger',
                                                cancelText: t("common.cancel").toString(),
                                                centered: true,
                                                width: 650,
                                                async onOk() {
                                                    await handleSyncMonthWithEmployeeCalendar(month.number);
                                                },
                                            })
                                        }
                                    >
                                        {t("timeControl.syncWithEmployeeCalendar")}
                                    </button>
                                </div>
                            }
                        >
                            <button className="rounded-md bg-fuchsia-50 px-2 py-1 text-sm font-semibold text-fuchsia-600 shadow-sm hover:bg-fuchsia-100 cursor-default">
                                <CalendarDaysIcon className="h-5 w-5 text-fuchsia-500" />
                            </button>
                        </Popover>
                    }
                    <Tooltip placement="topLeft" title={t("timeControl.monthlyDocumentation", { monthName: t(`common.month${month.number}`) })} color="geekblue">
                        <button
                            className="rounded-md bg-indigo-50 px-2 py-1 text-sm font-semibold text-indigo-600 shadow-sm hover:bg-indigo-100"
                            onClick={() => (
                                setMonthlyDocumentationOpenModalMonthNumber(month.number)
                            )}
                        >
                            <DocumentCheckIcon className="h-5 w-5" aria-hidden="true" />
                        </button>
                    </Tooltip>
                    <ModalAdvanced
                        title={t("timeControl.monthlyDocumentation", { monthName: t(`common.month${month.number}`) })}
                        subtitle={t("timeControl.monthlyDocumentationModalSubtitle")}
                        okText={t("common.close")}
                        onOk={() => setMonthlyDocumentationOpenModalMonthNumber(null)}
                        isOpen={monthlyDocumentationOpenModalMonthNumber === month.number}
                        onClose={() => setMonthlyDocumentationOpenModalMonthNumber(null)}
                        closeVisible={false}
                    >
                        <Attachments
                            attachments={month.attachments}
                            allowedExtensions={['png', 'jpg', 'jpeg', 'pdf', 'docx']}
                            maxSize={5}
                            onAdd={async (file: File) => {
                                setIsLoading(true);
                                await AddWorkShiftMonthSummaryAttachment(month.workShiftMonthSummaryId, file).then((newId) => {
                                    if (newId) {
                                        month.attachments.push(
                                            {
                                                id: newId,
                                                workShiftMonthSummaryId: month.workShiftMonthSummaryId,
                                                fileName: file.name,
                                                fileType: ""
                                            }
                                        );
                                    }
                                });
                                setIsLoading(false);
                            }}
                            onDelete={async (id: number) => {
                                setIsLoading(true);
                                await DeleteWorkShiftMonthSummaryAttachment(month.workShiftMonthSummaryId, id).then((removeSuccessful) => {
                                    if (removeSuccessful === true) {
                                        month.attachments = month.attachments.filter(a => a.id !== id);
                                        Modal.success({
                                            title: t("common.deletedSuccessful").toString(),
                                            content: "",
                                            okText: t("common.accept").toString(),
                                            centered: true
                                        })
                                    }
                                });
                                setIsLoading(false);
                            }}
                            onDownload={async (id: number) => {
                                setIsLoading(true);
                                await GetWorkShiftMonthSummaryAttachment(month.workShiftMonthSummaryId, id);
                                setIsLoading(false);
                            }}
                        />
                    </ModalAdvanced>
                </div >
            )
        },
        {
            title: t("common.month"),
            key: 'number',
            render: (_, month) => (
                t(`common.month${month.number}`).toUpperCase()
            ),
        },
        { title: t("timeControl.monthlyExpectedWorkDays"), dataIndex: 'expectedWorkDays', key: 'expectedWorkDays', align: 'center' },
        { title: t("timeControl.monthlyRealWorkDays"), dataIndex: 'realWorkDays', key: 'realWorkDays', align: 'center' },
        { title: t("timeControl.monthlyExpectedWorkTime"), dataIndex: 'expectedWorkTime', key: 'expectedWorkTime', align: 'center' },
        { title: t("timeControl.monthlyTotalWorkTime"), dataIndex: 'computedTime', key: 'computedTime', align: 'center' },
        { title: t("timeControl.monthlyDesviationTime"), dataIndex: 'desviationTime', key: 'desviationTime', align: 'center' },
        {
            title: t("timeControl.monthlyDesviationPercent"),
            key: 'desviationPercent',
            align: 'center',
            render: (_, month) => (
                month.desviationPercent + " %"
            )
        },
        {
            title: t("timeControl.monthlyIncidencesCount"),
            align: 'center',
            render: (_, month) => (
                month.incidencesCount === 0 ? (
                    <div className="flex items-center justify-center">
                        <HandThumbUpIcon className="h-6 w-6 text-green-500" />
                        <span className="text-base text-green-500 ml-2">{month.incidencesCount}</span>
                    </div>
                ) : (
                    <div className="flex items-center justify-center">
                        <HandThumbDownIcon className="h-6 w-6 text-red-600" />
                        <span className="text-base text-red-500 ml-2">{month.incidencesCount}</span>
                    </div>
                )
            )
        },
    ];

    const data = timeControlSummary ? timeControlSummary.months : [];

    const expandedRowRender = (month: DTOMonthSummary) => {
        const expandedColumns: TableColumnsType<DTOWorkShift> = [
            {
                key: 'isValidated',
                align: 'center',
                render: (_, workShift) => (
                    <div className="flex items-center justify-center space-x-2 ml-1">
                        {workShift.endShift === "-" ? (
                            <Tooltip placement="topLeft" title={t("timeControl.workShiftInProgress")} color="geekblue">
                                <PlayIcon className="h-7 w-7 text-indigo-500" />
                            </Tooltip>
                        ) : workShift.isValidated ? (
                            <Tooltip placement="topLeft" title={t("timeControl.workShiftValidated")} color="green">
                                <CheckIcon className="h-7 w-7 text-green-500" />
                            </Tooltip>
                        ) : (
                            <Popover
                                placement="topLeft"
                                title={<div className="text-sm text-amber-600 font-bold">{t("timeControl.incidencePopover")}</div>}
                                content={
                                    <div className="mb-3 space-y-4">
                                        <p className="text-sm font-medium text-gray-900 italic">{t(`incidenceType.${workShift.incidenceType}`)}</p>
                                        {workShift.incidenceType === IncidenceType.WithoutClockIn ? (
                                            <Tooltip placement="topLeft" title={t("timeControl.closeWorkShiftIncidenceAsWithoutClockInTooltip")} color="red">
                                                <Button
                                                    className="rounded-md bg-orange-50 px-2 py-1 text-sm font-semibold text-orange-600 shadow-sm hover:bg-orange-100"
                                                    type="primary"
                                                    danger
                                                    loading={validateIncidenceAsWithoutClockIn}
                                                    disabled={validateIncidenceAsWithoutClockIn}
                                                    onClick={async () => {
                                                        setValidateIncidenceAsWithoutClockIn(true);
                                                        await CloseWorkShiftIncidenceAsWithoutClockIn(workShift.id).then((result) => {
                                                            setValidateIncidenceAsWithoutClockIn(false);
                                                            if (result) {
                                                                workShift.isValidated = true;
                                                                workShift.totalWorkTime = "-";
                                                                workShift.totalOffTime = "-";
                                                                workShift.totalTime = "-";
                                                            }
                                                        })
                                                    }}
                                                >
                                                    {t("timeControl.closeWorkShiftIncidenceAsWithoutClockInTitle")}
                                                </Button>
                                            </Tooltip>
                                        ) : null}
                                    </div>
                                }
                            >
                                <ExclamationTriangleIcon className="h-7 w-7 text-red-600" />
                            </Popover>
                        )}
                        {workShift.incidenceType === IncidenceType.WithoutClockOut &&
                            <Tooltip placement="topLeft" title={t("timeControl.withoutClockOutTooltip")} color="orange">
                                <ExclamationCircleIcon className="h-6 w-6 text-orange-500" />
                            </Tooltip>
                        }
                    </div>
                ),
            },
            {
                title: '',
                dataIndex: 'operation',
                key: 'operation',
                align: 'center',
                render: (_, workShift) => (
                    <div className="flex items-center justify-center space-x-2 ml-1">
                        <Tooltip placement="topLeft" title={t("timeControl.viewWorkShiftDetail")} color="geekblue">
                            <button
                                className="rounded-md bg-indigo-50 px-2 py-1 text-sm font-semibold text-indigo-600 shadow-sm hover:bg-indigo-100"
                                onClick={() => navigate(`/time-control/${workCenterId}/${workShift.id}`)}
                            >
                                <EyeIcon className="h-5 w-5" aria-hidden="true" />
                            </button>
                        </Tooltip>
                        {timeControlSummary?.isAdminView &&
                            <Tooltip placement="topLeft" title={t("common.delete")} color="red">
                                <button
                                    className="rounded-md bg-red-50 px-2 py-1 text-sm font-semibold text-red-600 shadow-sm hover:bg-red-100"
                                    onClick={() => {
                                        Modal.confirm({
                                            title: t("timeControl.deleteWorkShiftTitle").toString(),
                                            content: <div className="text-justify py-3" dangerouslySetInnerHTML={{ __html: `${t("timeControl.deleteWorkShiftSubtitle", { date: workShift.startShift.split(" ")[0], extra: (month.status === TimeControlSummaryByEmployeeStatusType.Validated ? t("timeControl.deleteWorkShiftSubtitle2") : "") })}` }} />,
                                            okText: t("common.accept").toString(),
                                            okType: 'danger',
                                            cancelText: t("common.cancel").toString(),
                                            centered: true,
                                            width: 550,
                                            async onOk() {
                                                setIsLoading(true);
                                                await DeleteWorkShift(workShift.id)
                                                    .then(() => {
                                                        onRefresh();
                                                    });
                                            },
                                        })
                                    }}
                                >
                                    <TrashIcon className="h-5 w-5" aria-hidden="true" />
                                </button>
                            </Tooltip>
                        }
                    </div>
                ),
            },
            {
                key: 'info',
                align: 'center',
                className: 'min-w-min w-[50px]',
                render: (_, workShift) => (
                    <div className="flex items-center justify-center space-x-2 ml-1">
                        {workShift.employeeRemarks &&
                            <Popover
                                placement="topLeft"
                                title={t("timeControl.workShiftRowRemarksPopover")}
                                content={
                                    <div className="flex justify-start mt-3 mb-2 whitespace-pre-wrap">
                                        {workShift.employeeRemarks}
                                    </div>
                                }
                            >
                                <ChatBubbleBottomCenterTextIcon className="h-6 w-6 text-amber-500" />
                            </Popover>
                        }
                        {(workShift.attachmentsCount ?? 0) > 0 &&
                            <Tooltip placement="topLeft" title={t("timeControl.workShiftRowAttachmentsCountTooltip", { attachmentsCount: workShift.attachmentsCount, plural: (workShift.attachmentsCount ?? 0) > 1 ? "s" : "" })} color="gold">
                                <PaperClipIcon className="h-6 w-6 text-amber-500" />
                            </Tooltip>
                        }
                    </div>
                ),
            },
            {
                title: t("timeControl.startShiftDate"),
                dataIndex: 'startShift',
                key: 'startShift',
                align: 'center',
                render: (_, workShift) => (
                    workShift.startShift.split(" ")[0]
                ),
            },
            { title: t("timeControl.effectiveWorkTime"), dataIndex: 'totalWorkTime', key: 'totalWorkTime', align: 'center' },
            { title: t("timeControl.otherComputedTime"), dataIndex: 'totalOffTime', key: 'totalWorkTime', align: 'center' },
            { title: t("timeControl.totalComputedTime"), dataIndex: 'totalTime', key: 'totalWorkTime', align: 'center' },
            {
                title: t("common.status"),
                key: 'isActive',
                render: (_, workShift) => (
                    workShift.isActive ? (
                        <Badge status="processing" text={t("timeControl.workShiftInProgress")} />
                    ) : workShift.isValidated ? (
                        <Badge status="success" text={t("timeControl.workShiftFinished")} />
                    ) : (
                        <Badge status="error" text={t("timeControl.workShiftWithIncidence")} />
                    )
                ),
            },
        ];

        return <Table
            columns={expandedColumns}
            dataSource={month.workShifts}
            rowKey={workShift => workShift.id.toString()}
            pagination={false}
            locale={{
                emptyText: (
                    <div className="flex flex-col items-center justify-center">
                        <span className="mb-3 text-base font-semibold">{t("common.noDataInPeriod")}</span>
                        <InboxIcon className="h-14 w-14" />
                    </div>
                )
            }}
        />;
    };

    const onExpand = (expanded: boolean, month: DTOMonthSummary) => {
        const key = month.number.toString();
        const keys = expanded
            ? [...expandedRowKeys, key]
            : expandedRowKeys.filter(k => k !== key);
        setExpandedRowKeys(keys);

        dispatch({ type: 'SET_PAGE_STATE', payload: { pageKey: pageName, state: { ...state[pageName], expandedRowKeys: keys } } });
    };

    useEffect(() => {
        if (state[pageName]?.expandedRowKeys) {
            setExpandedRowKeys(state[pageName].expandedRowKeys);
        }
    }, [workCenterId, employeeId]);

    const exportMonthSummary = async (month: number, year: number) => {
        setIsLoading(true);
        await GetMonthSummaryForEmployeeByWorkCenter(employeeId, month, year, workCenterId);
        setIsLoading(false);
    };

    return (
        <Table
            rowKey={month => month.number.toString()}
            columns={columns}
            expandable={{
                expandedRowRender,
                expandedRowKeys,
                onExpand
            }}
            dataSource={data}
            pagination={false}
        />
    );
};

export default TimeControlSummaryTable;