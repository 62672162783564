import React from 'react';
import { ResponsivePie } from '@nivo/pie';
import { DTOPieChartItem } from '../../../interfaces/common/statistics/DTOPieChartItem';

interface PieChartProps {
  data: DTOPieChartItem[];
  legend?: boolean;
  size?: number;
}

const PieChart: React.FC<PieChartProps> = ({ data, legend = false, size = 300 }) => {
  return (
    <div style={{ height: size }}>
      <ResponsivePie
        data={data}
        margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
        innerRadius={0.5}
        padAngle={0.7}
        cornerRadius={3}
        activeOuterRadiusOffset={8}
        colors={{ scheme: 'nivo' }}
        borderColor={{ from: 'color', modifiers: [['darker', 0.6]] }}
        arcLinkLabelsSkipAngle={10}
        arcLinkLabelsTextColor="#333333"
        arcLinkLabelsThickness={2}
        arcLinkLabelsColor={{ from: 'color' }}
        arcLabelsSkipAngle={10}
        arcLabelsTextColor={{ from: 'color', modifiers: [['darker', 2]] }}
        legends={legend ? (
          [
            {
              anchor: 'top-left',
              direction: 'column',
              justify: false,
              translateX: 0,
              translateY: 56,
              itemsSpacing: 3,
              itemWidth: 100,
              itemHeight: 18,
              itemTextColor: '#999',
              itemDirection: 'left-to-right',
              itemOpacity: 1,
              symbolSize: 18,
              symbolShape: 'circle',
              effects: [
                {
                  on: 'hover',
                  style: {
                    itemTextColor: '#000'
                  }
                }
              ]
            }
          ]) : undefined}
      />
    </div>
  );
};

export default PieChart;