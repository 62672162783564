import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { GetCompanies, GetCompany, GetWorkCenter, GetWorkCenters } from "../../services/company/CompanyService";
import { WorkCenter } from "../../interfaces/work-center/WorkCenter";
import { Company } from "../../interfaces/company/Company";

export const UseCompanies = () => {
    const [data, setData] = useState<Company[]>([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const result = await GetCompanies(undefined, undefined, undefined);
                setData(result);
            } catch (err) {
                console.log(err as Error);
            }
        };

        fetchData();
    }, []);

    return data;
};

export const UseCompany = (companyId: number) => {
    const [data, setData] = useState<Company | null>(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const result = await GetCompany(companyId);
                setData(result);
            } catch (err) {
                console.log(err as Error);
            }
        };

        fetchData();
    }, []);

    return data;
};

export const UseWorkCenters = () => {
    const [data, setData] = useState<WorkCenter[]>([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const result = await GetWorkCenters();
                setData(result);
            } catch (err) {
                console.log(err as Error);
            }
        };

        fetchData();
    }, []);

    return data;
};

export const UseWorkCenter = (id: number) => {
    const [data, setData] = useState<WorkCenter | null>();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const result = await GetWorkCenter(id);
                setData(result);
            } catch (err) {
                console.log(err as Error);
            }
        };

        fetchData();
    }, [id]);

    return data;
};