import { Dialog, Disclosure, Menu, Transition } from '@headlessui/react'
import { ChevronDoubleLeftIcon, ChevronDoubleRightIcon, ChevronRightIcon } from '@heroicons/react/20/solid'
import {
  Bars3Icon,
  BellIcon,
  HomeIcon,
  UsersIcon,
  XMarkIcon,
  ClockIcon,
  Cog6ToothIcon,
  BuildingOffice2Icon,
  BookOpenIcon
} from '@heroicons/react/24/outline'
import {
  ChevronDownIcon,
} from "@heroicons/react/20/solid";
import { AuthProps } from '../../App'
import { useNavigate, BrowserRouter as Router, Routes, Route, Link, useLocation } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { Fragment, useEffect, useState } from 'react';
import { User } from '../../interfaces/user/User';
import { Employee } from '../../interfaces/employee/Employee';
import { RouteItem } from '../../interfaces/common/RouteItem';
import { RemoveLoginCookies } from "../../services/cookie/CookieService";
import { GetCookie } from "../../services/cookie/CookieService";
import TimeControlList from '../section/time-control/TimeControlList';
import Home from '../section/Home';
import EmployeeList from '../section/rrhh/EmployeeList';
import EmployeeDetail from '../section/rrhh/EmployeeDetail';
import TimeControlWorkCenterSummary from '../section/time-control/TimeControlWorkCenterSummary';
import TimeControlEmployeeSummary from '../section/time-control/TimeControlEmployeeSummary';
import CompanyList from '../section/maintenance/CompanyList';
import WorkCenterDetail from '../section/maintenance/WorkCenterDetail';
import TimeControlDetail from '../section/time-control/TimeControlDetail';
import CompanyDetail from '../section/maintenance/CompanyDetail';
import NotFound from '../not-found/NotFound';
import { Tooltip } from 'react-tooltip';
import { GetUserManual } from '../../services/documentation/DocumentationService';
import { useSpinner } from '../../contexts/SpinnerContext';
import EnvironmentAlert from '../../components/common/alert/EnvironmentAlert';
import { getImagePath } from '../../resources/utils/common';
import CalendarDetail from '../section/calendar/CalendarDetail';

interface Permissions {
  [key: string]: Permissions | string[] | {};
}

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ')
}

function getMainMenu(navigationComplete: RouteItem[], user: User | undefined, t: Function, selectedKey: string): RouteItem[] {
  if (!user) {
    return [];
  }

  return navigationComplete
    .filter(item => (user.permissions as { [key: string]: any }).hasOwnProperty(item.key))
    .map((item) => {
      const newItem = { ...item, name: t("dashboard." + item.key), current: selectedKey === item.key };

      if (newItem.children) {
        newItem.children = newItem.children
          .filter(child => (user.permissions[item.key] as { [key: string]: any }).hasOwnProperty(child.key))
          .map(child => ({ ...child, name: t("dashboard." + child.key), current: selectedKey === child.key }));
      }

      return newItem;
    });
}

function getUserFromCookie() {
  return JSON.parse(GetCookie("user") || "{}");
}

function getEmployeeFromCookie() {
  return JSON.parse(GetCookie("employee") || "{}");
}

export default function Dashboard({ setIsLoggedIn }: AuthProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { setIsLoading } = useSpinner();

  const [isDesktopSidebarCollapsed, setDesktopSidebarCollapsed] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [user, setUser] = useState<User | undefined>(undefined);
  const [employee, setEmployee] = useState<Employee | undefined>(undefined);
  const [navigation, setNavigation] = useState<RouteItem[]>([]);
  const [selectedKey, setSelectedKey] = useState("");
  const [expandedKeys, setExpandedKeys] = useState<string[]>([]);

  const navigationComplete: RouteItem[] = [
    {
      key: 'home',
      route: "/home",
      component: Home,
      icon: HomeIcon,
      current: selectedKey === "home"
    },
    {
      key: 'rrhh',
      icon: UsersIcon,
      current: false,
      children: [
        {
          key: 'timeControl',
          route: "/time-control",
          component: TimeControlList,
          icon: ClockIcon,
          current: false,
          children: [
            {
              key: 'timeControlWorkCenterSummary',
              route: "/time-control/work-center",
              routeParams: "/:workCenterId",
              component: TimeControlWorkCenterSummary,
              current: false,
            },
            {
              key: 'timeControlEmployeeSummary',
              route: "/time-control/employee",
              routeParams: "/:workCenterId/:employeeId",
              component: TimeControlEmployeeSummary,
              current: false,
            },
            {
              key: 'timeControlDetail',
              route: "/time-control",
              routeParams: "/:workCenterId/:id",
              component: TimeControlDetail,
              current: false,
            },
          ],
        },
      ],
    },
    {
      key: 'maintenance',
      icon: Cog6ToothIcon,
      current: false,
      children: [
        {
          key: 'companies',
          route: "/companies",
          component: CompanyList,
          icon: BuildingOffice2Icon,
          current: false,
          children: [
            {
              key: 'companyDetail',
              route: "/companies",
              routeParams: "/:id",
              component: CompanyDetail,
              current: false,
            },
            {
              key: 'workCenterDetail',
              route: "/work-centers",
              routeParams: "/:id",
              component: WorkCenterDetail,
              current: false,
              children: [
                {
                  key: 'calendar',
                  route: "/work-centers/calendar",
                  routeParams: "/:workCenterId/:calendarId",
                  component: CalendarDetail,
                  current: false,
                },
              ]
            },
          ]
        },
        {
          key: 'employees',
          route: "/employees",
          component: EmployeeList,
          icon: UsersIcon,
          current: false,
          children: [
            {
              key: 'employeeDetail',
              route: "/employees",
              routeParams: "/:id",
              component: EmployeeDetail,
              current: false,
            },
          ]
        },
      ],
    },
  ]

  const renderRoutes = (navigationItems: RouteItem[]): JSX.Element[] => {
    return navigationItems.map((item, index) => {
      const routes: JSX.Element[] = [];

      if (item.component) {
        const path = item.routeParams ? `${item.route}${item.routeParams}` : item.route;
        routes.push(<Route key={`route-${index}`} path={path} element={<item.component />} />);
      }

      if (item.children) {
        routes.push(...renderRoutes(item.children));
      }

      return routes;
    }).flat();
  };

  const logout = () => {
    RemoveLoginCookies();
    setIsLoggedIn(false);
    navigate("/");
  };

  const userNavigation = [
    { key: "logout", onClick: logout },
  ].map(item => ({
    ...item,
    name: t("dashboard." + item.key),
  }));

  useEffect(() => {
    setUser(getUserFromCookie());
    setEmployee(getEmployeeFromCookie());
  }, []);

  useEffect(() => {
    if (location?.pathname !== "/") {
      let item = navigationComplete.find(item => item.route === location.pathname);
      if (item !== undefined) {
        setSelectedKey(item.key);
        return;
      }
    }
    if (user && user.permissions) {
      const firstPermission = Object.keys(user.permissions)[0];
      setSelectedKey(firstPermission);
    }
  }, [user]);

  useEffect(() => {
    setNavigation(getMainMenu(navigationComplete, user, t, selectedKey));
  }, [selectedKey]);

  function findItemWithPath(items: RouteItem[], path: string, parents: string[] = []): [RouteItem | null, string[]] {
    for (let item of items) {
      const currentPath = [...parents, item.key];
      if (item.route && path.startsWith(item.route)) {
        return [item, currentPath];
      } else if (item.children) {
        let [found, foundPath] = findItemWithPath(item.children, path, currentPath);
        if (found) {
          return [found, foundPath];
        }
      }
    }
    return [null, []];
  }

  function hasPermission(permissions: Permissions, key: string): boolean {
    if (permissions.hasOwnProperty(key)) {
      return true;
    }
    for (const prop in permissions) {
      if (typeof permissions[prop] === 'object' && hasPermission(permissions[prop] as Permissions, key)) {
        return true;
      }
    }
    return false;
  }

  function findFirstAvailableRoute(navigationItems: RouteItem[], user: User | undefined): string {
    if (!user || !user.permissions) {
      return '/';
    }

    for (const item of navigationItems) {
      if (hasPermission(user.permissions, item.key)) {
        if (item.route) {
          return item.route;
        }
      }
      if (item.children) {
        for (const child of item.children) {
          if (hasPermission(user.permissions, child.key)) {
            return child.route ?? '/';
          }
        }
      }
    }
    return '/';
  }

  useEffect(() => {
    if (location.pathname !== "/") {
      const [selectedNavItem, path] = findItemWithPath(navigationComplete, location.pathname);
      if (selectedNavItem && selectedNavItem.key !== selectedKey) {
        setSelectedKey(selectedNavItem.key);
        const newPath = path.slice(0, -1);
        if (!arraysEqual(newPath, expandedKeys)) {
          setExpandedKeys(newPath);
        }
      }
    } else {
      const firstAvailableRoute = findFirstAvailableRoute(navigationComplete, user);
      navigate(firstAvailableRoute);
    }
  }, [location, navigationComplete, selectedKey, expandedKeys, user]);

  function arraysEqual(a: string[], b: string[]): boolean {
    if (a === b) return true;
    if (a == null || b == null) return false;
    if (a.length !== b.length) return false;

    for (var i = 0; i < a.length; ++i) {
      if (a[i] !== b[i]) return false;
    }
    return true;
  }

  const handleSidebarClick = (key: string) => {
    setSelectedKey(key);
    setSidebarOpen(false);
  };

  return (
    <div>
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-50 lg:hidden"
          onClose={setSidebarOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-900/80" />
          </Transition.Child>

          <div className="fixed inset-0 flex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                    <button
                      type="button"
                      className="-m-2.5 p-2.5"
                      onClick={() => setSidebarOpen(false)}
                    >
                      <span className="sr-only">{t("dashboard.closeSidebar")}</span>
                      <XMarkIcon
                        className="h-6 w-6 text-white"
                        aria-hidden="true"
                      />
                    </button>
                  </div>
                </Transition.Child>
                <div className="flex grow flex-col gap-y-5 overflow-y-auto border-r border-gray-200 bg-white px-6">
                  <div className="flex h-16 shrink-0 items-center">
                    <img className="mt-7 h-16 w-auto" src={getImagePath("images/logo-ocon-black.webp")} alt="Transportes Ocon - ERP" />
                  </div>
                  <h2 className="mt-2 text-center text-2xl font-bold italic leading-9 tracking-tight text-gray-700">
                    {t("login.erpTitle")}
                  </h2>
                  <nav className="flex flex-1 flex-col">
                    <ul role="list" className="flex flex-1 flex-col gap-y-7">
                      <li>
                        <ul role="list" className="-mx-2 space-y-1">
                          {navigation.map((item) => (
                            <li key={item.name}>
                              {!item.children ? (
                                <Link
                                  to={item.route ?? '/'}
                                  onClick={() => handleSidebarClick(item.key)}
                                  className={classNames(
                                    item.current ? 'bg-gray-200' : 'hover:bg-gray-200',
                                    'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold text-gray-900'
                                  )}
                                >
                                  <item.icon className="h-6 w-6 shrink-0 text-gray-900" aria-hidden="true" />
                                  {item.name}
                                </Link>
                              ) : (
                                <Disclosure as="div" defaultOpen={expandedKeys.includes(item.key)}>
                                  {({ open }) => (
                                    <>
                                      <Disclosure.Button
                                        className={classNames(
                                          item.current ? 'bg-gray-50' : 'hover:bg-gray-50',
                                          'flex items-center w-full text-left rounded-md p-2 gap-x-3 text-sm leading-6 font-semibold text-gray-900'
                                        )}
                                      >
                                        <item.icon className="h-6 w-6 shrink-0 text-gray-900" aria-hidden="true" />
                                        {item.name}
                                        <ChevronRightIcon
                                          className={classNames(
                                            open ? 'rotate-90 text-gray-600' : 'text-gray-600',
                                            'ml-auto h-5 w-5 shrink-0'
                                          )}
                                          aria-hidden="true"
                                        />
                                      </Disclosure.Button>
                                      <Disclosure.Panel as="ul" className="mt-1 px-2">
                                        {item.children?.map((subItem) => (
                                          <Link
                                            to={subItem.route ?? '/'}
                                            key={subItem.key}
                                            onClick={() => handleSidebarClick(subItem.key)}
                                            className={classNames(
                                              subItem.current ? 'bg-gray-200' : 'hover:bg-gray-200',
                                              'group flex items-center gap-x-3 rounded-md p-2 text-sm font-semibold text-gray-600'
                                            )}
                                          >
                                            {subItem.icon ? <subItem.icon className="h-6 w-6 shrink-0" aria-hidden="true" /> : null}
                                            {subItem.name}
                                          </Link>
                                        ))}
                                      </Disclosure.Panel>
                                    </>
                                  )}
                                </Disclosure>
                              )}
                            </li>
                          ))}
                        </ul>
                      </li>
                    </ul>
                  </nav>
                </div>

              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      <div className={isDesktopSidebarCollapsed ? "hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-24 lg:flex-col" : "hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col"}>
        <div className="flex grow flex-col gap-y-5 overflow-y-auto border-r border-gray-200 bg-gray-100 px-6 pb-4">
          <div className="flex h-16 shrink-0 items-center">
            {isDesktopSidebarCollapsed ? <img className="mt-7 h-12 w-12" src={getImagePath("images/logo-ocon-small.webp")} alt="Transportes Ocon - ERP" /> : <img className="mt-7 h-16 w-auto" src={getImagePath("images/logo-ocon-black.webp")} alt="Transportes Ocon - ERP" />}
          </div>
          <h2 className={isDesktopSidebarCollapsed ? "hidden" : "mt-2 text-center text-2xl font-bold italic leading-9 tracking-tight text-gray-700"}>
            {t("login.erpTitle")}
          </h2>
          <nav className={isDesktopSidebarCollapsed ? "hidden" : "mt-2 flex flex-1 flex-col"}>
            <ul role="list" className="flex flex-1 flex-col gap-y-7">
              <li>
                <ul role="list" className="-mx-2 space-y-1">
                  {navigation.map((item) => (
                    <li key={item.key}>
                      {!item.children ? (
                        <Link
                          to={item.route ?? '/'}
                          onClick={() => handleSidebarClick(item.key)}
                          className={classNames(
                            item.current ? 'bg-gray-200' : 'hover:bg-gray-200',
                            'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold text-gray-900'
                          )}
                        >
                          <item.icon className="h-6 w-6 shrink-0 text-gray-900" aria-hidden="true" />
                          {item.name}
                        </Link>
                      ) : (
                        <Disclosure as="div" defaultOpen={expandedKeys.includes(item.key)}>
                          {({ open }) => (
                            <>
                              <Disclosure.Button className="flex items-center justify-start w-full px-2 py-2 text-sm text-left font-semibold text-gray-900 hover:bg-gray-200">
                                <item.icon className="h-6 w-6 shrink-0 text-gray-900" aria-hidden="true" />
                                <span className="ml-3">{item.name}</span>
                                <ChevronRightIcon className={classNames(open ? 'text-gray-600 rotate-90' : 'text-gray-600', 'ml-auto h-5 w-5 transform')} aria-hidden="true" />
                              </Disclosure.Button>
                              <Disclosure.Panel className="px-2 pt-2 pb-3 space-y-1">
                                {item.children && item.children.map((subItem) => (
                                  <Link
                                    to={subItem.route ?? '/'}
                                    key={subItem.key}
                                    onClick={() => handleSidebarClick(subItem.key)}
                                    className={classNames(
                                      subItem.current ? 'bg-gray-200' : 'hover:bg-gray-200',
                                      'group flex items-center gap-x-3 rounded-md p-2 text-sm font-semibold text-gray-600'
                                    )}
                                  >
                                    {subItem.icon ? <subItem.icon className="h-6 w-6 shrink-0" aria-hidden="true" /> : null}
                                    {subItem.name}
                                  </Link>
                                ))}
                              </Disclosure.Panel>
                            </>
                          )}
                        </Disclosure>
                      )}
                    </li>
                  ))}
                </ul>
              </li>
            </ul>
          </nav>
          <nav className="mt-2 flex flex-1 flex-col">
            <ul role="list" className="flex flex-1 flex-col gap-y-7">
              <li className="-mx-6 mt-auto">
                <button
                  onClick={() => setDesktopSidebarCollapsed(!isDesktopSidebarCollapsed)}
                  className="flex items-center justify-center gap-x-4 px-6 py-3 text-sm font-semibold leading-6 text-gray-900 hover:bg-gray-50 w-full"
                >
                  <span aria-hidden="true">
                    {isDesktopSidebarCollapsed ?
                      <ChevronDoubleRightIcon className="h-6 w-6 shrink-0" aria-hidden="true" />
                      :
                      <ChevronDoubleLeftIcon className="h-6 w-6 shrink-0" aria-hidden="true" />
                    }
                  </span>
                </button>
              </li>
            </ul>
          </nav>
        </div>
      </div>

      <div className={isDesktopSidebarCollapsed ? "lg:pl-24 transition-all" : "lg:pl-72 transition-all"}>
        <EnvironmentAlert message={process.env.REACT_APP_NOT_PRODUCTIVE_ENVIRONMENT_MESSAGE} />
        <div className="sticky top-0 z-50 flex h-16 shrink-0 items-center gap-x-4 border-b border-gray-200 bg-white px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8">
          <button
            type="button"
            className="-m-2.5 p-2.5 text-gray-700 lg:hidden"
            onClick={() => setSidebarOpen(true)}
          >
            <span className="sr-only">{t("dashboard.openSidebar")}</span>
            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
          </button>

          <div
            className="h-6 w-px bg-gray-900/10 lg:hidden"
            aria-hidden="true"
          />

          <div className="flex flex-1 gap-x-4 self-stretch lg:gap-x-6">
            <div className="relative flex flex-1">&nbsp;</div>
            <div className="flex items-center gap-x-4 lg:gap-x-6">
              <button
                type="button"
                className="-m-2.5 p-2.5 text-gray-400 hover:text-gray-500"
                data-tooltip-id="viewUserManual"
                data-tooltip-content={t("dashboard.viewUserManual")}
                data-tooltip-place="bottom"
                onClick={async () => {
                  setIsLoading(true);
                  await GetUserManual();
                  setIsLoading(false);
                }}
              >
                <span className="sr-only">{t("dashboard.viewUserManual")}</span>
                <Tooltip id="viewUserManual" />
                <BookOpenIcon className="h-6 w-6" aria-hidden="true" />
              </button>
              <button
                type="button"
                className="-m-2.5 p-2.5 text-gray-400 hover:text-gray-500"
              >
                <span className="sr-only">{t("dashboard.viewNotifications")}</span>
                <BellIcon className="h-6 w-6" aria-hidden="true" />
              </button>

              <div
                className="hidden lg:block lg:h-6 lg:w-px lg:bg-gray-900/10"
                aria-hidden="true"
              />

              <Menu as="div" className="relative">
                <Menu.Button className="-m-1.5 flex items-center p-1.5">
                  <span className="sr-only">{t("dashboard.openUserMenu")}</span>
                  <img
                    className="h-8 w-8 rounded-full bg-gray-50"
                    src={getImagePath("images/default-user-icon.webp")}
                    alt=""
                  />
                  <span className="hidden lg:flex lg:items-center">
                    <span
                      className="ml-4 text-sm font-semibold leading-6 text-gray-900"
                      aria-hidden="true"
                    >
                      {employee?.firstName} {employee?.lastName1}
                    </span>
                    <ChevronDownIcon
                      className="ml-2 h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </span>
                </Menu.Button>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="absolute right-0 z-10 mt-2.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                    {userNavigation.map((item) => (
                      <Menu.Item key={item.name}>
                        {({ active }) => (
                          <button
                            type="button"
                            onClick={item.onClick}
                            className={classNames(
                              active ? "bg-gray-50" : "",
                              "w-full text-left block px-3 py-1 text-sm leading-6 text-gray-900"
                            )}
                          >
                            {item.name}
                          </button>
                        )}
                      </Menu.Item>
                    ))}
                  </Menu.Items>
                </Transition>
              </Menu>
            </div>
          </div>
        </div>

        <main className="py-10">
          <div className="px-4 sm:px-6 lg:px-8">
            <Routes>
              {renderRoutes(navigationComplete)}
              <Route path="*" element={<NotFound />} />
            </Routes>
          </div>
        </main>
      </div>
    </div>
  )
}
