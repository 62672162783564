import { Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { CheckCircleIcon, XCircleIcon, ExclamationTriangleIcon, InformationCircleIcon } from '@heroicons/react/20/solid';
import { CheckIcon, MinusCircleIcon } from '@heroicons/react/24/outline'
import { useTranslation } from 'react-i18next';

const alertStyles = {
    success: 'border-green-400 bg-green-50 text-green-800',
    error: 'border-red-400 bg-red-50 text-red-800',
    warning: 'border-yellow-400 bg-yellow-50 text-yellow-800',
    info: 'border-blue-400 bg-blue-50 text-blue-800',
};

const alertIcons = {
    success: CheckCircleIcon,
    error: XCircleIcon,
    warning: ExclamationTriangleIcon,
    info: InformationCircleIcon,
};

type AlertTypes = 'success' | 'error' | 'warning' | 'info';

interface AlertModalProps {
    title: string;
    text: string;
    type: AlertTypes;
    isOpen: boolean;
    onClose: () => void;
}

const AlertModal: React.FC<AlertModalProps> = ({ title, text, type = 'info', isOpen, onClose }) => {
    const { t } = useTranslation();

    return (
        <Transition.Root show={isOpen} as={Fragment}>
            <Dialog as="div" className="relative z-50" onClose={onClose}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-xl sm:p-6">
                                <div>
                                    {type === "success" ? (
                                        <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
                                            <CheckIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
                                        </div>
                                    ) : type === "error" ? (
                                        <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-red-100">
                                            <MinusCircleIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                                        </div>
                                    ) : type === "warning" ? (
                                        <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-yellow-100">
                                            <ExclamationTriangleIcon className="h-6 w-6 text-yellow-600" aria-hidden="true" />
                                        </div>
                                    ) : (
                                        <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-blue-100">
                                            <InformationCircleIcon className="h-6 w-6 text-blue-600" aria-hidden="true" />
                                        </div>
                                    )}
                                    <div className="mt-3 text-center sm:mt-5">
                                        <Dialog.Title as="h3" className="text-xl font-semibold leading-6 text-gray-900">
                                            {title}
                                        </Dialog.Title>
                                        <div className="mt-6">
                                            {text.split('\n').map((line, index) => (
                                                line ? (
                                                    <p key={index} className="text-sm leading-6 text-justify text-gray-500">
                                                        {line}
                                                    </p>
                                                ) : (
                                                    <br key={index} />
                                                )
                                            ))}
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-7 sm:mt-8 flex justify-center">
                                    <button
                                        type="button"
                                        className="inline-flex w-60 justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                        onClick={onClose}
                                    >
                                        {type === "success" ? (
                                            t("common.close")
                                        ) : type === "error" ? (
                                            t("common.continue")
                                        ) : type === "warning" ? (
                                            t("common.understood")
                                        ) : (
                                            t("common.understood")
                                        )}
                                    </button>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    );
}

export default AlertModal;