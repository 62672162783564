import { useState, useEffect, Fragment } from 'react';
import { useTranslation } from "react-i18next";
import SectionHeader from "../../../components/common/header/SimpleHeader";
import { useNavigate } from 'react-router-dom';
import { useSpinner } from '../../../contexts/SpinnerContext';
import Alert from '../../../components/common/alert/Alert';
import { GetCompanies } from '../../../services/company/CompanyService';
import { Company } from '../../../interfaces/company/Company';
import Modal from '../../../components/common/overlay/Modal';
import WorkCenterAdd from './WorkCenterAdd';
import AlertModal from '../../../components/common/alert/AlertModal';

export default function CompanyMaintenance() {
  const { t, i18n } = useTranslation();
  const { isLoading, setIsLoading } = useSpinner(true);
  const [companyFilter] = useState<Record<string, any> | undefined>(undefined);
  const [companies, setCompanies] = useState<Company[]>();

  const [openedModalCompanyId, setOpenedModalCompanyId] = useState<number | undefined>();
  const [modalWorkCenterAddIsOpen, setModalWorkCenterAddIsOpen] = useState<boolean>(false);
  const [modalWorkCenterAddResult, setModalWorkCenterAddResult] = useState<string>("");

  const navigate = useNavigate();

  const fetchCompanies = async () => {
    const data = await GetCompanies(undefined, undefined, companyFilter);
    setCompanies(data || []);
  };

  useEffect(() => {
    fetchCompanies();
  }, [companyFilter]);

  useEffect(() => {
    if (companies) {
      setIsLoading(false);
    }
  }, [companies]);

  function classNames(...classes: any[]) {
    return classes.filter(Boolean).join(' ')
  }

  const openModal = (companyId: number) => {
    setOpenedModalCompanyId(companyId);
  };

  useEffect(() => {
    if (openedModalCompanyId !== undefined) {
      setModalWorkCenterAddIsOpen(true);
    }
  }, [openedModalCompanyId]);

  useEffect(() => {
    if (!modalWorkCenterAddIsOpen) {
      setTimeout(() => {
        closeModal();
      }, 300);
    }
  }, [modalWorkCenterAddIsOpen]);

  const closeModal = () => {
    setOpenedModalCompanyId(undefined);
  };

  const updateParentFromWorkCenterAdd = () => {
    setModalWorkCenterAddResult(t("workCenter.addWorkCenterSuccessInfo").toString());
    setModalWorkCenterAddIsOpen(false)
    fetchCompanies();
  };

  const isLoadingChildren = (state: boolean) => {
    setIsLoading(state)
  };

  return (
    <div className="px-2 sm:px-4">
      <AlertModal title={t("common.updatedOkTitle")} text={modalWorkCenterAddResult} type="success" isOpen={modalWorkCenterAddResult !== ""} onClose={() => setModalWorkCenterAddResult("")} />
      <SectionHeader title={t("maintenance.company")} textSize="text-3xl" />
      {!isLoading && companies &&
        <div className="mt-4">
          <p className="mt-2 text-sm italic text-gray-700">
            {t("maintenance.dataSectionSubtitle")}
          </p>
          <div className="mt-6">
            <div className="-mx-4 mt-4 sm:-mx-0">
              {companies && companies.length > 0 ? (
                <table className="min-w-full divide-y divide-gray-300">
                  <thead>
                    <tr>
                      <th className="pl-3 py-3 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">{t("company.codeName")}</th>
                      <th className="py-3 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">{t("workCenter.namePlural")}</th>
                      <th className="py-3 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">&nbsp;</th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {companies && companies.length > 0 && companies.map((company, rowIndex) => (
                      <Fragment key={company.id}>
                        <tr className="border-t border-gray-200">
                          <td
                            scope="colgroup"
                            className="bg-gray-50 py-2 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3"
                          >
                            {company.code} - {company.name}
                          </td>
                          <td
                            scope="colgroup"
                            className="bg-gray-50 py-2 pr-3 text-left text-sm font-semibold text-gray-900"
                          >
                            <button
                              className="rounded-md bg-indigo-50 px-2.5 py-1.5 text-sm font-semibold text-indigo-600 shadow-sm hover:bg-indigo-100"
                              onClick={() => openModal(company.id)}
                            >
                              {t("maintenance.addWorkCenter")}
                            </button>
                            {openedModalCompanyId === company.id && (
                              <Modal isOpen={modalWorkCenterAddIsOpen} onClose={() => setModalWorkCenterAddIsOpen(false)}>
                                <WorkCenterAdd
                                  companyId={company.id}
                                  onUpdateParent={updateParentFromWorkCenterAdd}
                                  closeModal={() => setModalWorkCenterAddIsOpen(false)}
                                  isLoading={isLoadingChildren}
                                />
                              </Modal>
                            )}
                          </td>
                          <td
                            scope="colgroup"
                            className="bg-gray-50 py-2 pr-3 text-right text-sm font-semibold text-gray-900"
                          >
                            <button
                              className="rounded-md bg-indigo-50 px-2.5 py-1.5 text-sm font-semibold text-indigo-600 shadow-sm hover:bg-indigo-100"
                              onClick={() => {
                                navigate(`/companies/${company.id}`)
                              }}
                            >
                              {t("company.viewDetail")}
                            </button>
                          </td>
                        </tr>
                        {company.workCenters?.map((workCenter, personIdx) => (
                          <tr
                            key={workCenter.id}
                            className={classNames(personIdx === 0 ? 'border-gray-300' : 'border-gray-200', 'border-t')}
                          >
                            <td className="">
                              &nbsp;
                            </td>
                            <td className="text-sm font-medium text-gray-900">
                              {workCenter.code} - {workCenter.name}
                            </td>
                            <td className="py-4 pl-3 pr-3 text-right text-sm font-medium">
                              <button
                                type="button"
                                className="rounded-md bg-indigo-50 px-2.5 py-1.5 text-sm font-semibold text-indigo-600 shadow-sm hover:bg-indigo-100"
                                onClick={() => {
                                  navigate(`/work-centers/${workCenter.id}`)
                                }}
                              >
                                {t("workCenter.viewDetail")}<span className="sr-only">, {workCenter.name}</span>
                              </button>
                            </td>
                          </tr>
                        ))}
                      </Fragment>
                    ))}
                  </tbody>
                </table>
              ) : (
                <Alert type="warning" text={t("common.noData")} />
              )}
            </div>
          </div>
        </div>
      }
    </div>
  )
}
