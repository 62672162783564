import { useTranslation } from "react-i18next";
import { NotificationContext } from '../../../contexts/NotificationContext';
import { NotificationTypes } from "../../../components/common/Notification";
import Form from "../../../components/common/form/Form";
import { FormField } from "../../../components/common/form/FormField";
import { useContext, useEffect, useRef, useState } from "react";
import { DefaultEmployee, Employee } from "../../../interfaces/employee/Employee";
import * as Yup from 'yup';
import { FormikProps } from "formik";
import { DefaultWorkCenter, WorkCenter } from "../../../interfaces/work-center/WorkCenter";
import { GetWorkCenters, PostWorkCenter } from "../../../services/company/CompanyService";
import { DefaultUser, User } from "../../../interfaces/user/User";
import { PostEmployee } from "../../../services/employee/EmployeeService";
import AlertModal from "../../../components/common/alert/AlertModal";

interface WorkCenterAddProps {
    companyId: number;
    onUpdateParent: () => void;
    isLoading: (state: boolean) => void;
    closeModal: () => void;
}

export default function WorkCenterAdd(props: WorkCenterAddProps) {
    const { t } = useTranslation();
    const [workCenter, setWorkCenter] = useState<WorkCenter>(DefaultWorkCenter());
    const { showNotification } = useContext(NotificationContext);
    const [updateErrors, setUpdateErrors] = useState<string>("");
    const [validationErrors, setValidationErrors] = useState<string>("");

    const workCenterFormRef = useRef<FormikProps<WorkCenter>>();

    const workCenterValidationSchema = Yup.object({
        code: Yup.string().required('El código es obligatorio').max(10, "El código no puede tener más de 10 caracteres"),
        name: Yup.string().required('El nombre es obligatorio').max(100, 'El nombre no puede tener más de 100 caracteres'),
    });

    const handleValidateAndSubmit = async () => {
        if (workCenterFormRef.current) {
            const workCenterValues = await workCenterFormRef.current.submitForm();
            const workCenterErrors = await workCenterFormRef.current.validateForm();

            if (Object.keys(workCenterErrors).length === 0) {
                addWorkCenter(workCenterValues!);
            } else {
                setValidationErrors(Object.values(workCenterErrors).map(error => `- ${error}`).join('\n'));
            }
        }
    };

    const addWorkCenter = async (workCenter: WorkCenter) => {
        workCenter.companyId = props.companyId;
        props.isLoading(true);
        const result = await PostWorkCenter(workCenter);
        if (result) {
            props.isLoading(false);
            if (typeof result === 'string') {
                props.isLoading(false);
                setValidationErrors(result);
            } else {
                props.onUpdateParent();
                props.closeModal();
            }
        } else {
            props.isLoading(false);
            setUpdateErrors(t("common.updatedErrorSubtitle").toString());
        }
    };

    return (
        <>
            <h3 className="mt-0 mb-2 text-xl font-medium leading-6 text-gray-900">
                {t("maintenance.addWorkCenter")}
            </h3>
            <p className="mt-4 text-sm italic text-justify text-red-700">
                Esta herramienta le permite añadir de manera excepcional un centro de trabajo a la empresa cuando todavía no se haya cargado la información en la sincronización con Sage.
            </p>
            <p className="mt-4 mb-6 text-sm italic text-justify text-red-700">
                Por favor, verifique que los datos son correctos antes de guardar.
            </p>
            <AlertModal title={t("common.updatedErrorTitle")} text={t(updateErrors)} type="error" isOpen={updateErrors !== ""} onClose={() => setUpdateErrors("")} />
            <AlertModal title={t("common.errorDataValidation")} text={t(validationErrors)} type="warning" isOpen={validationErrors !== ""} onClose={() => setValidationErrors("")} />
            <Form initialValues={workCenter} validationSchema={workCenterValidationSchema} ref={workCenterFormRef}>
                <div className="space-y-7">
                    <div className="border-t border-b border-gray-900/10 pt-2 pb-10">
                        <div className="mt-4 grid gap-x-6 gap-y-8 grid-cols-6">
                            <FormField colspan={2} label={t("workCenter.codeField")} name="code" />
                            <FormField colspan={4} label={t("workCenter.nameField")} name="name" />
                        </div>
                    </div>
                    <div className="flex justify-end mb-6 space-x-4">
                        <button
                            type="button"
                            className="mb-1 rounded-md bg-orange-50 px-3.5 py-2.5 text-sm font-semibold text-orange-600 shadow-sm hover:bg-orange-100"
                            onClick={() => props.closeModal()}
                        >
                            {t("common.cancel")}
                        </button>
                        <button
                            type="button"
                            className="mb-1 rounded-md bg-indigo-50 px-3.5 py-2.5 text-sm font-semibold text-indigo-600 shadow-sm hover:bg-indigo-100"
                            onClick={() => handleValidateAndSubmit()}
                        >
                            {t("maintenance.addWorkCenter")}
                        </button>
                    </div>
                </div>
            </Form>
        </>
    )
}