import './App.css';
import { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Login from "./pages/authentication/Login";
import Dashboard from "./pages/menu/Dashboard";
import Notification from "./components/common/Notification";
import { GetCookie } from "./services/cookie/CookieService";
import NotFound from './pages/not-found/NotFound';
import Profile from './pages/employee/Profile';
import { ContextProvider } from './contexts/ContextProvider';

export interface AuthProps {
  setIsLoggedIn: React.Dispatch<React.SetStateAction<boolean | undefined>>;
}

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState<boolean | undefined>(undefined);

  useEffect(() => {
    const token = GetCookie("token");
    if (token) {
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }
  }, []);

  if (isLoggedIn === undefined) {
    return <div>Cargando...</div>;
  }

  return (
    <ContextProvider>
      <Router>
        <Routes>
          {isLoggedIn ? (
            <>
              <Route path="/profile" element={<Profile />} />
              <Route path="/*" element={<Dashboard setIsLoggedIn={setIsLoggedIn} />} />
            </>
          ) : (
            <>
              <Route path="/*" element={<Login setIsLoggedIn={setIsLoggedIn} />} />
            </>
          )}
          <Route path="*" element={<NotFound />} />
        </Routes>
        <Notification />
      </Router>
    </ContextProvider>
  );
}

export default App;