import React, { ReactNode } from 'react';
import { NotificationProvider } from './NotificationContext';
import { SpinnerProvider } from './SpinnerContext';
import { PageStateProvider } from './PageStateContext';

interface ContextProviderProps {
    children: ReactNode;
}

export const ContextProvider: React.FC<ContextProviderProps> = ({ children }) => (
    <NotificationProvider>
        <SpinnerProvider>
            <PageStateProvider>
                {children}
            </PageStateProvider>
        </SpinnerProvider>
    </NotificationProvider>
);