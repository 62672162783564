import React from 'react';
import { DTOTimeControlEmployeeSummary } from '../../../interfaces/time-control/DTOTimeControlEmployeeSummary';
import { useTranslation } from 'react-i18next';
import { ChartBarSquareIcon, CheckIcon, ExclamationTriangleIcon, PlayIcon } from '@heroicons/react/24/outline';
import { List, Popover, Tooltip } from 'antd';
import { TimeControlSummaryByEmployeeStatusType } from '../../../resources/utils/enums';

interface TimeControlSummaryStatsProps {
    timeControlSummary: DTOTimeControlEmployeeSummary | null;
}

const TimeControlSummaryStats: React.FC<TimeControlSummaryStatsProps> = ({ timeControlSummary }) => {
    const { t } = useTranslation();

    return (
        <>
            {timeControlSummary &&
                <div className="mt-4 mx-0.5">
                    <dl className="mt-5 grid grid-cols-5 divide-x divide-y-0 divide-gray-200 overflow-hidden rounded-lg bg-zinc-50 shadow">
                        <div className="px-4 py-5">
                            <dd className="h-full flex justify-center items-center">
                                <div className="flex items-baseline text-2xl font-semibold text-indigo-600">
                                    {timeControlSummary.status === TimeControlSummaryByEmployeeStatusType.InProgress ? (
                                        <Tooltip placement="topLeft" title={t("timeControl.yearInProgress")} color="geekblue">
                                            <PlayIcon className="h-12 w-12 text-indigo-500" />
                                        </Tooltip>
                                    ) : timeControlSummary.status === TimeControlSummaryByEmployeeStatusType.Validated ? (
                                        <Tooltip placement="topLeft" title={t("timeControl.yearValidated")} color="geekblue">
                                            <CheckIcon className="h-12 w-12 text-green-500" />
                                        </Tooltip>
                                    ) : timeControlSummary.status === TimeControlSummaryByEmployeeStatusType.NotValidated ? (
                                        <Tooltip placement="topLeft" title={t("timeControl.yearNonValidated")} color="red">
                                            <ExclamationTriangleIcon className="h-12 w-12 text-red-600" />
                                        </Tooltip>
                                    ) : null}
                                </div>
                            </dd>
                        </div>
                        <Tooltip placement="top" title={t("timeControl.annualExpectedWorkDaysTooltip")} color="geekblue">
                            <div className="px-4 py-5">
                                <dt className="text-sm font-normal text-gray-900">{t("timeControl.annualExpectedWorkDays")}</dt>
                                <dd className="mt-1 flex items-baseline justify-between md:block lg:flex">
                                    <div className="flex items-baseline text-2xl font-semibold text-indigo-600">
                                        {timeControlSummary.expectedWorkDays}
                                    </div>
                                </dd>
                            </div>
                        </Tooltip>
                        <Tooltip placement="top" title={t("timeControl.annualRealWorkDaysTooltip")} color="geekblue">
                            <div className="px-4 py-5">
                                <dt className="text-sm font-normal text-gray-900">{t("timeControl.annualRealWorkDays")}</dt>
                                <dd className="mt-1 flex items-baseline justify-between md:block lg:flex">
                                    <div className="flex items-baseline text-2xl font-semibold text-indigo-600">
                                        {timeControlSummary.realWorkDays}
                                    </div>
                                </dd>
                            </div>
                        </Tooltip>
                        <div className="px-4 py-5">
                            <Tooltip placement="top" title={t("timeControl.annualExpectedWorkTimeTooltip")} color="geekblue">
                                <dt className="text-sm font-normal text-gray-900">{t("timeControl.annualExpectedWorkTime")}</dt>
                            </Tooltip>
                            <dd className="mt-1 flex items-baseline justify-between md:block lg:flex">
                                <Tooltip placement="top" title={t("timeControl.annualExpectedWorkTimeTooltip")} color="geekblue">
                                    <div className="flex items-baseline text-2xl font-semibold text-indigo-600">
                                        {timeControlSummary.expectedWorkTime}
                                    </div>
                                </Tooltip>
                                <Popover
                                    placement="topLeft"
                                    title={t("timeControl.annualExpectedTimeStatisticsPopoverTitle")}
                                    content={
                                        <div className="flex flex-col w-64 -mt-2">
                                            <List
                                                itemLayout="horizontal"
                                                dataSource={timeControlSummary.expectedTimeBreakdowns}
                                                locale={{
                                                    emptyText: (
                                                        <div className="flex flex-col items-center justify-center text-gray-400">
                                                            {t("common.noDataInPeriod")}
                                                        </div>
                                                    )
                                                }}
                                                renderItem={(item, _) => (
                                                    <List.Item>
                                                        <List.Item.Meta
                                                            title={item.name}
                                                            description={item.computedTime}
                                                        />
                                                    </List.Item>
                                                )}
                                            />
                                        </div>
                                    }
                                >
                                    <ChartBarSquareIcon className="h-6 w-6 text-amber-500" aria-hidden="true" />
                                </Popover>
                            </dd>
                        </div>
                        <div className="px-4 py-5">
                            <Tooltip placement="top" title={t("timeControl.annualComputedTimeTooltip")} color="geekblue">
                                <dt className="text-sm font-normal text-gray-900">{t("timeControl.annualComputedTime")}</dt>
                            </Tooltip>
                            <dd className="mt-1 flex items-baseline justify-between md:block lg:flex">
                                <Tooltip placement="top" title={t("timeControl.annualComputedTimeTooltip")} color="geekblue">
                                    <div className="flex items-baseline text-2xl font-semibold text-indigo-600">
                                        {timeControlSummary.computedTime}
                                    </div>
                                </Tooltip>
                                <Popover
                                    placement="topLeft"
                                    title={t("timeControl.annualComputedTimeStatisticsPopoverTitle")}
                                    content={
                                        <div className="flex flex-col w-64 -mt-2">
                                            <List
                                                itemLayout="horizontal"
                                                dataSource={timeControlSummary.computedTimeBreakdowns}
                                                locale={{
                                                    emptyText: (
                                                        <div className="flex flex-col items-center justify-center text-gray-400">
                                                            {t("common.noDataInPeriod")}
                                                        </div>
                                                    )
                                                }}
                                                renderItem={(item, _) => (
                                                    <List.Item>
                                                        <List.Item.Meta
                                                            title={item.name}
                                                            description={item.computedTime}
                                                        />
                                                    </List.Item>
                                                )}
                                            />
                                        </div>
                                    }
                                >
                                    <ChartBarSquareIcon className="h-6 w-6 text-amber-500" aria-hidden="true" />
                                </Popover>
                            </dd>
                        </div>
                        <div className="px-4 py-5">
                            <dt className="text-sm font-normal text-gray-900">&nbsp;</dt>
                            <dd className="mt-1 flex items-baseline justify-between md:block lg:flex">
                                &nbsp;
                            </dd>
                        </div>
                        <Tooltip placement="top" title={t("timeControl.annualDesviationTimeTooltip")} color="geekblue">
                            <div className="px-4 py-5">
                                <dt className="text-sm font-normal text-gray-900">{t("timeControl.annualDesviationTime")}</dt>
                                <dd className="mt-1 flex items-baseline justify-between md:block lg:flex">
                                    <div className={`flex items-baseline text-2xl font-semibold ${timeControlSummary.desviationTime.startsWith('-') ? 'text-green-500' : 'text-red-500'}`}>
                                        {timeControlSummary.desviationTime}
                                    </div>
                                </dd>
                            </div>
                        </Tooltip>
                        <Tooltip placement="top" title={t("timeControl.annualDesviationPercentTooltip")} color="geekblue">
                            <div className="px-4 py-5">
                                <dt className="text-sm font-normal text-gray-900">{t("timeControl.annualDesviationPercent")}</dt>
                                <dd className="mt-1 flex items-baseline justify-between md:block lg:flex">
                                    <div className={`flex items-baseline text-2xl font-semibold ${timeControlSummary.desviationPercent >= 0 ? 'text-green-500' : 'text-red-500'}`}>
                                        {timeControlSummary.desviationPercent} %
                                    </div>
                                </dd>
                            </div>
                        </Tooltip>
                        <div className="px-4 py-5">
                            <dt className="text-sm font-normal text-gray-900">{t("timeControl.annualIncidencesCount")}</dt>
                            <dd className="mt-1 flex items-baseline justify-between md:block lg:flex">
                                {timeControlSummary.incidencesCount > 0 ? (
                                    <div className="flex items-baseline text-2xl font-semibold text-orange-600">
                                        {timeControlSummary.incidencesCount}
                                    </div>
                                ) : (
                                    <div className="flex items-baseline text-2xl font-semibold text-green-600">
                                        {timeControlSummary.incidencesCount}
                                    </div>
                                )}
                            </dd>
                        </div>
                        <Tooltip placement="top" title={t("employee.timeBalanceTooltip")} color="geekblue">
                            <div className="px-4 py-5">
                                <dt className="text-sm font-normal text-gray-900">{t("employee.timeBalance")}</dt>
                                <dd className="mt-1 flex items-baseline justify-between md:block lg:flex">
                                    <div className={`flex items-baseline text-2xl font-semibold ${timeControlSummary.timeBalance.toString().startsWith("-") ? 'text-green-500' : (timeControlSummary.timeBalance.toString() === '00:00' ? 'text-indigo-600' : 'text-red-500')}`}>
                                        {timeControlSummary.timeBalance}
                                    </div>
                                </dd>
                            </div>
                        </Tooltip>
                    </dl>
                </div>
            }
        </>
    );
};

export default TimeControlSummaryStats;