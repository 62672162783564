import { WorkCenter } from "../work-center/WorkCenter";
import { Employee } from "../employee/Employee";
import { CalendarDay } from "./CalendarDay";
import { AnnualCalendar } from "../common/calendar/AnnualCalendar";

export interface Calendar {
    id: number;
    year: number;
    workCenterId?: number;
    workCenter?: WorkCenter;
    employeeId?: number;
    employee?: Employee;
    name?: string;
    remarks?: string;
    isDefault?: boolean;
    calendarDays?: CalendarDay[];
    annualCalendar?: AnnualCalendar | null;
}

export function DefaultCalendar(): Calendar {
    return {
        id: 0,
        year: new Date().getFullYear(),
        name: "",
        isDefault: false,
    };
}