import { useTranslation } from 'react-i18next';

interface PaginationProps {
    totalPages: number;
    currentPage: number;
    itemsPerPage: number;
    totalItems: number;
    onPageChange: (newPage: number) => void;
}

export default function Pagination({ totalPages, currentPage, itemsPerPage, totalItems, onPageChange }: PaginationProps) {
    const { t } = useTranslation();

    const startResult = ((currentPage - 1) * itemsPerPage) + 1;
    const endResult = currentPage === totalPages ? totalItems : currentPage * itemsPerPage;

    const getPageNumbers = () => {
        const range = (start: number, end: number) => Array.from({ length: (end - start + 1) }, (_, i) => i + start);

        let pages: (number | string)[] = [];

        const startPages = range(1, Math.min(3, totalPages));
        const endPages = range(Math.max(totalPages - 2, 1), totalPages);

        pages.push(...startPages);

        const shouldInsertSelect = startPages[startPages.length - 1] !== endPages[0] - 1;

        if (shouldInsertSelect && typeof pages[pages.length - 1] === 'number' && endPages[0] - (pages[pages.length - 1] as number) > 1) {
            pages.push('select');
        }
        pages.push(...endPages);

        const uniquePages: (number | string)[] = [];
        pages.forEach(p => {
            if (!uniquePages.includes(p)) uniquePages.push(p);
        });

        return uniquePages;
    };

    const intermediatePages = () => {
        let start = 4;
        let end = totalPages - 3;
        return Array.from({ length: end - start + 1 }, (_, i) => i + start);
    };

    const pageNumbers = getPageNumbers();

    return (
        <div className="flex items-center justify-between bg-white px-4 py-3 mt-5">
            <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
                <div>
                    <p className="text-sm text-gray-700">
                        {t("pagination.showing")} <span className="font-medium">{startResult}</span> {t("pagination.to")} <span className="font-medium">{endResult}</span> {t("pagination.of")}{' '}
                        <span className="font-medium">{totalItems}</span> {t("pagination.results")}
                    </p>
                </div>
                <div>
                    <nav className="isolate inline-flex -space-x-px rounded-md shadow-sm" aria-label="Pagination">
                        {pageNumbers.map((number, index) => {
                            const isStart = index === 0;
                            const isEnd = index === pageNumbers.length - 1;

                            return number === 'select' ? (
                                <select
                                    key="select"
                                    onChange={(e) => {
                                        if (e.target.value) {
                                            onPageChange(Number(e.target.value))
                                        }
                                    }}
                                    value={currentPage}
                                    className={`relative inline-flex items-center px-4 py-2 text-sm font-medium text-gray-900 custom-select hover:bg-gray-50 focus:z-20 focus:outline-offset-0 ${isStart ? 'rounded-l-md' : ''} ${isEnd ? 'rounded-r-md' : ''}`}
                                >
                                    <option value="">...</option>
                                    {intermediatePages().map(num => (
                                        <option key={num} value={num}>
                                            {num}&nbsp;&nbsp;&nbsp;
                                        </option>
                                    ))}
                                </select>
                            ) : (
                                <button
                                    key={number as number}
                                    onClick={() => onPageChange(number as number)}
                                    className={`relative inline-flex items-center px-4 py-2 text-sm font-medium ${number === currentPage ? 'bg-indigo-600 text-white' : 'text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50'} focus:z-20 focus:outline-offset-0 ${isStart ? 'rounded-l-md' : ''} ${isEnd ? 'rounded-r-md' : ''}`}
                                >
                                    {number}
                                </button>
                            );
                        })}
                    </nav>
                </div>
            </div>
        </div>
    )
}