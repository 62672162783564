import { axiosRRHHEmployeeAPI } from '../axios/AxiosRRHHEmployeeAPI';

export async function AddResource(
    file: File
): Promise<number | null> {
    try {
        const formData = new FormData();
        formData.append("file", file);

        const response = await axiosRRHHEmployeeAPI.post("/api/resource",
            formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            }
        );

        if (response.status === 200) {
            return response.data;
        }
    } catch (error) {
        console.error('Error en la solicitud:', error);
    }
    return null;
}

export async function UpdateResource(
    resourceId: number,
    file: File
): Promise<number> {
    try {
        const formData = new FormData();
        formData.append("file", file);

        const response = await axiosRRHHEmployeeAPI.post(`/api/company/UpdateResource/${resourceId}`,
            formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            }
        );

        if (response.status === 200) {
            return response.data;
        }
    } catch (error) {
        console.error('Error en la solicitud:', error);
    }
    return -1;
}