import { AxiosError } from "axios";
import { User } from "../../interfaces/user/User";
import { axiosRRHHEmployeeAPI } from '../axios/AxiosRRHHEmployeeAPI';

export async function GetUser(
    userId: number
): Promise<User | null> {
    try {
        const response = await axiosRRHHEmployeeAPI.get(`/api/user/${userId}`);

        if (response.status === 200) {
            return response.data;
        }
    } catch (error) {
        console.error('Error en la solicitud:', error);
    }
    return null;
}

export async function PutUser(
    user: User
): Promise<boolean | string> {
    try {
        const response = await axiosRRHHEmployeeAPI.put("/api/user", user);

        if (response.status === 200) {
            return true;
        }
    } catch (error) {
        console.error('Error en la solicitud:', error);
        const axiosError = error as AxiosError;
        if (axiosError.response && axiosError.response.data && typeof axiosError.response.data === "string") {
            return axiosError.response.data;
        }
    }
    return false;
}