import { useState, useEffect, useContext, useRef, useCallback } from 'react';
import { useTranslation } from "react-i18next";
import { NotificationContext } from '../../../contexts/NotificationContext';
import { CalendarDaysIcon, BuildingOffice2Icon } from '@heroicons/react/20/solid'
import Tabs from '../../../components/common/navigation/Tabs';
import { useNavigate, useParams } from 'react-router-dom';
import SimpleHeaderWithTwoButtons from '../../../components/common/header/SimpleHeaderWithTwoButtons';
import { DefaultWorkCenter, WorkCenter } from '../../../interfaces/work-center/WorkCenter';
import { DeleteWorkCenterDepartment, DeleteWorkCenterTimeControlResponsible, GetCompanies, GetDepartmentsByWorkCenter, GetTimeControlResponsiblesByWorkCenter, GetWorkCenter, PatchWorkCenter, PostWorkCenter } from '../../../services/company/CompanyService';
import { NotificationTypes } from '../../../components/common/Notification';
import * as Yup from 'yup';
import Form from '../../../components/common/form/Form';
import { FormikProps } from 'formik';
import { useSpinner } from '../../../contexts/SpinnerContext';
import AlertModal from '../../../components/common/alert/AlertModal';
import { getFirstTab, userHasPermission } from '../../../resources/utils/common';
import TableDynamic from '../../../components/common/list/TableDynamic';
import Modal from '../../../components/common/overlay/Modal';
import WorkCenterDepartmentAdd from './WorkCenterDepartmentAdd';
import WorkCenterTimeControlResponsibleAdd from './WorkCenterTimeControlResponsibleAdd';
import { Modal as AntdModal, Button, Select, Space, Table, TableColumnsType, Tooltip } from 'antd';
import { UseCalendarsByWorkCenterId } from '../../../hooks/calendar/UseCalendar';
import { Calendar } from '../../../interfaces/calendar/Calendar';
import { ArrowDownOnSquareIcon, ArrowTrendingUpIcon, EyeIcon, PlusIcon, TrashIcon } from '@heroicons/react/24/outline';
import { StarIcon } from '@heroicons/react/24/solid';
import { DeleteCalendar, GetBulkAllocationToEmployeesByWorkCenterId, PostBulkAllocationToEmployeesByCalendarId, PostBulkAllocationToWorkCentersByCalendarId } from '../../../services/calendar/CalendarService';
import { showConfirmationModal } from '../../../components/common/overlay/ConfirmationModal';
import { usePageState } from '../../../contexts/PageStateContext';
import { DTOEmployeeOptions } from '../../../interfaces/employee/DTOEmployeeOptions';
import ModalAdvanced from '../../../components/common/overlay/ModalAdvanced';
import { Company } from '../../../interfaces/company/Company';

export default function WorkCenterDetail() {
  const pageName = 'WorkCenterDetail';
  const { t } = useTranslation();
  const { showNotification } = useContext(NotificationContext);
  const { setIsLoading } = useSpinner(true);
  const { state, dispatch } = usePageState();

  const navigate = useNavigate();
  const { id } = useParams();

  const [workCenter, setWorkCenter] = useState<WorkCenter>(DefaultWorkCenter());
  const [validationErrors, setValidationErrors] = useState<string>("");
  const [modalWorkCenterDepartmentAddIsOpen, setModalWorkCenterDepartmentAddIsOpen] = useState<boolean>(false);
  const [modalWorkCenterTimeControlResponsibleAddIsOpen, setModalWorkCenterTimeControlResponsibleAddIsOpen] = useState<boolean>(false);
  const [calendarList, calendarRefresh] = UseCalendarsByWorkCenterId(Number(id));

  const [bulkAllocationToEmployeesEmployees, setBulkAllocationToEmployeesEmployees] = useState<DTOEmployeeOptions[]>([]);
  const [bulkAllocationToEmployeesIsLoading, setBulkAllocationToEmployeesIsLoading] = useState(false);
  const [bulkAllocationToEmployeesModal, setBulkAllocationToEmployeesModal] = useState<number | null>(null);
  const [bulkAllocationToEmployeesSelectedEmployees, setBulkAllocationToEmployeesSelectedEmployees] = useState<number[]>([]);

  const [bulkAllocationToWorkCentersWorkCenters, setBulkAllocationToWorkCentersWorkCenters] = useState<Company[]>([]);
  const [bulkAllocationToWorkCentersIsLoading, setBulkAllocationToWorkCentersIsLoading] = useState(false);
  const [bulkAllocationToWorkCentersModal, setBulkAllocationToWorkCentersModal] = useState<number | null>(null);
  const [bulkAllocationToWorkCentersSelectedWorkCenters, setBulkAllocationToWorkCentersSelectedWorkCenters] = useState<number[]>([]);

  const workCenterFormRef = useRef<FormikProps<WorkCenter>>();

  const tabs = [
    { key: "generalData", name: t("workCenter.generalData"), icon: BuildingOffice2Icon, current: true, permission: ["roleRRHH"] },
    { key: "calendar", name: t("calendar.title"), icon: CalendarDaysIcon, current: false },
  ];
  const sectionKey = "maintenance__companies";
  const [activeTab, setActiveTab] = useState<string>(state[pageName]?.activeTab || getFirstTab(tabs, sectionKey) || "workshiftsMainView");

  const changeTab = (tabName: string) => {
    setActiveTab(tabName);
    dispatch({ type: 'SET_PAGE_STATE', payload: { pageKey: pageName, state: { ...state[pageName], activeTab: tabName } } });
  };

  useEffect(() => {
    fetchWorkCenter();
  }, []);

  const fetchWorkCenter = async () => {
    const workCenter = await GetWorkCenter(Number(id)) || DefaultWorkCenter();
    setIsLoading(false);
    setWorkCenter(workCenter);
  };

  const workCenterValidationSchema = Yup.object({});

  const handleValidateAndSubmit = async () => {
    if (workCenterFormRef.current) {
      const workCenterValues = await workCenterFormRef.current.submitForm();
      const workCenterErrors = await workCenterFormRef.current.validateForm();

      if (Object.keys(workCenterErrors).length === 0) {
        updateWorkCenter(workCenterValues!);
      } else {
        showNotification({ color: NotificationTypes.Danger, messageTitle: t("common.errorDataValidation"), messageText: Object.values(workCenterErrors).map(error => `- ${error}`).join('\n') });
      }
    }
  };

  const updateWorkCenter = async (workCenter: WorkCenter) => {
    setIsLoading(true);
    if (workCenter.id === 0) {
      await PostWorkCenter(workCenter).then((result) => {
        setIsLoading(false);
        if (result) {
          showNotification({ color: NotificationTypes.Success, messageTitle: t("common.updatedOkTitle"), messageText: t("common.updatedOkSubtitle") });
        }
      });
    } else {
      await PatchWorkCenter(workCenter.id, [
        { fieldName: "responsibleEmployeeId", fieldValue: workCenter.responsibleEmployeeId },
      ]).then((result) => {
        setIsLoading(false);
        if (result) {
          showNotification({ color: NotificationTypes.Success, messageTitle: t("common.updatedOkTitle"), messageText: t("common.updatedOkSubtitle") });
        }
      });
    }
  };

  const fetchWorkCenterDepartmentsData = useCallback(async () => {
    if (workCenter && workCenter.id > 0) {
      return await GetDepartmentsByWorkCenter(workCenter.id);
    }
  }, [workCenter]);

  const fetchWorkCenterTimeControlResponsiblesData = useCallback(async () => {
    if (workCenter && workCenter.id > 0) {
      return await GetTimeControlResponsiblesByWorkCenter(workCenter.id);
    }
  }, [workCenter]);

  const updateParentFromEmployeeAdd = () => {
    setWorkCenter({ ...workCenter });
  };

  const closeWorkCenterDepartmentAddModal = () => {
    setModalWorkCenterDepartmentAddIsOpen(false)
  };

  const closeWorkCenterTimeControlResponsibleAddModal = () => {
    setModalWorkCenterTimeControlResponsibleAddIsOpen(false)
  };

  const isLoadingChildren = (state: boolean) => {
    setIsLoading(state)
  };

  const filterOption = (input: string, option: any) =>
    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;

  const calendarColumns: TableColumnsType<Calendar> = [
    {
      title: '',
      key: 'operation',
      align: 'center',
      className: 'min-w-min w-[50px]',
      render: (_, calendar) => (
        <div className="flex items-center justify-center space-x-2 ml-1">
          <Tooltip placement="topLeft" title={t("common.edit")} color="geekblue">
            <button
              className="rounded-md bg-indigo-50 px-2 py-1 text-sm font-semibold text-indigo-600 shadow-sm hover:bg-indigo-100"
              onClick={() => navigate(`/work-centers/calendar/${Number(id)}/${calendar.id}`)}
            >
              <EyeIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </Tooltip>

          <Tooltip placement="topLeft" title={t("common.delete")} color="red">
            <button
              className="rounded-md bg-red-50 px-2 py-1 text-sm font-semibold text-red-600 shadow-sm hover:bg-red-100"
              onClick={() => {
                showConfirmationModal({
                  title: t("common.confirmation"),
                  content: <div>{t("common.deleteConfirm")}</div>,
                  okText: t("common.delete"),
                  cancelText: t("common.cancel"),
                  onConfirm: async () => {
                    await DeleteCalendar(calendar.id)
                      .then(async (result) => {
                        if (result) {
                          await calendarRefresh();
                        }
                        setIsLoading(false);
                      });
                  },
                });
              }}
            >
              <TrashIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </Tooltip>

          <Tooltip placement="topLeft" title={t("calendar.bulkAllocationToEmployees")} color="green">
            <button className="rounded-md bg-green-50 px-2 py-1 text-sm font-semibold text-green-600 shadow-sm hover:bg-green-100" onClick={() => (setBulkAllocationToEmployeesModal(calendar.id))}>
              <ArrowTrendingUpIcon className="h-5 w-5" aria-hidden="true" />
            </button >
          </Tooltip>
          <ModalAdvanced
            title={t("calendar.bulkAllocationToEmployees")}
            subtitle={t("calendar.bulkAllocationToEmployeesSubtitle").toString()}
            isOpen={bulkAllocationToEmployeesModal === calendar.id}
            onClose={() => setBulkAllocationToEmployeesModal(null)}
            onOk={async () => {
              setIsLoading(true);
              await PostBulkAllocationToEmployeesByCalendarId(calendar.id, bulkAllocationToEmployeesSelectedEmployees).then((isSuccessful) => {
                if (isSuccessful) {
                  setBulkAllocationToEmployeesModal(null)
                  showNotification({ color: NotificationTypes.Success, messageTitle: t("calendar.bulkAllocationToEmployeesSuccessfulTitle"), messageText: t("calendar.bulkAllocationToEmployeesSuccessfulSubtitle") });
                }
              })
              setIsLoading(false);
            }}
          >
            <Space style={{ width: '100%' }} direction="vertical">
              <Select
                mode="multiple"
                showSearch
                labelInValue
                style={{ width: '100%' }}
                placeholder=""
                filterOption={filterOption}
                onChange={(selectedOptions) => {
                  const selectedEmployeeIds = selectedOptions.map((option: { value: any; }) => option.value);
                  setBulkAllocationToEmployeesSelectedEmployees(selectedEmployeeIds);
                }}
                onDropdownVisibleChange={async open => {
                  if (open && bulkAllocationToEmployeesEmployees.length === 0 && calendar.workCenterId) {
                    setBulkAllocationToEmployeesIsLoading(true);
                    await GetBulkAllocationToEmployeesByWorkCenterId(calendar.workCenterId).then((employees) => {
                      setBulkAllocationToEmployeesEmployees(employees);
                    });
                    setBulkAllocationToEmployeesIsLoading(false);
                  }
                }}
                loading={bulkAllocationToEmployeesIsLoading}
                options={bulkAllocationToEmployeesEmployees.map(employee => ({ value: employee.id, label: employee.employeeFullName }))}
              />
            </Space>
          </ModalAdvanced>

          <Tooltip placement="topLeft" title={t("calendar.bulkAllocationToWorkCenters")} color="cyan">
            <button className="rounded-md bg-cyan-50 px-2 py-1 text-sm font-semibold text-cyan-600 shadow-sm hover:bg-cyan-100" onClick={() => (setBulkAllocationToWorkCentersModal(calendar.id))}>
              <ArrowDownOnSquareIcon className="h-5 w-5" aria-hidden="true" />
            </button >
          </Tooltip>
          <ModalAdvanced
            title={t("calendar.bulkAllocationToWorkCenters")}
            subtitle={t("calendar.bulkAllocationToWorkCentersSubtitle").toString()}
            isOpen={bulkAllocationToWorkCentersModal === calendar.id}
            onClose={() => setBulkAllocationToWorkCentersModal(null)}
            onOk={async () => {
              setIsLoading(true);
              await PostBulkAllocationToWorkCentersByCalendarId(calendar.id, bulkAllocationToWorkCentersSelectedWorkCenters).then((isSuccessful) => {
                if (isSuccessful) {
                  setBulkAllocationToWorkCentersModal(null)
                  showNotification({ color: NotificationTypes.Success, messageTitle: t("calendar.bulkAllocationToWorkCentersSuccessfulTitle"), messageText: t("calendar.bulkAllocationToWorkCentersSuccessfulSubtitle") });
                }
              })
              setIsLoading(false);
            }}
          >
            <Space style={{ width: '100%' }} direction="vertical">
              <Select
                mode="multiple"
                showSearch
                labelInValue
                style={{ width: '100%' }}
                placeholder=""
                filterOption={filterOption}
                onChange={(selectedOptions) => {
                  const selectedWorkCenterIds = selectedOptions.map((option: { value: any; }) => option.value);
                  setBulkAllocationToWorkCentersSelectedWorkCenters(selectedWorkCenterIds);
                }}
                onDropdownVisibleChange={async open => {
                  if (open && bulkAllocationToWorkCentersWorkCenters.length === 0 && calendar.workCenterId) {
                    setBulkAllocationToWorkCentersIsLoading(true);
                    await GetCompanies().then((companies) => {
                      setBulkAllocationToWorkCentersWorkCenters(companies);
                    });
                    setBulkAllocationToWorkCentersIsLoading(false);
                  }
                }}
                loading={bulkAllocationToWorkCentersIsLoading}
                options={
                  bulkAllocationToWorkCentersWorkCenters
                    .flatMap(company =>
                      company.workCenters!.map(workCenter => ({
                        value: workCenter.id.toString(),
                        label: `${company.code} ${company.name} | ${workCenter.code} ${workCenter.name}`,
                        companyCode: +company.code!,
                        workCenterCode: +workCenter.code!
                      }))
                    )
                    .sort((a, b) => {
                      if (a.companyCode !== b.companyCode) {
                        return a.companyCode - b.companyCode;
                      }
                      return a.workCenterCode - b.workCenterCode;
                    })
                    .map(option => ({
                      value: option.value,
                      label: option.label
                    }))}
              />
            </Space>
          </ModalAdvanced>
        </div >
      )
    },
    { title: t("calendar.year"), dataIndex: 'year', key: 'year', align: 'center' },
    {
      title: t("calendar.isDefault"),
      key: 'operation',
      align: 'center',
      className: 'min-w-min w-[130px]',
      render: (_, calendar) => (
        calendar.isDefault ? (
          <div className="flex justify-center">
            <Tooltip placement="topLeft" title={t("calendar.isDefaultTooltip")} color="gold">
              <StarIcon className="text-yellow-400 h-6 w-6" aria-hidden="true" />
            </Tooltip>
          </div>
        ) : (
          <div>-</div>
        )
      )
    },
    { title: t("calendar.name"), dataIndex: 'name', key: 'name', align: 'left' },
    { title: t("calendar.remarks"), dataIndex: 'remarks', key: 'experemarksctedWorkTime', align: 'left' },
  ];

  return (
    <div className="px-4">
      <SimpleHeaderWithTwoButtons
        title={workCenter && workCenter.id > 0
          ? `${t("workCenter.name")}: ${workCenter.name}`
          : t("common.loading")}
        textSize="text-3xl"
        buttonText1={t("common.goBack")}
        buttonText2={t("common.save")}
        button1Click={() => navigate("/companies")}
        button2Click={handleValidateAndSubmit}
      />
      <div className="mt-4">
        <Tabs setTab={changeTab} activeTab={activeTab} tabs={tabs} section={sectionKey} />
        {tabs.map(tab => (
          <div key={tab.key} className={`mt-8 ${activeTab === tab.key && userHasPermission(tab, sectionKey) ? '' : ' hidden'}`}>
            {tab.key === "generalData" && (
              <Form initialValues={workCenter} validationSchema={workCenterValidationSchema} ref={workCenterFormRef}>
                <AlertModal title={t("common.errorDataValidation")} text={t(validationErrors)} type="warning" isOpen={validationErrors !== ""} onClose={() => setValidationErrors("")} />
                <div className="space-y-6">
                  <div className="pb-8">
                    <h2 className="text-base font-semibold leading-7 text-gray-900">{t("workCenter.workCenterTimeControlResponsible")}</h2>
                    <div className="space-x-4 mt-4">
                      <button
                        type="button"
                        className="rounded-md bg-green-50 px-3.5 py-2.5 text-sm font-semibold text-green-600 shadow-sm hover:bg-green-100"
                        onClick={() => setModalWorkCenterTimeControlResponsibleAddIsOpen(true)}
                      >
                        {t("workCenter.addWorkCenterTimeControlResponsible")}
                      </button>
                      <Modal isOpen={modalWorkCenterTimeControlResponsibleAddIsOpen} onClose={() => setModalWorkCenterTimeControlResponsibleAddIsOpen(false)}>
                        <WorkCenterTimeControlResponsibleAdd workCenter={workCenter} onUpdateParent={updateParentFromEmployeeAdd} closeModal={closeWorkCenterTimeControlResponsibleAddModal} isLoading={isLoadingChildren} />
                      </Modal>
                    </div>
                    <TableDynamic
                      tableName={`${pageName}_TimeControlResponsible`}
                      fetchData={fetchWorkCenterTimeControlResponsiblesData}
                      usePagination={false}
                      onRowButtonDeleteClick={(primaryKeyValue) => {
                        if (window.confirm(t("common.areYouSure").toString())) {
                          DeleteWorkCenterTimeControlResponsible(primaryKeyValue).then(() => {
                            setWorkCenter({ ...workCenter });
                          });
                        }
                      }}
                      translationNode="workCenter"
                    />
                  </div>
                  <div className="pb-8">
                    <h2 className="text-base font-semibold leading-7 text-gray-900">{t("workCenter.departments")}</h2>
                    <div className="space-x-4 mt-4">
                      <button
                        type="button"
                        className="rounded-md bg-green-50 px-3.5 py-2.5 text-sm font-semibold text-green-600 shadow-sm hover:bg-green-100"
                        onClick={() => setModalWorkCenterDepartmentAddIsOpen(true)}
                      >
                        {t("workCenter.addDepartment")}
                      </button>
                      <Modal isOpen={modalWorkCenterDepartmentAddIsOpen} onClose={() => setModalWorkCenterDepartmentAddIsOpen(false)}>
                        <WorkCenterDepartmentAdd workCenter={workCenter} onUpdateParent={updateParentFromEmployeeAdd} closeModal={closeWorkCenterDepartmentAddModal} isLoading={isLoadingChildren} />
                      </Modal>
                    </div>
                    <TableDynamic
                      tableName={`${pageName}_DepartmentResponsible`}
                      fetchData={fetchWorkCenterDepartmentsData}
                      usePagination={false}
                      onRowButtonDeleteClick={(primaryKeyValue) => {
                        if (window.confirm(t("common.areYouSure").toString())) {
                          DeleteWorkCenterDepartment(primaryKeyValue).then(() => {
                            setWorkCenter({ ...workCenter });
                          });
                        }
                      }}
                      translationNode="workCenter"
                    />
                  </div>
                </div>
              </Form>
            )}
            {tab.key === "calendar" && (
              <div className="space-y-12">
                <div className="border-b border-gray-900/10">
                  <h2 className="text-base font-semibold leading-7 text-gray-900">{t("workCenter.calendars")}</h2>
                  <p className="my-2 text-sm italic text-gray-700">
                    {t("workCenter.workCenterCalendarsTitle")}
                  </p>
                  <button
                    type="button"
                    className="my-4 inline-flex items-center gap-x-2 rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
                    onClick={() => navigate(`/work-centers/calendar/${id}/0`)}
                  >
                    <PlusIcon className="h-5 w-5" aria-hidden="true" />
                    {t("common.add")}
                  </button>
                  <Table
                    rowKey={calendar => calendar.id}
                    columns={calendarColumns}
                    dataSource={calendarList}
                    locale={{
                      emptyText: (
                        <div className="flex flex-col items-center justify-center text-gray-400">
                          {t("common.noDataInPeriod")}
                        </div>
                      )
                    }}
                  />
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
    </div >
  )
}
