import { useState, useEffect } from 'react';
import Alert from '../alert/Alert';
import { PhotoIcon } from "@heroicons/react/20/solid";

interface UploadFilesProps {
    onUpdateParent: (selectedFile: File | null) => void;
    text1: string;
    text2: string;
    text3: string;
    allowedExtensions: string[];
    extensionErrorMessage: string;
    maxSize: number;
    maxSizeErrorMessage: string;
}

export default function UploadFiles(props: UploadFilesProps) {
    const [fileError, setFileError] = useState<string | null>(null);
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [selectedFilesFileName, setSelectedFilesFileName] = useState<string>();

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0];
        if (file && validateFile(file)) {
            setSelectedFile(file);
            setSelectedFilesFileName(file.name);
        }
    };

    const validateFile = (file: File): boolean => {
        const allowedExtensions = props.allowedExtensions;
        const maxFileSize = props.maxSize * 1024 * 1024;

        const fileExtension = file.name.split('.').pop()?.toLowerCase();

        if (!allowedExtensions.includes(fileExtension || '')) {
            setFileError(props.extensionErrorMessage);
            return false;
        }

        if (file.size > maxFileSize) {
            setFileError(props.maxSizeErrorMessage);
            return false;
        }

        setFileError(null);
        return true;
    };

    useEffect(() => {
        props.onUpdateParent(selectedFile);
    }, [selectedFile]);

    return (
        <>
            {fileError && <Alert text={fileError} type="error" />}
            <div className="col-span-full">
                <div className="flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10">
                    <div className="text-center">
                        <PhotoIcon className="mx-auto h-12 w-12 text-gray-300" aria-hidden="true" />
                        <div className="mt-4 flex text-sm leading-6 text-gray-600">
                            <label
                                htmlFor="file-upload"
                                className="relative cursor-pointer rounded-md bg-white font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500"
                            >
                                <span>{props.text1}</span>
                                <input id="file-upload" name="file-upload" type="file" className="sr-only" onChange={handleFileChange} />
                            </label>
                            <p className="pl-1">{props.text2}</p>
                        </div>
                        <p className="text-xs leading-5 text-gray-600">{props.text3}</p>
                    </div>
                </div>
            </div>
        </>
    )
}