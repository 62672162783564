import { WorkShift } from "../../interfaces/time-control/WorkShift";
import { Employee } from "../../interfaces/employee/Employee";
import { GetCookie } from "../../services/cookie/CookieService";
import { axiosRRHHEmployeeAPI } from '../axios/AxiosRRHHEmployeeAPI';
import { WorkTime as IWorkTime } from "../../interfaces/time-control/WorkTime";
import { TimeLogTypeCategory, TimeLogType } from "../../interfaces/time-control/TimeLogType";
import { DTOTimeControlEmployeeSummary, DTOTimeControlManualMonthClosingConfirmationData, DTOTimeControlManualMonthClosingData } from "../../interfaces/time-control/DTOTimeControlEmployeeSummary";
import { DTOTimeControlWorkCenterSummary } from "../../interfaces/time-control/DTOTimeControlWorkCenterSummary";
import { DTOTimeControlCertificateOption } from "../../interfaces/time-control/DTOTimeControlCertificateOption";
import { DTOPieChartItem } from "../../interfaces/common/statistics/DTOPieChartItem";
import { DTOBarChartItem } from "../../interfaces/common/statistics/DTOBarChartItem";

export async function GetWorkShiftsCount(
    workShiftFilter?: Record<string, any>
): Promise<number> {
    try {
        const response = await axiosRRHHEmployeeAPI.post("/api/timecontrol/WorkShift/count", workShiftFilter ?? {});

        if (response.status === 200) {
            return response.data;
        }
    } catch (error) {
        console.error('Error en la solicitud:', error);
    }
    return 0;
}

export async function GetWorkShifts(
    page?: number,
    itemsPerPage?: number,
    workShiftFilter?: Record<string, any>
): Promise<WorkShift[]> {
    try {
        const response = await axiosRRHHEmployeeAPI.post(`/api/timecontrol/WorkShift/GetWorkShifts/${page}/${itemsPerPage}`, workShiftFilter ?? {});

        if (response.status === 200) {
            return response.data;
        }
    } catch (error) {
        console.error('Error en la solicitud:', error);
    }
    return [];
}

export async function GetWorkShiftsGroupByWorkCenterCount(
    workShiftFilter?: Record<string, any>
): Promise<number> {
    const response = await axiosRRHHEmployeeAPI.post("/api/timecontrol/WorkShift/GetWorkShiftsGroupByWorkCenterCount", workShiftFilter ?? {});
    return response.status === 200 ? response.data : 0;
}

export async function GetWorkShiftsGroupByWorkCenter(
    page?: number,
    itemsPerPage?: number,
    workShiftFilter?: Record<string, any>
): Promise<WorkShift[]> {
    const response = await axiosRRHHEmployeeAPI.post(`/api/timecontrol/WorkShift/GetWorkShiftsGroupByWorkCenter/${page}/${itemsPerPage}`, workShiftFilter ?? {});
    return response.status === 200 ? response.data : [];
}

export async function GetEmployeeIsTimeControlSupervisor(): Promise<boolean> {
    const response = await axiosRRHHEmployeeAPI.get("/api/timecontrol/WorkShift/GetEmployeeIsTimeControlSupervisor");
    return response.status === 200 ? response.data : 0;
}

export async function GetSupervisedEmployeesWorkShiftsCount(
    workShiftFilter?: Record<string, any>
): Promise<number> {
    const response = await axiosRRHHEmployeeAPI.post("/api/timecontrol/WorkShift/GetSupervisedEmployeesWorkShiftsCount", workShiftFilter ?? {});
    return response.status === 200 ? response.data : 0;
}

export async function GetSupervisedEmployeesWorkShifts(
    page?: number,
    itemsPerPage?: number,
    workShiftFilter?: Record<string, any>
): Promise<WorkShift[]> {
    const response = await axiosRRHHEmployeeAPI.post(`/api/timecontrol/WorkShift/GetSupervisedEmployeesWorkShifts/${page}/${itemsPerPage}`, workShiftFilter ?? {});
    return response.status === 200 ? response.data : [];
}

export async function GetWorkShiftsByEmployeeByMonth(
    workShiftFilter?: Record<string, any>
): Promise<WorkShift[]> {
    try {
        const response = await axiosRRHHEmployeeAPI.post(`/api/timecontrol/GetWorkShiftsByEmployeeByMonth`, workShiftFilter ?? {});

        if (response.status === 200) {
            return response.data;
        }
    } catch (error) {
        console.error('Error en la solicitud:', error);
    }
    return [];
}

export async function GetWorkShift(
    workShiftId: number
): Promise<WorkShift | null> {
    try {
        const response = await axiosRRHHEmployeeAPI.get(`/api/TimeControl/WorkShift/${workShiftId}`);

        if (response.status === 200) {
            return response.data;
        }
    } catch (error) {
        console.error('Error en la solicitud:', error);
    }
    return null;
}

export async function GetWorkShiftsExportExcel(
    fileName: string,
    workShiftFilter?: Record<string, any>
): Promise<void> {
    const response = await axiosRRHHEmployeeAPI.post("/api/timecontrol/GetWorkShiftsExportExcel", workShiftFilter ?? {}, {
        responseType: 'blob',
    });

    if (response.status === 200) {
        const url = window.URL.createObjectURL(new Blob([response.data]));

        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${fileName}.xlsx`);
        document.body.appendChild(link);

        link.click();
        link.parentNode?.removeChild(link);
    }
}

export async function GetTimeOffsReport(
    fileName: string,
    workShiftFilter?: Record<string, any>
): Promise<void> {
    const response = await axiosRRHHEmployeeAPI.post("/api/timecontrol/GetTimeOffsReport", workShiftFilter ?? {}, {
        responseType: 'blob',
    });

    if (response.status === 200) {
        const url = window.URL.createObjectURL(new Blob([response.data]));

        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${fileName}.xlsx`);
        document.body.appendChild(link);

        link.click();
        link.parentNode?.removeChild(link);
    }
}

export async function GetIncidencesReport(
    fileName: string,
    workShiftFilter?: Record<string, any>
): Promise<void> {
    const response = await axiosRRHHEmployeeAPI.post("/api/timecontrol/GetIncidencesReport", workShiftFilter ?? {}, {
        responseType: 'blob',
    });

    if (response.status === 200) {
        const url = window.URL.createObjectURL(new Blob([response.data]));

        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${fileName}.xlsx`);
        document.body.appendChild(link);

        link.click();
        link.parentNode?.removeChild(link);
    }
}

export async function GetEmployeesCertificatesReport(
    fileName: string,
    workShiftFilter?: Record<string, any>
): Promise<void> {
    const response = await axiosRRHHEmployeeAPI.post("/api/timecontrol/GetEmployeesCertificatesReport", workShiftFilter ?? {}, {
        responseType: 'blob',
    });

    if (response.status === 200) {
        const url = window.URL.createObjectURL(new Blob([response.data]));

        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${fileName}.xlsx`);
        document.body.appendChild(link);

        link.click();
        link.parentNode?.removeChild(link);
    }
}

export async function UpdateWorkShift(
    workShift: WorkShift
): Promise<WorkShift> {
    try {
        const response = await axiosRRHHEmployeeAPI.put("/api/timecontrol/WorkShift", workShift);

        if (response.status === 200) {
            return response.data;
        }
    } catch (error) {
        console.error('Error en la solicitud:', error);
    }
    return workShift;
}

export async function PostWorkShift(
    workShift: WorkShift
): Promise<WorkShift | null> {
    const response = await axiosRRHHEmployeeAPI.post("/api/TimeControl/WorkShift", workShift);
    return response.status === 200 ? response.data : null;
}

export async function PatchWorkShift(
    workShiftId: number,
    updates: { fieldName: string, fieldValue: any }[]
): Promise<WorkShift | null> {
    const patchWorkShift = updates.map(update => {
        return { op: "replace", path: `/${update.fieldName}`, value: update.fieldValue };
    });
    const response = await axiosRRHHEmployeeAPI.patch(`/api/timecontrol/WorkShift/${workShiftId}`, patchWorkShift);
    return response.status === 200 ? response.data : null;
}

export async function PutWorkShift(
    workShift: WorkShift
): Promise<WorkShift | null> {
    const response = await axiosRRHHEmployeeAPI.put("/api/TimeControl/WorkShift", workShift);
    return response.status === 200 ? response.data : null;
}

export async function OpenWorkShift(): Promise<string | null> {
    try {
        const employee = JSON.parse(GetCookie("employee") || "{}") as Employee;
        const response = await axiosRRHHEmployeeAPI.post("/api/timecontrol/OpenWorkShift", {
            employeeId: employee.id,
            timeLogTypeId: 1
        });

        if (response.status === 202) {
            const jsonResponse = response.data;
            return jsonResponse.message;
        }
    } catch (error) {
        console.error('Error en la solicitud:', error);
    }
    return null;
}

export async function CloseWorkShift(
    workShift: WorkShift
): Promise<boolean> {
    try {
        const employee = JSON.parse(GetCookie("employee") || "{}") as Employee;
        const formData = new FormData();

        if (workShift.attachments) {
            workShift.attachments.forEach((attachment, index) => {
                formData.append("files", attachment.attachment, attachment.fileName);
            });
        }

        if (workShift.employeeRemarks) {
            formData.append("remarks", workShift.employeeRemarks);
        }

        console.log(formData);
        const response = await axiosRRHHEmployeeAPI.post(
            `/api/timecontrol/CloseWorkShift/${workShift.id}/${employee.id}`,
            formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            }
        );

        if (response.status === 200) {
            return true;
        }
    } catch (error) {
        console.error('Error en la solicitud:', error);
    }
    return false;
}

export async function GetCurrentWorkShiftByEmployee(
    employeeId?: number
): Promise<WorkShift | null> {
    try {
        const employee = JSON.parse(GetCookie("employee") || "{}") as Employee;
        const response = await axiosRRHHEmployeeAPI.post("/api/timecontrol/GetCurrentWorkShiftByEmployee", {
            employeeId: employeeId ?? employee.id,
        });

        if (response.status !== 200) {
            return null;
        }

        const jsonResponse = response.data;
        const workShift: WorkShift = {
            id: jsonResponse.workShiftId,
            employeeId: jsonResponse.employeeId,
            workCenterId: jsonResponse.workCenterId,
            startShift: jsonResponse.startShift,
            endShift: jsonResponse.endShift,
            isActive: jsonResponse.isActive,
            employeeRemarks: jsonResponse.remarks,
            isValidated: jsonResponse.isValidated,
            totalWorkTime: jsonResponse.totalWorkTime,
            workTimes: jsonResponse.workTimes.map((wt: any) => ({
                workTimeId: wt.workTimeId,
                workShiftId: wt.workShiftId,
                timeLogTypeId: wt.timeLogTypeId,
                startTime: wt.startTime,
                endTime: wt.endTime,
                workShift: wt.workShift,
                timeLogType: wt.timeLogType,
                WorkShiftAttachments: wt.WorkShiftAttachments
            })),
            isEditable: jsonResponse.isEditable
        };
        return workShift;
    } catch (error) {
        console.error('Error en la solicitud:', error);
        return null;
    }
}

export async function UpdateCurrentWorkTimeInWorkShift(
    workShiftId: number,
    closingWorkTimeId: number,
    openingWorkTimeTypeId: number,
    employeeId?: number
): Promise<boolean> {
    try {
        const employee = JSON.parse(GetCookie("employee") || "{}") as Employee;
        const response = await axiosRRHHEmployeeAPI.post("/api/timecontrol/UpdateCurrentWorkTimeInWorkShift", {
            employeeId: employeeId ?? employee.id,
            workShiftId: workShiftId,
            closingWorkTimeId: closingWorkTimeId,
            openingWorkTimeTypeId: openingWorkTimeTypeId,
        });

        if (response.status === 200) {
            return true;
        }
    } catch (error) {
        console.error('Error en la solicitud:', error);
    }
    return false;
}

export async function CloseCurrentWorkTimeInWorkShift(
    workShiftId: number,
    closingWorkTimeId: number,
    employeeId?: number
): Promise<boolean> {
    try {
        const employee = JSON.parse(GetCookie("employee") || "{}") as Employee;
        const response = await axiosRRHHEmployeeAPI.post("/api/timecontrol/CloseCurrentWorkTimeInWorkShift", {
            employeeId: employeeId ?? employee.id,
            workShiftId: workShiftId,
            closingWorkTimeId: closingWorkTimeId,
        });

        if (response.status === 200) {
            return true;
        }
    } catch (error) {
        console.error('Error en la solicitud:', error);
    }
    return false;
}

export async function OpenNewWorkTimeInWorkShift(
    workShiftId: number,
    openingWorkTimeTypeId: number,
    employeeId?: number
): Promise<boolean> {
    try {
        const employee = JSON.parse(GetCookie("employee") || "{}") as Employee;
        const response = await axiosRRHHEmployeeAPI.post("/api/timecontrol/OpenNewWorkTimeInWorkShift", {
            employeeId: employeeId ?? employee.id,
            workShiftId: workShiftId,
            openingWorkTimeTypeId: openingWorkTimeTypeId,
        });

        if (response.status === 200) {
            return true;
        }
    } catch (error) {
        console.error('Error en la solicitud:', error);
    }
    return false;
}

export async function AddWorkShiftAttachment(
    workShiftId: number,
    file: File
): Promise<boolean> {
    try {
        const employee = JSON.parse(GetCookie("employee") || "{}") as Employee;
        const formData = new FormData();
        formData.append("file", file);

        const response = await axiosRRHHEmployeeAPI.post(
            `/api/timecontrol/AddWorkShiftAttachment/${workShiftId}/${employee.id}`,
            formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            }
        );

        if (response.status === 200) {
            return true;
        }
    } catch (error) {
        console.error('Error en la solicitud:', error);
    }
    return false;
}

export async function GetWorkShiftAttachment(
    workShiftId: number,
    workShiftAttachmentId: number,
    fileName: string
): Promise<void> {
    try {
        const response = await axiosRRHHEmployeeAPI.get(`/api/TimeControl/GetWorkShiftAttachment/${workShiftId}/${workShiftAttachmentId}`, {
            responseType: 'blob',
        });

        if (response.status === 200) {
            const url = window.URL.createObjectURL(new Blob([response.data]));

            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${fileName}`);
            document.body.appendChild(link);

            link.click();
            link.parentNode?.removeChild(link);
        }
    } catch (error) {
        console.error('Error en la solicitud:', error);
    }
}

export async function GetTimeLogTypes(
    timeLogTypeCategory?: TimeLogTypeCategory
): Promise<TimeLogType[] | null> {
    const response = await axiosRRHHEmployeeAPI.get(timeLogTypeCategory ? `/api/timecontrol/GetTimeLogTypes/${timeLogTypeCategory}` : "/api/timecontrol/GetTimeLogTypes");
    return response.status === 200 ? response.data : null;
}

export async function GetWorkShiftDetailTimeLogTypes(
): Promise<TimeLogType[] | null> {
    const response = await axiosRRHHEmployeeAPI.get("/api/timecontrol/GetWorkShiftDetailTimeLogTypes");
    return response.status === 200 ? response.data : null;
}

export const CalculateTotalWorkTimeInSeconds = async (workTimes: IWorkTime[]): Promise<number> => {
    let totalWorkTimeInSeconds = 0;

    for (const workTime of workTimes) {
        if (workTime.timeLogType.category === TimeLogTypeCategory.WorkTime) {
            const startTime = new Date(workTime.startTime);
            const endTime = workTime.endTime ? new Date(workTime.endTime) : await GetCurrentDateTime();
            const diffInSeconds = (endTime.getTime() - startTime.getTime()) / 1000;
            totalWorkTimeInSeconds += diffInSeconds;
        }
    }

    return totalWorkTimeInSeconds;
};

export async function GetYears(): Promise<number[] | []> {
    try {
        const employee = JSON.parse(GetCookie("employee") || "{}") as Employee;
        const response = await axiosRRHHEmployeeAPI.post("/api/timecontrol/GetYears", {
            employeeId: employee.id,
            documentType: employee.documentType,
            documentNumber: employee.documentNumber
        });

        const jsonResponse = response.data;
        if (response.status === 200) {
            let years: number[] = jsonResponse;
            return years;
        }
    } catch (error) {
        console.error('Error en la solicitud:', error);
    }
    return [];
}

export async function GetMonthsByYear(
    year: number
): Promise<number[] | []> {
    try {
        const employee = JSON.parse(GetCookie("employee") || "{}") as Employee;
        const response = await axiosRRHHEmployeeAPI.post(`/api/timecontrol/GetMonthsByYear/${year}`, {
            employeeId: employee.id,
            documentType: employee.documentType,
            documentNumber: employee.documentNumber
        });

        const jsonResponse = response.data;
        if (response.status === 200) {
            let months: number[] = jsonResponse;
            return months;
        }
    } catch (error) {
        console.error('Error en la solicitud:', error);
    }
    return [];
}

export async function GetWorkShiftsByEmployeeByMonthByYear(
    month: number,
    year: number
): Promise<WorkShift[] | null> {
    try {
        const employee = JSON.parse(GetCookie("employee") || "{}") as Employee;
        const response = await axiosRRHHEmployeeAPI.post(`/api/timecontrol/GetWorkShiftsByEmployeeByMonthByYear/${month}/${year}`,
            {
                employeeId: employee.id,
                documentType: employee.documentType,
                documentNumber: employee.documentNumber
            }
        );

        const jsonResponse = response.data;
        if (response.status === 200) {
            let workShifts: WorkShift[] = jsonResponse;
            return workShifts;
        }
    } catch (error) {
        console.error('Error en la solicitud:', error);
    }
    return [];
}

export async function UpdateWorkShiftRemarks(
    workShiftId: number,
    remarks: string
): Promise<boolean> {
    try {
        const employee = JSON.parse(GetCookie("employee") || "{}") as Employee;
        const response = await axiosRRHHEmployeeAPI.post(`/api/timecontrol/UpdateWorkShiftRemarks/${workShiftId}/${remarks}`,
            {
                employeeId: employee.id,
                documentType: employee.documentType,
                documentNumber: employee.documentNumber
            }
        );

        if (response.status === 200) {
            return true;
        }
    } catch (error) {
        console.error('Error en la solicitud:', error);
    }
    return false;
}

export async function GetCurrentDateTime(): Promise<Date> {
    try {
        const response = await axiosRRHHEmployeeAPI.get(`/api/timecontrol/GetCurrentDateTime`);

        if (response.status === 200) {
            return new Date(response.data);
        }
    } catch (error) {
        console.error('Error en la solicitud:', error);
    }
    return new Date();
}

export async function GetMonthSummaryForEmployeeByWorkCenter(
    employeeId: number,
    month: number,
    year: number,
    workCenterId: number,
): Promise<void> {
    try {
        const response = await axiosRRHHEmployeeAPI.get(`/api/TimeControl/GenerateMonthSummaryForEmployee/${employeeId}/${month}/${year}/${workCenterId}`, {
            responseType: 'blob',
        });

        if (response.status === 200) {
            const url = window.URL.createObjectURL(new Blob([response.data]));

            const contentDisposition = response.headers['content-disposition'];
            let fileName = 'download.pdf';
            if (contentDisposition) {
                const filenameMatch = contentDisposition.match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/);
                if (filenameMatch && filenameMatch[1]) {
                    fileName = filenameMatch[1].replace(/['"]/g, '');
                }
            }

            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);

            link.click();

            window.URL.revokeObjectURL(url);
            link.parentNode?.removeChild(link);
        }
    } catch (error) {
        console.error('Error en la solicitud:', error);
    }
}

export async function GenerateTimeControlCertificateForEmployee(
    employeeId: number,
    workCenterId: number,
    months: string[],
): Promise<void> {
    try {
        const response = await axiosRRHHEmployeeAPI.post(`/api/TimeControl/GenerateTimeControlCertificateForEmployee/${employeeId}/${workCenterId}`, months, {
            responseType: 'blob',
        });

        if (response.status === 200) {
            const url = window.URL.createObjectURL(new Blob([response.data]));

            const contentDisposition = response.headers['content-disposition'];
            let fileName = 'download.pdf';
            if (contentDisposition) {
                const filenameMatch = contentDisposition.match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/);
                if (filenameMatch && filenameMatch[1]) {
                    fileName = filenameMatch[1].replace(/['"]/g, '');
                }
            }

            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);

            link.click();

            window.URL.revokeObjectURL(url);
            link.parentNode?.removeChild(link);
        }
    } catch (error) {
        console.error('Error en la solicitud:', error);
    }
}

export async function GenerateTimeControlCertificateForCustomer(
    employeeId: number,
    workCenterId: number,
    months: string[],
): Promise<void> {
    try {
        const response = await axiosRRHHEmployeeAPI.post(`/api/TimeControl/GenerateTimeControlCertificateForCustomer/${employeeId}/${workCenterId}`, months, {
            responseType: 'blob',
        });

        if (response.status === 200) {
            const url = window.URL.createObjectURL(new Blob([response.data]));

            const contentDisposition = response.headers['content-disposition'];
            let fileName = 'download.pdf';
            if (contentDisposition) {
                const filenameMatch = contentDisposition.match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/);
                if (filenameMatch && filenameMatch[1]) {
                    fileName = filenameMatch[1].replace(/['"]/g, '');
                }
            }

            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);

            link.click();

            window.URL.revokeObjectURL(url);
            link.parentNode?.removeChild(link);
        }
    } catch (error) {
        console.error('Error en la solicitud:', error);
    }
}

export async function GetTimeControlSummaryByWorkCenter(
    workCenterId: number
): Promise<DTOTimeControlWorkCenterSummary | null> {
    const response = await axiosRRHHEmployeeAPI.get(`/api/timecontrol/GetTimeControlSummaryByWorkCenter/${workCenterId}`);
    return response.status === 200 ? response.data : null;
}

export async function GetTimeControlSummaryByEmployee(
    workCenterId: number,
    employeeId: number,
    year: number
): Promise<DTOTimeControlEmployeeSummary | null> {
    try {
        const response = await axiosRRHHEmployeeAPI.get(`/api/timecontrol/GetTimeControlSummaryByEmployee/${workCenterId}/${employeeId}/${year}`);

        if (response.status === 200) {
            return response.data;
        }
    } catch (error) {
        console.error('Error en la solicitud:', error);
    }
    return null;
}

export async function GetManualMonthClosingData(
    employeeId: number,
    workCenterId: number,
    year: number,
    month: number
): Promise<DTOTimeControlManualMonthClosingData | null> {
    const response = await axiosRRHHEmployeeAPI.get(`/api/timecontrol/ManualMonthClosingData/${employeeId}/${workCenterId}/${year}/${month}`);
    return response.status === 200 ? response.data : null;
}

export async function GetManualMonthClosingConfirmation(
    employeeId: number,
    workCenterId: number,
    year: number,
    month: number
): Promise<string | null> {
    const response = await axiosRRHHEmployeeAPI.get(`/api/timecontrol/ManualMonthClosingConfirmation/${employeeId}/${workCenterId}/${year}/${month}`);
    return response.status === 200 ? response.data : null;
}

export async function SaveManualMonthClosing(
    employeeId: number,
    workCenterId: number,
    year: number,
    month: number,
    withTimeCompensation: boolean,
    data: DTOTimeControlManualMonthClosingConfirmationData
): Promise<boolean> {
    const response = await axiosRRHHEmployeeAPI.post(`/api/timecontrol/ManualMonthClosingSave/${employeeId}/${workCenterId}/${year}/${month}/${withTimeCompensation}`, data);
    return response.status === 200 ? response.data : false;
}

export async function RevertManualMonthClosing(
    employeeId: number,
    workCenterId: number,
    year: number,
    month: number
): Promise<boolean> {
    const response = await axiosRRHHEmployeeAPI.post(`/api/timecontrol/ManualMonthClosingReversion/${employeeId}/${workCenterId}/${year}/${month}`);
    return response.status === 200 ? response.data : false;
}

export async function GetWorkTimeSuggestionByWorkCenterIdByEmployeeIdByDate(
    workCenterId: number,
    employeeId: number,
    date: Date
): Promise<string | null> {
    const response = await axiosRRHHEmployeeAPI.get(`/api/TimeControl/GetWorkTimeSuggestionByWorkCenterIdByEmployeeIdByDate/${workCenterId}/${employeeId}/${date}`);
    return response.status === 200 ? response.data : null;
}

export async function DeleteWorkShift(
    workShiftId: number
): Promise<boolean> {
    const response = await axiosRRHHEmployeeAPI.delete(`/api/TimeControl/WorkShift/${workShiftId}`);
    return response.status === 200 ? response.data : null;
}

export async function SyncMonthlyTimeControlWithEmployeeCalendar(
    employeeId: number,
    workCenterId: number,
    year: number,
    month: number
): Promise<boolean> {
    const response = await axiosRRHHEmployeeAPI.get(`/api/timecontrol/SyncMonthlyTimeControlWithEmployeeCalendar/${employeeId}/${workCenterId}/${year}/${month}`);
    return response.status === 200;
}

export async function GetWorkShiftMonthSummaryAttachment(
    workShiftMonthSummaryId: number,
    workShiftMonthSummaryAttachmentId: number
): Promise<void> {
    const response = await axiosRRHHEmployeeAPI.get(`/api/TimeControl/GetWorkShiftMonthSummaryAttachment/${workShiftMonthSummaryId}/${workShiftMonthSummaryAttachmentId}`, {
        responseType: 'blob',
    });

    if (response.status === 200) {
        const url = window.URL.createObjectURL(new Blob([response.data]));

        const contentDisposition = response.headers['content-disposition'];
        let fileName = 'download.pdf';
        if (contentDisposition) {
            const filenameMatch = contentDisposition.match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/);
            if (filenameMatch && filenameMatch[1]) {
                fileName = filenameMatch[1].replace(/['"]/g, '');
            }
        }

        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);

        link.click();

        window.URL.revokeObjectURL(url);
        link.parentNode?.removeChild(link);
    }
}

export async function AddWorkShiftMonthSummaryAttachment(
    workShiftMonthSummaryId: number,
    file: File
): Promise<number | null> {
    const formData = new FormData();
    formData.append("file", file);

    const response = await axiosRRHHEmployeeAPI.post(
        `/api/timecontrol/AddWorkShiftMonthSummaryAttachment/${workShiftMonthSummaryId}`,
        formData,
        {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        }
    );
    return response.status === 200 ? response.data : null;
}

export async function DeleteWorkShiftMonthSummaryAttachment(
    workShiftMonthSummaryId: number,
    workShiftMonthSummaryAttachmentId: number
): Promise<boolean> {
    const response = await axiosRRHHEmployeeAPI.delete(`/api/TimeControl/DeleteWorkShiftMonthSummaryAttachment/${workShiftMonthSummaryId}/${workShiftMonthSummaryAttachmentId}`);
    return response.status === 200 ? response.data : null;
}

export async function GetTimeControlCertificateForEmployeeOptions(
    employeeId: number,
    workCenterId?: number
): Promise<DTOTimeControlCertificateOption[]> {
    const response = await axiosRRHHEmployeeAPI.get(`/api/TimeControl/GetTimeControlCertificateForEmployeeOptions/${employeeId}/${workCenterId}`);
    return response?.status === 200 ? response.data : [];
}

export async function GetWorkCentersIncidencesStatistics(): Promise<DTOPieChartItem[]> {
    const response = await axiosRRHHEmployeeAPI.get("/api/TimeControl/GetWorkCentersIncidencesStatistics");
    return response?.status === 200 ? response.data : [];
}

export async function GetWorkShiftDistributionStatisticsByYearByMonth(
    year: number,
    month: number
): Promise<DTOBarChartItem[]> {
    const response = await axiosRRHHEmployeeAPI.get(`/api/TimeControl/GetWorkShiftDistributionStatisticsByYearByMonth/${year}/${month}`);
    return response?.status === 200 ? response.data : [];
}

export async function CloseWorkShiftIncidenceAsWithoutClockIn(
    workShiftId: number
): Promise<boolean> {
    const response = await axiosRRHHEmployeeAPI.get(`/api/TimeControl/CloseWorkShiftIncidenceAsWithoutClockIn/${workShiftId}`);
    return response?.status === 200;
}