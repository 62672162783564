import { Modal } from 'antd';

interface ConfirmationModalOptions {
    title: React.ReactNode;
    content: React.ReactNode;
    okText: string;
    cancelText: string;
    onConfirm: () => Promise<void>;
}

export const showConfirmationModal = ({
    title,
    content,
    okText,
    cancelText,
    onConfirm,
}: ConfirmationModalOptions) => {
    let confirmModal = Modal.confirm({
        title,
        content,
        okText,
        okType: 'danger',
        okButtonProps: {
            type: "primary"
        },
        cancelText,
        centered: true,
        width: 550,
        onOk() {
            return new Promise<void>((resolve, reject) => {
                confirmModal.update({
                    cancelButtonProps: {
                        disabled: true,
                    }
                });
                onConfirm()
                    .then(() => {
                        resolve();
                    })
                    .catch((error) => {
                        console.error('Error during confirmation:', error);
                        reject(error);
                    });
            });
        },
        onCancel() {
        },
    });
};