import React from 'react';
import { useTranslation } from 'react-i18next';
import { Descriptions, DescriptionsProps } from 'antd';
import { DTOEmployeeInfo } from '../../../interfaces/employee/DTOEmployeeInfo';

interface TimeControlSummaryEmployeeInfoProps {
    employee: DTOEmployeeInfo | null | undefined;
}

const TimeControlSummaryEmployeeInfo: React.FC<TimeControlSummaryEmployeeInfoProps> = ({ employee }) => {
    const { t } = useTranslation();

    const items: DescriptionsProps['items'] = [
        {
            key: 'currentCompany',
            label: t("timeControl.currentCompany"),
            children: employee?.company ?? "-",
            span: 1
        },
        {
            key: 'currentWorkCenter',
            label: t("timeControl.currentWorkCenter"),
            children: employee?.workCenter ?? "-",
            span: 1
        },
        {
            key: 'currentJob',
            label: t("timeControl.currentJob"),
            children: employee?.job ?? "-",
            span: 1
        },
        {
            key: 'currentConventionCode',
            label: t("timeControl.currentConventionCode"),
            children: employee?.conventionCode ?? "-",
            span: 1
        },
    ];

    return (
        <>
            {employee &&
                <>
                    <h3 className="mb-3 text-lg font-semibold text-gray-700">{employee?.fullName}</h3>
                    <Descriptions column={4} title="" items={items} size="small" layout="vertical" colon={false} />
                </>
            }
        </>
    );
};

export default TimeControlSummaryEmployeeInfo;